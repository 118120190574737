import React from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { MenuItem, InputLabel, FormControl } from '@material-ui/core';
export { FileUploadField } from './FileUploadField';
export { NameField } from './NameField';
const getTonalities = () => {
  const tonalityNames = ["C", "C#", "Db", "D", "D#", "Eb", "E", "F", "F#", "Gb", "G", "G#", "Ab", "A", "A#", "Bb", "B"];
  const tonalityQualities = ["major", "minor"];
  const tonalities = ["None", "Non-tonal"];
  tonalityNames.forEach((name) => {
    tonalityQualities.forEach((quality) => {
      const out = `${name} ${quality}`;
      tonalities.push(out);
    })
  })
  return tonalities;
}
export const tonalities = getTonalities();
export const TonalityDropdown = () => {
  const htmlID = 'tonality-dropdown-tonality'
  return (
    <FormControl>
      <InputLabel htmlFor={htmlID}>
        Key
      </InputLabel>
      <Field component={Select} name="tonality" inputProps={{ id: htmlID }}>
        {tonalities.map((tonality) => {
          return (
            <MenuItem value={tonality} key={tonality}>
              {tonality}
            </MenuItem>
          )
        })}
      </Field>
    </FormControl>
  )
}
