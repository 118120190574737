import { ListItemIcon, ListItemText, MenuItem } from "@material-ui/core";
import { RemoveCircle } from "@material-ui/icons";
import get from "lodash.get";
import React from "react";
import { ROLES } from "../../constants";
import { handleShowConfirmActionDialog } from "../dialogs/utils";
import { getRevokeAllCopy, handleRevokeAllShares, hideRevokeAll, isSongWithListShares } from "../utils";

/**
 * @typedef {Object} RevokeAllSharesMenuItemProps
 * @property {import("../../constants").Item} item
 * @property {import("../../Context").KarachordsContext} context
 * @property {Boolean} [hidden]
 * 
 * @param {RevokeAllSharesMenuItemProps} props 
 */
export const RevokeAllSharesMenuItem = (props) => {
  const { context, hidden, item } = props;
  const { dispatch } = context;
  const { shares } = item;
  const role = get(item, 'share.role', ROLES.owner);
  if (hidden || hideRevokeAll(shares, role) || isSongWithListShares(item)) {
    return null;
  }
  const name = get(item, 'name', null);
  return (
    <MenuItem onClick={handleShowConfirmActionDialog(
      getRevokeAllCopy(name),
      handleRevokeAllShares(item, context),
      dispatch
    )}>
      <ListItemIcon>
        <RemoveCircle color={"secondary"} />
      </ListItemIcon>
      <ListItemText>
        {"Revoke all shares"}
      </ListItemText>
    </MenuItem>
  );
};