import { Button } from '@material-ui/core';
import React from 'react';

/**
 *
 * @typedef {Object} HeadedItemsListAddButtonProps
 * @property {import('./../drawers/SubDrawer').HeadedItemListAddAction} action
 * @property {boolean} [disabled]
 */

/**
 *
 * @param {HeadedItemsListAddButtonProps} props
 */
export const HeadedItemsListAddButton = (props) => {
  const { action, disabled } = props;
  if (!action) {
    return null;
  }
  const { method, Icon } = action;
  return (
    <Button onClick={method} startIcon={<Icon />} disabled={disabled}>
      {"Add"}
    </Button>
  );
};
