import { Button } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { ROLES } from '../../constants';
import { Context } from '../../Context';
import { handleShowConfirmActionDialog } from '../dialogs/utils';
import { getRevokeAllCopy, handleRevokeAllShares, hideRevokeAll, isSongWithListShares } from '../utils';





/**
 * @typedef {Object} RevokeAllSharesButtonProps
 * @property {import('../../constants').Item} item
 * @property {() => void} [afterClose]
 * @property {boolean} [disabled]
 * @property {String} [className]
 * 
 * @param {RevokeAllSharesButtonProps} props 
 */
export const RevokeAllSharesButton = (props) => {
  const { item, disabled, className, afterClose } = props;
  const { shares, share } = item;
  const role = get(share, 'role', ROLES.owner);
  const context = useContext(Context);
  const { dispatch } = context;
  if (hideRevokeAll(shares, role) || isSongWithListShares(item)) {
    return null
  }
  const name = get(item, 'name', null);
  return (
    <Button
      className={clsx(className)}
      variant={"contained"}
      color={"secondary"}
      onClick={handleShowConfirmActionDialog(
        getRevokeAllCopy(name),
        handleRevokeAllShares(item, context),
        dispatch,
        afterClose
      )}
      startIcon={<RemoveCircle />}
      disabled={disabled}
    >
      {"Revoke all"}
    </Button>
  );
}



