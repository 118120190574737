import React, { useContext, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Context } from '../../../Context';
import { validateSongForm } from './validate';
import { FileUploadField } from '../../fields';
import { useSongFormStyles } from '.';
import * as constants from '../../../constants';
import get from 'lodash.get';
import { SongFormActions } from './SongFormActions';
import { SongFormHeader } from './SongFormHeader';
import isEqual from 'lodash.isequal';
import { handleSongFormSubmit } from './utils';
import { FORM_STRING_INPUT_MAX_LENGTH } from '../../../constants';
/**
 * @typedef {Object} SongFormValues
 * @property {String} [name] song name
 * @property {String} [artist] artist name
 * @property {String} [tonality] tonality
 * @property {File} [file] most recently uploaded file
 * @property {String} [fileID] fileID from a previous upload and save
 * @property {String} [id] song ID
 * @property {String} [filename] filename from a previous upload and save
 * 
 */

/**
 * @typedef {import('../../../utils/persistence').KarachordsContext} KarachordsContext
 */
const copy = {
  title: {
    edit: 'Edit library item',
    new: 'Add to library'
  }
}

const NEW_SONG_KEY_STRING = 'NEW_SONG'
/**
 * @typedef {Object} SongFile
 * @property {String} name
 * @property {String} id
 * @property {import('../../play').JSONParsedBuffer} [buffer]
 * @property {import('../../play').PagesLayers} [pagesLayers]
 * @property {String} [type]
 * @property {String} [string]
 * 
 * @typedef {Object} Song
 * @property {String} [id]
 * @property {String} [name]
 * @property {String} [artist]
 * @property {String} [tonality]
 * @property {Array<SongFile>} [files]
 * 
 * @typedef {Object} SongFormikProps
 * @property {Song} item
 * @property {KarachordsContext} context
 * @param {SongFormikProps} props 
 */
export const SongFormik = (props) => {
  const context = useContext(Context);
  const { state } = context;
  const song = get(state, 'main.item', null);
  if (!song) {
    return null;
  }
  let title = copy.title.edit;
  /**
   * @type {SongFormValues}
   */
  let initialValues = Object.assign({}, song);
  if (song) {
    const { files } = song;
    if (Array.isArray(files) && files.length > 0) {
      initialValues.fileID = files[0].id;
      initialValues.filename = files[0].name;
    }
  }
  if (isEqual(song, constants.EMPTY_ITEM)) {
    title = copy.title.new;
    initialValues = { name: '', artist: '', file: null, fileID: null, filename: null };
  }
  /**
   * @type {SongFormComputedProps} ComputedProps
   */
  const computedProps = {
    song,
    title,
    initialValues
  };
  const keyString = initialValues.id ? initialValues.id : NEW_SONG_KEY_STRING;
  const muiSongFormKey = `SongForm-${keyString}`;
  const formikKey = `Formik-${keyString}`;
  return (
    <Formik
      onSubmit={handleSongFormSubmit(context)}
      initialValues={initialValues}
      validate={validateSongForm}
      enableReinitialize={true}
      key={formikKey}
    >
      {(formikProps) => (<MUISongForm key={muiSongFormKey} {...props} {...formikProps} {...computedProps} />)}
    </Formik>
  );
}

/**
 *
 * @typedef {Object} SongFormComputedProps
 * @property {Song} song
 * @property {String} title
 * @property {SongFormValues} initialValues
 */
/**
 *
 * @param {SongFormikProps & {isSubmitting: Boolean} & SongFormComputedProps} props
 */
const MUISongForm = (props) => {
  const { isSubmitting, title, initialValues } = props;
  const classes = useSongFormStyles();
  let [showUpload, setShowUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const areButtonsDisabled = isSubmitting || isLoading;
  const role = get(initialValues, 'share.role', constants.ROLES.owner);
  const canEdit = constants.EDIT_ROLES.has(role);
  return (
    <Form style={{ display: 'contents' }}>
      <Grid container item direction={"column"} style={{ padding: '5%' }} spacing={2}>

        <SongFormHeader
          initialValues={initialValues}
          title={title}
          menuAnchorEl={menuAnchorEl}
          setMenuAnchorEl={setMenuAnchorEl}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />

        <Grid item>
          <Field 
          style={{ width: '100%' }}
          type="text" name="name"
          label="Name"
          component={TextField} 
          inputProps={{maxLength: FORM_STRING_INPUT_MAX_LENGTH, disabled: !canEdit}}
          />
        </Grid>

        <Grid item>
          <Field 
          style={{ width: '100%' }} 
          type="text" 
          name="artist" 
          label="Artist" 
          component={TextField} 
          inputProps={{maxLength: FORM_STRING_INPUT_MAX_LENGTH, disabled: !canEdit}} />
        </Grid>

        <Grid item>
          <FileUploadField
            onClose={() => { setShowUpload(true) }}
            fileID={initialValues.fileID}
            filename={initialValues.filename}
            showUpload={showUpload}
            formIsSubmitting={isSubmitting}
            hideRemove={role !== constants.ROLES.owner}
          />
        </Grid>

        <SongFormActions
          isSubmitting={isSubmitting}
          buttonProgress={classes.buttonProgress}
          isLoading={isLoading}
          areButtonsDisabled={areButtonsDisabled}
          initialValues={initialValues}
          setIsLoading={setIsLoading}
        />

      </Grid>
    </Form>

  )
}

