import { makeStyles } from "@material-ui/core";
import { getBreakpoints } from "../../utils/theme";

export const useDialogStyles = makeStyles((theme) => {
  const { mobilePortrait } = getBreakpoints(theme);
  return {
    ctaTypography: {
      paddingTop: '1em'
    },
    hidden: {
      display: 'none',
    },
    flexMarkdownContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center'
    },
    subscribeCardContainer: {
      maxWidth: '165px',
      margin: `${theme.spacing(1)}px`,
      [mobilePortrait]: {
        maxWidth: 'initial',
        width: '100%',
        margin: '0',
        marginTop: `${theme.spacing(1)}px`, 
      }
    },
    upgradeOptionsContainer: {
      flexWrap: 'nowrap',
      [mobilePortrait]: {
        flexDirection: 'column'
      }
    },
    subscribeCard: {
      height: '325px',
      [mobilePortrait]: {
        height: '200px'
      }
    },
    subscribeCardContentContainer: {
      height: '100%',
      padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`
    },
    subscribeCardBestForCTA: {
      lineHeight: '1.15'
    },
    upgradeOptionHighlightText: {
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: '-1rem',
      width: '100%',
      [mobilePortrait]: {
        position: 'initial'
      }
    },
    upgradeOptionContainerHighlight: {
      backgroundColor: theme.palette.primary.main,
      position: 'relative',
      [mobilePortrait]: {
        width: '100%',
        padding: `${theme.spacing(1)}px`
      }
    },
    upgradeOptionContainer: {
      [mobilePortrait]: {
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
        width: '75%'
      }
    },
    selectViewLink: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      margin: '0 0.25rem'
    }
  }
})