import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { PlayContext } from './PlayContext';
import { handleDrawCompletion } from './SketchOverlay';
import { getHandleDrawCompletionParams } from './utils';


export const RenderDrawingButton = () => {
  const context = useContext(PlayContext);
  const params = getHandleDrawCompletionParams(context)
  return (
    <IconButton
      color={"default"} 
      onClick={handleDrawCompletion(params)}
    >
      <Check />
    </IconButton>
  );
};
