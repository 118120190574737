import { Divider } from '@material-ui/core';
import { Drawer } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { DrawerMenuButton } from './DrawerMenuButton';
import { useDrawerStyles } from "./styles";
import { MenuDrawerOptionsList } from './MenuDrawerOptionsList';

export const MenuDrawer = () => {
  const classes = useDrawerStyles();
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context)
  const { state } = context;
  const menuIsOpen = state.nav.menu.isOpen;
  return (
    <Drawer
      id="menu"
      variant="permanent" 
      classes={{
        paper: clsx({
          [classes.drawerOpen]: menuIsOpen,
          [classes.drawerClose]: !menuIsOpen
        })
      }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: menuIsOpen,
        [classes.drawerClose]: !menuIsOpen
      })} 
      anchor={'left'}>
      <DrawerMenuButton />
      <Divider />
      <MenuDrawerOptionsList />
    </Drawer>
  )
};


export default MenuDrawer;
