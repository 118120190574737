import { SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { usePlayStyles } from "./PlayStyles";
import { getScaledInputStyle } from './utils';

/**
 * 
 * @param {import('./DraggableResizeableContainer').DraggableItemProps} props 
 */
export const DraggableFreehand = (props) => {
  const { draggableItem, draggableSvgPath } = usePlayStyles();
  const { note, pageScale } = props;
  const { id, font, symbol } = note;
  const { color } = font;
  const inputStyle = getScaledInputStyle(note, pageScale);
  const { strokeWidth, paths, viewBox } = symbol;
  return (
    <SvgIcon
      className={clsx(draggableItem)}
      style={inputStyle}
      viewBox={viewBox}
    >
      {paths.map((path, index) => {
        const { d } = path
        return (
          <path
            key={`note-${id}-symbol-path-${index}`}
            d={d}
            className={draggableSvgPath}
            strokeWidth={strokeWidth}
            stroke={color} />
        )
      })}
    </SvgIcon>

  );
};
