import { Container, Grid, GridList, SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { v4 as createUUID } from 'uuid';
import { DEFAULT_CONTAINER_SIZE, noteType, symbols } from './playConstants';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { addNote, getPathProps, getUnscaledDefaultNoteOrigin } from './utils';


/**
 * @typedef {import('./PlayContext').Note} Note
 * @typedef {import('./utils').Symbol} Symbol 
 * 
 * 
 * @param {Symbol} symbol
 * @param {Number} pageScale
 * @param {Array<Note>} notes 
 * @param {(newNotes: Array<Note>) => void} setNotes 
 * @param {(isOpen: boolean) => void} setIsOpen 
 * @param {(nextActiveNote: Note) => void} setActiveNote 
 */
const handleAddSymbolNote = (symbol, pageScale, notes, setNotes, setIsOpen, setActiveNote) => () => {
  setIsOpen(false);
  const {x, y} = getUnscaledDefaultNoteOrigin(window, DEFAULT_CONTAINER_SIZE, pageScale);
  const addedNote = addNote({ symbol, type: noteType.symbol, x, y }, notes, setNotes);
  setActiveNote(addedNote);
};

/**
 * @typedef {Object} SymbolButtonProps
 * @property {Symbol} symbol
 * @property {Array<Note>} notes
 * @property {(notes: Array<Note>) => void} setNotes
 * @property {(isOpen: boolean) => void} setIsOpen
 * @property {(note:Note) => void} setActiveNote
 * @param {SymbolButtonProps} props 
 */
const SymbolButton = (props) => {
  const { controlsButton, symbolButton } = usePlayStyles();
  const { symbol, notes, setNotes, setIsOpen, setActiveNote } = props;
  const { state } = useContext(PlayContext);
  const { pageScale } = state;
  const {viewBox, transform} = symbol;
  const gProps = transform ? {transform} : {};
  return (
    <button 
      className={clsx(controlsButton, symbolButton)}
      onClick={handleAddSymbolNote(symbol, pageScale, notes, setNotes, setIsOpen, setActiveNote)}
    >
      <SvgIcon viewBox={viewBox}>
        <g {...gProps}>
        {symbol.paths.map((path) => {
          const pathProps = getPathProps(path);
          return (
              <path key={`path-id-${createUUID()}`} {...pathProps} />
          );
        })}
        </g>
      </SvgIcon>
      <div>
        {symbol.display}
      </div>
    </button>
  )
};

/**
 * 
 * @param {symbols} symbols 
 * @param {*} classes 
 * @param {*} context 
 */
export const renderSymbolList = (symbols, classes, context, setIsOpen) => {
  const { symbolOptionContainer } = classes;
  const { state, methods } = context;
  const { notes } = state;
  const { setNotes, setActiveNote } = methods;
  return symbols.map((symbol, index) => {
    return (
      <li key={`symbol-list-option-${index}`}
        style={{ height: 'inherit', width: 'initial', padding: '0' }}
        className={clsx(symbolOptionContainer)}
      >
        <SymbolButton notes={notes} setNotes={setNotes} symbol={symbol} setIsOpen={setIsOpen} setActiveNote={setActiveNote} />

      </li>

    );
  });
};
/**
 * @return {(e: import('react').SyntheticEvent) => void} e
 */
export const handleClick = (togglePicker) => () => {
  togglePicker();
};

export const SymbolPicker = (props) => {
  const { isOpen, setIsOpen } = props;
  const classes = usePlayStyles();
  const {
    editPanelPickerContainer,
    editPanelPickerGrid,
    fullScreenWidth,
    symbolPickerContainer,
    symbolPickerList,
  } = classes;

  const context = useContext(PlayContext);
  if (!isOpen) {
    return null;
  }

  return (
    <Container className={clsx(editPanelPickerContainer, fullScreenWidth)} disableGutters={true}>
      <Grid className={clsx(editPanelPickerGrid, symbolPickerContainer)} container direction={"column"} justifyContent={"center"} alignItems={"center"}>

        <GridList className={symbolPickerList} style={{ margin: 'inherit' }}>
          {renderSymbolList(symbols, classes, context, setIsOpen)}
        </GridList>

      </Grid>
    </Container>
  );
};


