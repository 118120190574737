import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import * as constants from '../../../constants';
import { Context } from '../../../Context';
import { DonateAd } from '../../ads';
import { SongFormik } from './SongForm';

export const useSongFormStyles = makeStyles((theme) => {
  const mobileBreakpoint = theme.breakpoints.down('xs');
  const smallBreakpoint = theme.breakpoints.down('s');
  return {
    songFormContainer: {
      [mobileBreakpoint]: {
        width: '100%'
      }
    },
    fieldContainer: {
      display: 'contents',
    },
    buttonProgress: {
      position: 'absolute',
      display: 'fixed'
    },
    iconButtonsContainer: {
      maxWidth: '50%',
      justifyContent: 'space-between'
    },
    hide: {
      display: 'none'
    },
    spacingLeft8: {
      padding: `0 0 0 ${theme.spacing(8)}px`,
      [smallBreakpoint]: {
        padding:`0 0 0 ${theme.spacing(4)}px`,
      },
      [mobileBreakpoint]: {
        padding:`0 0 0 ${theme.spacing(2)}px`,
      }
      
    }
  }
})
/**
 * 
 * @param {*} props 
 */
export const SongFormView = (props) => {
  const { songFormContainer, hide } = useSongFormStyles();
  const { state } = useContext(Context);
  const mainItem = state.main.item;
  return (
    <Grid container item direction={'column'} alignItems={'center'} className={clsx(songFormContainer, {
      [hide]: !mainItem
    })}>
      <DonateAd id={constants.adIDs.songFormDonate}/>
      <SongFormik {...props} />
    </Grid>
  );
};