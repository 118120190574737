import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { CONTACT_LINK_MAILING_LIST } from '../../constants';
import { Context } from '../../Context';
import { useDialogStyles } from './styles';

export const CTASignUpInfoDialog = () => {
  /** @type {import('../../utils/persistence').KarachordsContext} */
  const { state } = useContext(Context);
  const { hidden, ctaTypography } = useDialogStyles();
  const { onClose, onConfirm } = state.dialog.methods;
  const { displayText, headerText, ctaText } = state.dialog.props;
  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} wrap={"nowrap"}>
          <Typography variant={"h5"}>
            {headerText}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography variant={"body1"}>
          {displayText}
        </Typography>
        <Typography variant={"body1"} className={clsx(ctaTypography, {
          [hidden]: !ctaText
        })}>
          {ctaText}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color={"primary"} onClick={onConfirm} href={CONTACT_LINK_MAILING_LIST} target={"_blank"}>
          {"Sign up for updates"}
        </Button>
        <Button onClick={onClose}>
          {"Close"}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}