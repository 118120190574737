import { Grid, Icon, IconButton, MenuItem, Select, SvgIcon } from '@material-ui/core';
import { Edit, LineWeight, Redo, Undo } from '@material-ui/icons';
import { mdiEraserVariant } from '@mdi/js';
import React, { useContext, useState } from 'react';
import { FontColorSelector } from './FontColorSelector';
import { LeaveFreehandEditButton } from './LeaveFreehandEditButton';
import { PenPicker } from './PenPicker';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { RenderDrawingButton } from './RenderDrawingButton';
import { getBooleanForNumber, getNumberForBoolean } from './utils';

/**
 * 
 * @param {React.RefObject} freehandEditRef
 */
const handleUndo = (freehandEditRef) => () => {
  /** @type {import('react-sketch-canvas').ReactSketchCanvas} */
  const current = freehandEditRef.current;
  if (!current) {
    console.error('something broke - cant find the current freehandEditRef');
    // TODO: Message the user if this happens
  }
  current.undo();
}

/**
 * 
 * @param {React.RefObject} freehandEditRef
 */
const handleRedo = (freehandEditRef) => () => {
  /** @type {import('react-sketch-canvas').ReactSketchCanvas} */
  const current = freehandEditRef.current;
  if (!current) {
    console.error('something broke - cant find the current freehandEditRef');
    // TODO: Message the user if this happens
  }
  current.redo();
}
const handleSetErasing = (isErasing, setIsErasing) => (e) => {
  const nextValue = getBooleanForNumber(e.target.value);
  if (isErasing === nextValue) {
    return
  }
  setIsErasing(nextValue);
}


export const selectors = {
  color: 'color',
  width: 'width',
}

export const EditFreehandPanel = () => {
  const context = useContext(PlayContext);
  const { state, methods } = context;
  const { freehandEditRef, strokeWidth, isErasing } = state;
  const { setIsErasing } = methods;
  const { controlsButton, editPanel } = usePlayStyles();

  const [openSelector, setOpenSelector] = useState(null);

  const displaySelector = (selector) => () => {
    let nextSelector = selector;
    if (openSelector === nextSelector) {
      nextSelector = null;
    }
    setOpenSelector(nextSelector);
  }
  const hideSelector = () => {
    setOpenSelector(null)
  }


  return (
    <Grid className={editPanel} container alignItems={"center"} justifyContent={"space-around"} direction={"row"} wrap={"nowrap"}>

      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <IconButton className={controlsButton} onClick={handleUndo(freehandEditRef)}>
          <Undo />
        </IconButton>
      </Grid>

      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <IconButton className={controlsButton} onClick={handleRedo(freehandEditRef)}>
          <Redo />
        </IconButton>
      </Grid>

      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <Select value={getNumberForBoolean(isErasing)} onChange={handleSetErasing(isErasing, setIsErasing)} disableUnderline={true}>
          <MenuItem value={0}>
            <Icon>
              <Edit />
            </Icon>
          </MenuItem>
          <MenuItem value={1}>
            <SvgIcon>
              <path d={mdiEraserVariant} />
            </SvgIcon>
          </MenuItem>
        </Select>
      </Grid>

      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <PenPicker openSelector={openSelector} afterPick={hideSelector} />
        <IconButton className={controlsButton} onClick={displaySelector(selectors.width)}>
          <LineWeight />
          <span> {strokeWidth} </span>
        </IconButton>
      </Grid>

      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <FontColorSelector />
      </Grid>
{/* 
      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"row"} wrap={"nowrap"}>
        <LayerSelector />
      </Grid> */}
      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <RenderDrawingButton />
      </Grid>

      <Grid container item  alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <LeaveFreehandEditButton />
      </Grid>

    </Grid>
  );
};
