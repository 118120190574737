import { format, utcToZonedTime } from 'date-fns-tz';
import get from 'lodash.get';
import { CLEAR_CURRENT_ITEM, DELETE_DATA, SET_DATA } from '../actions';
import { DELETE_SHARE_TYPES, REVOKE_SHARE_COPY, ROLES, SHARE_TYPES, SHARE_TYPE_TO_DATA_TYPE } from '../constants';
import { updateSongsOnList } from '../utils';
import { dispatchFetchErrorNotification, dispatchSuccessNotification } from '../utils/notfications';
import { deleteOne, revokeShare } from '../utils/persistence';
import { SHARE_URL } from '../utils/persistence/constants';

/**
 * 
 * @param {RedeemedShareDetails} share
 * @return {String}
 */
export function getSharedWithYouAtCopy(share) {
  const copy = getSharedWithYouAtDateStrings(share);
  if (!copy) {
    return null;
  }
  const { month, day, year } = copy;
  const sharedAtCopy = `You first accessed on ${month} ${day}, ${year}`;
  return sharedAtCopy;
}
/**
 * 
 * @param {SharedDetails} share
 * @return {String}
 */
export function getYouSharedAtCopy(share) {
  const { month, day, year } = getYouSharedAtDateStrings(share);
  const { source } = share
  let sharedAtCopy = `You shared on ${month} ${day}, ${year}`;
  if (source && source.name) {
    sharedAtCopy += ` as a part of list ${source.name}`;
  }
  return sharedAtCopy;
}

/**
 * 
 * @param {{redeemedAt: String}} share `redeemedAt` is a date string in ISO format
 * @return {{month?: String, day?: String, year?: string}}
 */
function getSharedWithYouAtDateStrings(share) {
  if (!share) {
    return null
  }
  const { redeemedAt } = share;
  const utcDate = new Date(redeemedAt);
  const localDate = utcToZonedTime(utcDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
  const day = format(localDate, 'do');
  const month = format(localDate, 'MMM');
  const year = format(localDate, 'y');
  return { month, day, year };
}

/**
 * 
 * @param {{createdAt: String}} share `redeemedAt` is a date string in ISO format
 * @return {{month?: String, day?: String, year?: string}}
 */
function getYouSharedAtDateStrings(share) {
  if (!share) {
    return {}
  }
  const { createdAt } = share;
  const utcDate = new Date(createdAt);
  const localDate = utcToZonedTime(utcDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
  const day = format(localDate, 'do');
  const month = format(localDate, 'MMM');
  const year = format(localDate, 'y');
  return { month, day, year };
}

/**
 * 
 * @param {String} name 
 * @param {Object<String, {display: String, description: String}>} copy 
 * @return {{display: String, description: String}}
 */
export const getRoleCopy = (name, copy) => {
  return copy[name];
}

/**
 * 
 * @param {Array<SharedDetails>} shares
 * @param {Array<import('../appState').AssignableRole>} roles
 * @return {SharedDetails & {roles: Array<import('../appState').AssignableRole>} | null}
 */
export function getInitialShareForSong(shares, roles) {
  let initialShare = shares.find((share) => share.type === SHARE_TYPES.song);
  if (initialShare) {
    initialShare = Object.assign({}, initialShare, { roles });
  }
  // @ts-ignore
  return initialShare;
}
export function handleRemoveRevokedShareFromSong(revokedShareId) {
  return (song) => {
    /** @type {import('../constants').Item} */
    const { shares } = song;
    const updatedShares = shares.filter(share => share.id !== revokedShareId);
    const updatedSong = Object.assign({}, song, { shares: updatedShares });
    return updatedSong;
  };
}

function removeRevokedFromShares(item, revokedShareId) {
  const { shares } = item;
  const updatedShares = shares.filter(({ id }) => id !== revokedShareId);
  return updatedShares;
}
/**
 * 
 * @param {SharedDetails} share 
 * @param {import('../constants').Item} item 
 * @param {import('../Context').KarachordsContext} context
 */
export const handleRevokeShare = (share, item, context) => async () => {
  const { firebase, dispatch } = context;
  const { id: revokeShareId, type } = share;
  const userToken = await firebase.auth().currentUser.getIdToken();
  const resp = await deleteOne(SHARE_URL, userToken)(revokeShareId);
  const { status } = resp;
  if (status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    return;
  }
  const { data } = resp;
  const { id: revokedShareId } = data;
  const updatedShares = removeRevokedFromShares(item, revokedShareId);

  const updatedItem = Object.assign({}, item, { shares: updatedShares });
  if (type === SHARE_TYPES.list) {
    updateSongsOnList(updatedItem, context, handleRemoveRevokedShareFromSong(revokedShareId));
  }
  /** @type {import('../reducers/functions').SetDataPayload} */
  const payload = { data: [updatedItem], dataType: SHARE_TYPE_TO_DATA_TYPE[type] }
  dispatch({ type: SET_DATA, payload });
}
/**
 * 
 * @param {import('../constants').Item} item 
 * @param {import('../Context').KarachordsContext} context
 */
export const handleRevokeAllShares = (item, context) => async () => {
  const { firebase, dispatch } = context;
  const share = get(item, 'shares[0]', {});
  const { id: revokeShareId, type } = share;
  const userToken = await firebase.auth().currentUser.getIdToken();
  const params = new URLSearchParams();
  params.append("type", DELETE_SHARE_TYPES.cascade);
  const resp = await revokeShare(userToken, revokeShareId, params);
  const { status } = resp;
  if (status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    return;
  }
  const { data } = resp;
  const { id: revokedShareId } = data;

  const updatedItem = Object.assign({}, item, { shares: [] });
  if (type === SHARE_TYPES.list) {
    updateSongsOnList(updatedItem, context, handleRemoveRevokedShareFromSong(revokedShareId));
  }
  /** @type {import('../reducers/functions').SetDataPayload} */
  const payload = { data: [updatedItem], dataType: SHARE_TYPE_TO_DATA_TYPE[type] };
  dispatch({ type: SET_DATA, payload });
  dispatchSuccessNotification(`Successfully revoked all shares for ${item.name}`, dispatch);
}

export const hideRevokeAll = (shares, role) => {
  return (!shares || shares.length < 2) || role !== ROLES.owner;
};

export const getRevokeAllCopy = (name = 'this') => {
  const out = `${REVOKE_SHARE_COPY.revokeAll.confirm} ${name}`;
  return out;
}

/**
 * 
 * @param {React.Dispatch<import('../actions').Action>} dispatch 
 * @param {String} itemID 
 * @param {import('./../constants').DATA_TYPES} dataType 
 * @param {String} successMessage
 */
export function deleteActiveStateItem(dispatch, itemID, dataType, successMessage) {
  dispatch({ type: CLEAR_CURRENT_ITEM });
  dispatch({ type: DELETE_DATA, payload: { dataType: dataType, id: itemID } });
  dispatchSuccessNotification(successMessage, dispatch);
}

/**
 * @param {import('../constants').Item} item
 */
export const isSongWithListShares = (item) => {
  const { shares, songs } = item;
  const isSong = !songs;
  if (isSong) {
    return shares.reduce((acc, share) => {
      if (acc) {
        return true;
      }
      const { type } = share;
      return type === SHARE_TYPES.list;
    }, false);
  }
}