import get from 'lodash.get';
import set from 'lodash.set';
import { v4 as generateUUID } from 'uuid';
import { END_PLAY, SELECT_ITEM, SELECT_VIEW } from '../actions';
import { initialState } from "../appState";
import * as constants from '../constants';
import { clearAllFilters, getItemsMap, getMergedPlaySongs, pushPlayToHistory, shouldIgnoreSelectView, updateArtists, updateSongInState } from './utils';

/**
 * 
 * @typedef {import("../components/play/PlayContext").PlayViewState} PlayState
 * @typedef {import('./../components/forms/song/SongForm').Song} Song
 * @typedef {import('../components/forms/list/').List} List
 */
/**
 * @typedef {import('../App').KarachordsState} KarachordsState
 * @param {Object} payload 
 * @param {KarachordsState} state
 * @return {KarachordsState}
 */
export const toggleDrawer = (payload, state) => {
  const nav = state.nav;
  const menu = nav.menu;
  const isOpen = !menu.isOpen;
  Object.assign(menu, { isOpen });
  Object.assign(nav, { menu });
  return Object.assign({}, state, { nav });
}

/**
 * @typedef {Object} SelectViewPayload
 * @property {String} name view name
 * @property {import('../constants').Item} [item] optional item to set as selected item
 * @property {boolean} [skipHistory] if true, skip updating history in state via the history API
 */
/**
 * @param {SelectViewPayload} payload 
 * @param {KarachordsState} state
 * @return {KarachordsState} 
 */
export const selectView = (payload, state) => {
  const { name, item, skipHistory } = payload;
  
  if (shouldIgnoreSelectView(state, payload)) {
    return state;
  }
  const { history } = state;
  const updated = Object.assign({}, state);
  const map = getItemsMap(name, state);
  updated.nav.subMenu.map = map;
  updated.nav.subMenu.items = map ? Array.from(map.values()) : null;
  const newView = constants.VIEWS.map.get(name);
  updated.dialog = constants.INITIAL_DIALOG_STATE;
  updated.main.view = newView;
  updated.main.Component = newView.Main;
  updated.main.item = item ? item : null;
  clearAllFilters(updated);
  /** @type {import('../actions').Action} */
  const historyState = {type: SELECT_VIEW, payload}
  let path = `/${name.toLocaleLowerCase()}`;
  if (item) {
    path += `/item`;
  }
  if (!skipHistory) {
    history.push(path, historyState);
  }
  return updated;
}
/**
 * @typedef {Object} SelectItemPayload
 * @property {import('../constants').Item} item
 * @property {boolean} [skipHistory] if true, skip updating history API
 */
/**
 * 
 * @param {SelectItemPayload} payload 
 * @param {KarachordsState} state 
 */
export const selectItem = (payload, state) => {
  const { item: payloadItem, skipHistory } = payload;
  const item = Object.assign({}, payloadItem);
  const currentItem = state.main.item;
  if (currentItem === item) {
    return state;
  }
  const updated = Object.assign({}, state);
  delete updated.main.item;
  updated.main.item = Object.assign({}, item);
  const { history } = state;
  /** @type {import('../actions').Action} */
  const historyState = {type: SELECT_ITEM, payload};
  if (!skipHistory) {
    history.push(`/${updated.main.view.name.toLocaleLowerCase()}/item`, historyState)
  }
  
  return updated;
}

export const clearCurrentItem = (payload, state) => {
  const updated = Object.assign({}, state);
  updated.main.item = null;
  return updated;
}

/**
 * @typedef {Object} SetLoadingPayload
 * @property {Boolean} value
 * 
 * @param {SetLoadingPayload} payload
 * @param {KarachordsState} state 
 */
export const setLoading = (payload, state) => {
  const updated = Object.assign({}, state);
  updated.isLoading = payload.value;
  return updated;
}

/**
 * @typedef {Object} SetSongsPayload
 * @property {Array<import('../components/forms/song/SongForm').Song>} songs
 * @property {?Boolean} [shouldOverwrite]
 * 
 * @param {SetSongsPayload} payload 
 * @param {KarachordsState} state 
 */
export const setSongs = (payload, state) => {
  const { shouldOverwrite, songs } = payload
  const updated = Object.assign({}, state);
  if (shouldOverwrite) {
    updated.songs.map = new Map();
  }
  songs.forEach(song => {
    const { id } = song;
    updated.songs.map.set(id, song);
  })
  updated.songs.list = Array.from(updated.songs.map.values());
  return state;
}
/**
 * @typedef {(Song & List & ItemShares)} Item
 * 
 * @typedef {Object} ItemShares
 * @property {RedeemedShareDetails} [share]
 * @property {Array<SharedDetails>} [shares]
 * @property {Array<AssignableRole>} [roles]
 * 
 * @typedef {Object} AssignableRole
 * @property {String} id
 * @property {String} name
 */
/**
 * @typedef {Object} SetDataPayload
 * @property {Array<Item>} data
 * @property {String} dataType
 * @property {Boolean} [shouldOverwrite] should the currently stored lists and maps for this type be completely removed and replaced? defaults false
 * @property {Boolean} [isSilent] Should this supress UI re-renders? defaults false
 * 
 * 
 * @param {SetDataPayload} payload 
 * @param {KarachordsState} state 
 */
export const setData = (payload, state) => {
  const { shouldOverwrite, data, dataType, isSilent } = payload;
  const newState = isSilent ? state : Object.assign({}, state);
  const updated = setDataOnState(dataType, newState, shouldOverwrite, data, true);
  return updated;
}

/**
 * @typedef {Object} OpenDialogPayload
 * @property {import('./../constants').DIALOG_NAMES} dialog
 * @property {import("../appState").DialogMethods} [methods]
 * @property {import("../appState").DialogProps} [props]
 */
/**
 * 
 * @param {OpenDialogPayload} payload 
 * @param {KarachordsState} state 
 */
export const openDialog = (payload, state) => {
  const { dialog, methods, props } = payload;
  const updated = Object.assign({}, state);
  const selectedDialog = constants.DIALOGS.map.get(dialog);
  if (!selectedDialog) {
    return state;
  }
  updated.dialog.dialog = selectedDialog;
  updated.dialog.isOpen = true;
  if (props) {
    updated.dialog.props = props;
  }
  if (methods) {
    updated.dialog.methods = methods;
  }
  return updated;
}
/**
 * @typedef {Object} CloseDialogPayload
 */
/**
 * 
 * @param {CloseDialogPayload} payload 
 * @param {KarachordsState} state 
 */
export const closeDialog = (payload, state) => {
  const updated = Object.assign({}, state);
  updated.dialog.isOpen = false;
  return updated;
}

/**
 * @typedef {Object} PlaySongPayload
 * @property {import('./../components/forms/song/SongForm').Song} song
 * @property {boolean} [isSilent]
 * @property {boolean} [shouldMerge]
 */
/**
 * 
 * @param {PlaySongPayload} payload 
 * @param {KarachordsState} state 
 */
export const playSong = (payload, state) => {
  const { song, isSilent, shouldMerge } = payload;
  const newState = isSilent ? state : Object.assign({}, state);
  const updated = getUpdated(song, constants.DATA_TYPES.songs, newState);
  /** @type {PlayState} */
  let updatedPlay = getPlay(song);
  if (shouldMerge) {
    const songs = getMergedPlaySongs(state, updatedPlay);
    updatedPlay = Object.assign({}, updatedPlay, { songs });
  }
  updated.play = updatedPlay;
  updated.isLoading = false;
  pushPlayToHistory(state, Object.assign({type: END_PLAY}));
  return updated;
}
/**
 * @typedef {Object} PlaySetlistPayload
 * @property {List} list
 * @property {boolean} [isSilent]
 * @property {boolean} [shouldMerge]
 * @property {boolean} [shouldUpdateSubMenu]
 */
/**
 * 
 * @param {PlaySetlistPayload} payload 
 * @param {KarachordsState} state 
 */
export const playSetlist = (payload, state) => {
  const { list, isSilent, shouldMerge, shouldUpdateSubMenu = false} = payload;
  const newState = isSilent ? state : Object.assign({}, state);
  const updatedWithList = getUpdated(list, constants.DATA_TYPES.lists, newState);
  const { songs } = list;
  const updated = songs.reduce((acc, song) => {
    return getUpdated(song, constants.DATA_TYPES.songs, acc, shouldUpdateSubMenu);
  }, updatedWithList);
  const updatedPlay = getPlay(list);
  if (shouldMerge) {
    const nextPlaySongs = getMergedPlaySongs(state, updatedPlay);
    updatedPlay.songs = nextPlaySongs;
  }
  updated.play = updatedPlay;
  updated.isLoading = false;
  pushPlayToHistory(state, Object.assign({type: END_PLAY}));
  return updated;
}

/**
 * @typedef {Object} EndPlayPayload
 * @param {EndPlayPayload} payload 
 * @param {KarachordsState} state
 * @return {KarachordsState} Karachords state
 */
export const endPlay = (payload, state) => {
  const updated = Object.assign({}, state);
  updated.play = initialState.play;

  const { history, main } = updated;
  const { item, view } = main;
  const { name } = view;
  let path = `/${name.toLocaleLowerCase()}`;
  /** @type {SelectViewPayload} */
  const selectViewPayload = {name, item}
  const historyState = {type: SELECT_VIEW, payload: selectViewPayload};
  if (item) {
    path += '/item';
  }
  history.replace(path, historyState);
  return updated;
}
/**
 * 
 * @param {import('../constants').Item} item
 * @param {KarachordsState} state
 * @param {string} dataType
 * @param {boolean} [shouldUpdateSubMenu]
 * @return {KarachordsState}
 */
const getUpdated = (item, dataType, state, shouldUpdateSubMenu) => {
  return setDataOnState(dataType, state, false, [item], shouldUpdateSubMenu);
}

/**
 * 
 * @param {import('./../constants').Item} item
 * @return {PlayState}
 */
const getPlay = (item) => {
  if (!item) {
    return {
      songs: null,
    }
  }
  if (item.songs) {
    return {
      songs: item.songs
    }
  }
  return {
    songs: [item]
  }
}

/**
 * @param {string} dataType
 * @param {KarachordsState} state
 * @param {boolean} shouldOverwrite
 * @param {Array<Item>} data
 * @param {boolean} [shouldUpdateSubMenu]
 */
const setDataOnState = (dataType, state, shouldOverwrite, data, shouldUpdateSubMenu) => {
  const mapPath = constants.STATE_DATA_PATHS.get(dataType).map;
  /** @type {Map} */
  let map = get(state, mapPath);
  if (shouldOverwrite) {
    map = new Map();
    set(state, mapPath, map);
  }
  data.forEach(datum => {
    const { id } = datum;
    map.set(id, datum);
  });
  const listPath = constants.STATE_DATA_PATHS.get(dataType).list;
  const list = Array.from(map.values());
  set(state, listPath, list);
  if (dataType === constants.DATA_TYPES.songs) {
    // if this was a song, we should update the list of artists (for filtering)
    updateArtists(data, state);
  }
  const mainItem = state.main.item;
  if (mainItem && map.has(mainItem.id)) {
    // we have main item and it was in the data we just updated
    // therfore, copy over changes to main item so UI stays updated
    state.main.item = map.get(mainItem.id);
  }
  const subMenu = state.nav.subMenu;
  if (subMenu && subMenu.items && shouldUpdateSubMenu) {
    // if we have a subMenu, we're editing songs or lists
    // update the subMenu copies with the new data
    state.nav.subMenu.map = map;
    state.nav.subMenu.items = list;
  }
  // if (subMenuIsActive(subMenu, map)) {
  // if updated.nav.subMenu.items has anything in common with what we just saved, update that as well

  // }
  return state;
}

/**
 * @typedef {Object} DeleteDataPayload
 * @property {string} id
 * @property {string} dataType
 */

/**
 * 
 * @param {KarachordsState} state 
 * @param {DeleteDataPayload} payload
 * @return {KarachordsState}
 */
export const deleteData = (payload, state) => {
  const { dataType, id } = payload;
  const newState = Object.assign({}, state);
  const mapPath = constants.STATE_DATA_PATHS.get(dataType).map;
  /** @type {Map<string, import('../constants').Item>} */
  const map = get(newState, mapPath, new Map());
  if (map.has(id)) {
    map.delete(id);
  }
  const data = Array.from(map.values());
  const updated = setDataOnState(dataType, newState, true, data, true);
  return updated;
}

/**
 * @typedef {Object} AddNotificationPayload
 * @property {String} message
 * @property {import('notistack').OptionsObject} [options]
 * 
 * @typedef {Object} Notification
 * @property {String} message
 * @property {import('notistack').OptionsObject} options
 * 
 * @param {AddNotificationPayload} payload 
 * @param {KarachordsState} state 
 */
export const addNotification = (payload, state) => {
  const { message, options = {} } = payload;
  const prevNotificationsMap = get(state, 'notifications.map', new Map());

  if (!options.key) {
    Object.assign(options, { key: generateUUID() })
  }
  /** @type {Notification} */
  const notification = { message, options };
  const nextNotificationsMap = new Map(prevNotificationsMap.entries());
  nextNotificationsMap.set(options.key, notification)
  const nextNotificationsList = Array.from(nextNotificationsMap.values());
  /** @type {import('../appState').NotificationsState} */
  const notifications = { list: nextNotificationsList, map: nextNotificationsMap }
  return Object.assign({}, state, { notifications });
}

/**
 * @typedef {Object} RemoveNotificationPayload
 * @property {String} key
 * @property {import('notistack').CloseReason} [reason]
 * 
 * Silently updates state to remove a notification
 * Won't trigger a re-render
 * @param {RemoveNotificationPayload} payload 
 * @param {KarachordsState} state 
 */
export const removeNotification = (payload, state) => {
  const { key } = payload
  /** @type {Map<String, Notification>} */
  const prevMap = get(state, 'notifications.map', new Map());
  prevMap.delete(key);
  const map = new Map(prevMap.entries());
  const list = Array.from(map.values());
  const notifications = { list, map }
  return Object.assign(state, { notifications });
}

/**
 * @typedef {Object} SetFiltersPayload
 * @property {Array<import('../components/lists/utils').Filter>} filters
 * @property {String} path path in state where filters should be set
 * 
 * @param {SetFiltersPayload} payload 
 * @param {KarachordsState} state 
 */
export const setFilters = (payload, state) => {
  const { path, filters } = payload
  const updated = Object.assign({}, state);
  set(updated, path, filters);
  return updated;
}

/**
 * @typedef {Object} ResetStatePayload
 * 
 */
export const resetState = () => {
  const updated = Object.assign({}, initialState);
  return updated;
}


/**
 * @typedef {Object} UpdateLocalSongPayload
 * @property {Song} song
 * @property {Boolean} [isSilent]
 * 
 * @param {UpdateLocalSongPayload} payload
 * @param {KarachordsState} state
 */
export const updateLocalSong = (payload, state) => {
  const { song, isSilent } = payload;
  let updated = isSilent ? state : Object.assign({}, state);
  updated = updateSongInState(song, state)
  return updated;
}

/**
 * @typedef {Object} UpdateMaxPayload
 * @property {Number} max
 * @property {constants.DATA_TYPES} dataType
 * @property {Number} [maxSize]
 * 
 * @param {UpdateMaxPayload} payload 
 * @param {KarachordsState} state
 * @return {KarachordsState}
 */
export const updateMax = (payload, state) => {
  const updated = Object.assign({}, state);
  const { maxSize, max, dataType } = payload;
  const path = constants.STATE_DATA_PATHS.get(dataType).max
  set(updated, path, max);
  if (maxSize) {
    set(updated, 'lists.maxSize', maxSize);
  }
  return updated;
}


/**
 * @typedef {Object} SetPendingSharePayload
 * 
 * @param {SetPendingSharePayload} payload 
 * @param {KarachordsState} state 
 */
export const setPendingShare = (payload, state) => {
  const updated = Object.assign({}, state);
  set(updated, 'pendingShare', payload);
  return updated;
}