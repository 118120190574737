import { Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { PDFSong } from './PDFSong';
import { PDF } from './playConstants';
import { PlayContext } from './PlayContext';

export const SongComponent = () => {
  const context = useContext(PlayContext);
  const { currentFile: file, currentSong: song } = context.state;
  const { name } = song;
  if (!file) {
    return (
      <Grid item>
        <Typography>
          {`${name} has no files that we can interpret. Please add some before playing this song.`}
        </Typography>
      </Grid>
    );
  }
  const { type } = file;
  if (type === PDF) {
    return (<PDFSong />);
  }

  return (<div>file type not supported, please upload a different file</div>);
};
