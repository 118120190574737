import React from 'react';
import { Menu } from '@material-ui/core';
import { handleCloseMenu } from './utils';
import { ViewShareDetailsMenuItem } from '../../menuItems/ViewShareDetailsMenuItem';
import { DELETE_ROLES, EDIT_ROLES, ROLES } from '../../../constants';
import { RemoveItemMenuItem } from '../../menuItems/RemoveItemMenuItem';
import { ClearSetlistMenuItem } from './ClearSetlistMenuItem';
import { DeleteListMenuItem } from './DeleteListMenuItem';
import { RevokeAllSharesMenuItem } from '../../menuItems/RevokeAllSharesMenuItem';
import { hideRevokeAll } from '../../utils';
import get from 'lodash.get';

export const ListFormHeaderMenu = (props) => {
  const { menuAnchorEl, setMenuAnchorEl, editingList, setEditingList, context, setLoading } = props;
  const { shares } = editingList;
  const role = get(editingList, 'share.role', ROLES.owner);
  const canDelete = DELETE_ROLES.has(role);
  const canClear = EDIT_ROLES.has(role);
  return (
    <Menu
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={handleCloseMenu(setMenuAnchorEl)}
    >
      <div>
        <ViewShareDetailsMenuItem
          item={editingList}
        />
      </div>
      <div>
        <ClearSetlistMenuItem
          editingList={editingList}
          setEditingList={setEditingList}
          context={context}
          hidden={!canClear}
        />
      </div>
      <div>
        <RevokeAllSharesMenuItem
          item={editingList}
          context={context}
          hidden={hideRevokeAll(shares, role)}
        />
      </div>
      <div>
        <DeleteListMenuItem
          editingList={editingList}
          context={context}
          setLoading={setLoading}
          hidden={!canDelete}
        />
      </div>
      <div>
        <RemoveItemMenuItem
          item={editingList}
          setIsLoading={setLoading}
          context={context}
          hidden={canDelete}
        />
      </div>
    </Menu >
  )
};
