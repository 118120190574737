import firebase from 'firebase/app';
import get from 'lodash.get';
import React from 'react';
import { END_PLAY, OPEN_DIALOG, SET_LOADING, SET_PENDING_SHARE } from './actions';
import { PlayView } from './components/play';
import { FullScreenSpinner } from './components/spinners';
import { DATA_TYPES, DIALOG_NAMES } from './constants';
import { handleGetAll } from './utils/handlers';
import { dispatchFetchErrorNotification, dispatchSuccessNotification } from './utils/notfications';
import { getAll, getOne } from './utils/persistence';
import { LISTS_URL, SHARE_URL, SONGS_URL } from './utils/persistence/constants';
import { PrimaryView } from './views/PrimaryView';
/**
 *
 * @param {String} token
 * @param {(action:import('./actions').Action) => void} dispatch
 */
const loadInitialData = async (token, dispatch) => {
  try {
    const promises = [
      getAll(
        SONGS_URL,
        token,
        handleGetAll(dispatch, DATA_TYPES.songs, true)
      ),
      getAll(
        LISTS_URL,
        token,
        handleGetAll(dispatch, DATA_TYPES.lists, true)
      )
    ];
    await Promise.all(promises);
  } catch (error) {
    console.error('caught error in load initial Data', error);
  }
};
/**
 * @param {import('./App').KarachordsState} state
 * @return {React.FunctionComponent}
 */
export const getMainComponent = (state) => {
  let Component = state.main.Component ? state.main.Component : () => null;
  return Component;
};
/**
 * @param {import('./App').KarachordsState} state
 * @return {React.FunctionComponent}
 */
export const getDialogComponent = (state) => {
  return state.dialog.dialog.Component;
};
// /**
//  * 
//  * @param {(action:import('./actions').Action) => void} dispatch
//  * @return {(e: React.SyntheticEvent<window>) => void}
//  */
// const handleBeforeInstallPrompt = (dispatch) => (e) => {
//   // Prevent Chrome 67 and earlier from automatically showing the prompt
//   e.preventDefault();
//   console.info(e);
//   /** @type {import('./reducers/functions').AddNotificationPayload} */
//   const payload = { message: "Pls install" }
//   /** @type {import('./actions').Action} */
//   const action = { type: ADD_NOTIFICATION, payload }
//   dispatch(action);
// };

/**
 * @param {firebase} firebase
 * @param {firebase.analytics.Analytics} analytics
 * @param {import('./Context').KarachordsContext} context
 * @param {String} [shareId]
 * @return {firebase.Unsubscribe} 
 */
export const initApp = (firebase, analytics, context, shareId) => {
  const { dispatch } = context;
  let redeemedShare;
  let notified = false;
  const unsubscribeFromAuthState = firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      if (!shareId) {
        shareId = get(context, 'state.pendingShare.id', null);
      }
      const userToken = await user.getIdToken(true);
      analytics.setUserId(user.uid);
      if (shareId && !redeemedShare) {
        const shareResp = await getOne(SHARE_URL, userToken)(shareId);
        if (shareResp.status !== 200) {
          dispatchFetchErrorNotification(shareResp, dispatch);
        }
        redeemedShare = get(shareResp, 'data', null)
      }
      dispatch({type: SET_LOADING, payload: {value: true}});
      await loadInitialData(userToken, dispatch);
      dispatch({ type: SET_LOADING, payload: { value: false } });
      if (redeemedShare && !notified) {
        dispatchSuccessNotification(`Successfully received shared item!`, dispatch);
        notified = true;
      }
    } else {
      if (shareId) {
        dispatch({ type: SET_PENDING_SHARE, payload: {id: shareId}});
      }
      /** @type {import('./reducers/functions').OpenDialogPayload} */
      const payload = { dialog: DIALOG_NAMES.AUTH };
      dispatch({ type: OPEN_DIALOG, payload });
      dispatch({ type: SET_LOADING, payload: { value: false } });
    }
  })
  return unsubscribeFromAuthState;
}

/**
 * 
 * @param {import('./appState').KarachordsState} state 
 * @param {React.Dispatch<import('./actions').Action>} dispatch 
 */
export function getView(state, dispatch) {
  let View = () => null;

  if (state.isLoading) {
    // eslint-disable-next-line react/display-name
    View = () => (
      <FullScreenSpinner />
    );
  } else if (state.play.songs) {
    // eslint-disable-next-line react/display-name
    View = () => (
      <PlayView {...state.play} onClose={() => { dispatch({ type: END_PLAY }); }} />
    );
  } else {
    // eslint-disable-next-line react/display-name
    View = () => (
      <PrimaryView />
    );
  }
  return View;
}
