import { makeStyles } from '@material-ui/core';
import { getBreakpoints } from '../../utils/theme';
import { DEFAULT_BORDER_WIDTH } from './playConstants';



/**
 * @typedef {import('./utils').Layer} Layer
 */

const controlsSizeInt = 6;
export const controlsSize = `${controlsSizeInt}rem`;
// const halfControlsSize = `${controlsSizeInt / 2}rem`;
export const doubleControlsSize = `${controlsSizeInt * 2}rem`;
// const rootHeightOffset = `${controlsSizeInt * 2}rem`;

export const usePlayStyles = makeStyles((theme) => {
  const primaryColor = theme.palette.primary.main;
  const { mobilePortrait } = getBreakpoints(theme);
  return {
    playRoot: {
      height: `100%`,
      width: '100%',
      overflow: 'visible',
      display: 'block'
    },
    appBar: {
      top: 'auto !important',
      bottom: '0',
      left: '0',
      position: 'fixed',
      display: 'block',
      width: '100%',
      right: 'auto',
      padding: 'initial',
    },
    toolbar: {
      [mobilePortrait]: {
        padding: 0
      }
    },
    pdfContainer: {
      display: 'block',
      height: '100%',
      width: '100%',
      margin: '0 !important',
      zIndex: 0,
    },
    controlsButton: {
      minHeight: controlsSize,
      minWidth: controlsSize,
      [mobilePortrait]: {
        minWidth: 'initial',
        padding: '0px',
      },
    },
    editPanelContainer: {
      bottom: `calc(${controlsSize} - 1px)`,
      position: 'fixed',
      height: controlsSize,
      backgroundColor: primaryColor,
      zIndex: 1099,
      width: '100%',
      [mobilePortrait]: {
        padding: `${theme.spacing()}px`
      }
    },
    selectEditModeContainer: {
      backgroundColor: primaryColor,
      height: '100%',
      width: '100%',
    },
    editButton: {
      minHeight: controlsSize,
      minWidth: controlsSize,
      [mobilePortrait]: {
        minHeight: 'initial',
        minWidth: 'initial',
        padding: 0,
      }
    },
    editComponent: {
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
    dragAndDropEditComponent: {
      zIndex: 2,
    },
    editPanel: {
      height: '100%',
    },
    editPanelPickerContainer: {
      overflow: 'auto',
      position: 'absolute',
      display: 'block',
      bottom: `calc(${controlsSize} - 1px)`,
      zIndex: 1098,
      backgroundColor: primaryColor,
      minHeight: controlsSize,
      width: doubleControlsSize,
      height: '100%'
    },
    fullScreenWidth: {
      width: '100vw',
      left: '0',
      margin: '0',
      maxWidth: 'initial'
    },
    editPanelColorPicker: {
      width: 'initial',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    editPanelPickerGrid: {
      height: controlsSize,
    },
    editPanelPickerSelect: {
      width: '80%'
    },
    resizeArrow: {
      transform: 'rotate(45deg)',
      position: 'absolute',
      fontSize: '3rem',
      right: '0',
      bottom: '0',
      color: 'black',
      width: 'initial',
      height: 'initial'
    },
    draggableTextContainer: {
      position: 'absolute',
      zIndex: 30,
      fontWeight: 'bold',
      cursor: "move",
      paddingRight: `${theme.spacing(2)}px`
    },
    draggableItemContainer: {
      position: 'absolute',
      zIndex: 30,
      fontWeight: 'bold',
      cursor: "move",
    },
    activeNote: {
      border: `dashed ${DEFAULT_BORDER_WIDTH}px ${primaryColor}`,
      zIndex: 30,
    },
    draggableTextResizableContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    draggableItem: {
      backgroundColor: 'transparent',
      border: '0',
      width: '100%',
      height: '100%',
    },
    draggableTextInput: {
      backgroundColor: 'transparent',
      border: '0',
      width: '75%',
      height: '75%',
    },
    activeInput: {
      border: `dashed 1px ${primaryColor}`,
    },
    draggableSymbol: {
      position: 'absolute',
      zIndex: 2,
      fontWeight: 'bold',
      cursor: "move",
    },
    activeEditingComponent: {
      zIndex: 20,
    },
    symbolPickerContainer: {
      width: 'max-content',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      alignItems: 'flex-start',
    },
    symbolPickerList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      height: '100%',
      width: 'auto'
    },
    symbolOptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    // @ts-ignore
    symbolButton: {
      appearance: 'unset',
      backgroundColor: 'white',
      borderRight: '1px black solid',
      minHeight: controlsSize,
      minWidth: `calc(${controlsSize} - 2vw)`,
      [mobilePortrait]: {
        minWidth: `calc(${controlsSize} - 1vw)`,
      },
    },
    removeNoteButton: {
      color: theme.palette.primary.main,
      padding: '0',
      position: 'absolute',
      left: '-1.5rem',
      top: '-1.5rem'
    },
    layerSelectMenuItem: {
      padding: '6px'
    },
    layerSelectLayerChangeSpan: {
      // padding: '1rem 0 1rem 0',
    },
    draggableSvgPath: {
      fill: 'none',
      strokeLinecap: "round"
    },
    playNotification: {
      bottom: `calc(${doubleControlsSize} + 24px)`,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    playNotificationAlert: {
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  };
});
