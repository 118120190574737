import React from 'react';
import { Field } from 'formik';
import { SimpleFileUpload } from 'formik-material-ui';
import { Grid, IconButton, Paper, FormLabel } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useAppStyles } from '../../appStyles';
import { useFieldStyles } from './styles';


export const FileUploadField = (props) => {
  const { fileID, filename, onClose, showUpload, formIsSubmitting, disabled, hideRemove } = props;
  const { hide } = useAppStyles();
  const { titleMinHeight, titlePadding } = useFieldStyles();
  if ((!fileID && !filename) || showUpload) {
    return (
      <Grid container direction={"column"}>
        {filename && <Grid item>{`Current file: ${filename}`}</Grid>}
        <Grid item>
          <Field type="file" name="file" label="File" component={SimpleFileUpload} />
        </Grid>
      </Grid>

    );
  }
  const cardID = `song-form-file-card`;
  return (
    <Grid container direction={"column"}>
      <Grid item>
        <FormLabel htmlFor={cardID}>
          File
        </FormLabel>
      </Grid>
      <Paper elevation={1}>
        <Grid item container 
          direction={"row"}
          wrap={"nowrap"}
          alignItems={"center"}
          justifyContent={"space-between"}
          className={clsx(titlePadding, titleMinHeight)}>
          <Grid item>
            <span id={cardID}>
              {`${filename}`}
            </span>
          </Grid>
          <Grid item>
            <IconButton className={clsx({[hide]: hideRemove})}onClick={onClose} disabled={formIsSubmitting || disabled}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

  );
};
