import { ArrowBack, Gesture, Home as HomeIcon, LibraryBooks, MusicNote, PlaylistPlay, Settings, TextFields } from '@material-ui/icons';
import { AuthDialog } from '../components/dialogs/Auth';
import { ConfirmAction } from '../components/dialogs/ConfirmAction';
import { CTASignUpInfoDialog } from '../components/dialogs/CTASignUpInfo';
import { InfoDialog } from '../components/dialogs/Info';
import { ShareDialog } from '../components/dialogs/Share';
import { ShareDetailsDialog } from '../components/dialogs/ShareDetails';
import { SongFiltersDialog } from '../components/dialogs/SongFilters';
import { SubscribeDialog } from '../components/dialogs/Subscribe';
import { UpgradeAccountDialog } from '../components/dialogs/UpgradeAccount';
import { ListFormView } from '../components/forms/list';
import { SongFormView } from '../components/forms/song';
import { HomePanel } from '../components/panels';
import { SettingsPanel } from '../components/panels/settings';
import terms from './terms.json';

export const FORM_STRING_INPUT_MAX_LENGTH = 250;

/**
 * @typedef {import('../reducers/functions').Item} Item
 * 
 * @typedef {Object} MainNavOptions
 * @property {Array<MainNavOption>} list
 * @property {Map<String, MainNavOption>} map
 * 
 * @typedef {Object} MainNavOption
 * @property {String} text
 * @property {React.Component} Icon
 * @property {React.Component} Component
 */

export const EMPTY_ITEM = {};
/**
 * @typedef {Object} KarachordsView
 * @property {String} name The view's name - treat as an ENUM of all possible views (for selection, etc)
 * @property {String} text Text to be displayed for view's menu item in main menu
 * @property {String} header text to be displayed in view's subMenu
 * @property {import('@material-ui/icons').SvgIconComponent} Icon Menu icon for view's menu item in main menu
 * @property {String} [stateItemsLocation] string path to state list where items reside
 * @property {import('react').FunctionComponent} Main main screen component
 * @property {String} [dataType] data type from constants.DATA_TYPES
 * @property {KarachordsSubMenu} [subMenu] subMenu associated with this view
 * 
 */

/** @enum {String} */
export const DATA_TYPES = {
  lists: 'lists',
  songs: 'songs',
  artists: 'artists',
}

/** @enum {String}*/
export const DIALOG_NAMES = {
  SONG_FILTERS: 'SongFilters',
  LIST_FILTERS: 'ListFilters',
  AUTH: 'Auth',
  CONFIRM_ACTION: 'ConfirmAction',
  CTA_SIGNUP_INFO: 'CTASignUpInfo',
  INFO: 'Info',
  SHARE: 'Share',
  SHARE_DETAILS: 'ShareDetails',
  UPGRADE_ACCOUNT: 'UpgradeAccount',
  SUBSCRIBE_STUB: 'SubscribeStub',
}

/**
 * @typedef {Object} KarachordsDialog
 * @property {String} name
 * @property {String} [header]
 * @property {React.FunctionComponent} Component
 */
/** @type {Array<KarachordsDialog>} */
const DIALOGS_LIST = [
  {
    name: DIALOG_NAMES.SONG_FILTERS,
    header: 'Filters',
    Component: SongFiltersDialog
  },
  {
    name: DIALOG_NAMES.AUTH,
    Component: AuthDialog
  },
  {
    name: DIALOG_NAMES.CONFIRM_ACTION,
    header: 'Are you sure?',
    Component: ConfirmAction
  },
  {
    name: DIALOG_NAMES.CTA_SIGNUP_INFO,
    Component: CTASignUpInfoDialog
  },
  {
    name: DIALOG_NAMES.INFO,
    Component: InfoDialog,
  },
  {
    name: DIALOG_NAMES.SHARE,
    Component: ShareDialog,
  },
  {
    name: DIALOG_NAMES.SHARE_DETAILS,
    Component: ShareDetailsDialog
  },
  {
    name: DIALOG_NAMES.UPGRADE_ACCOUNT,
    Component: UpgradeAccountDialog,
  },
  {
    name: DIALOG_NAMES.SUBSCRIBE_STUB,
    Component: SubscribeDialog
  }
]

export const INITIAL_DIALOG = DIALOGS_LIST[0];
/** @type {import('../appState').DialogState} */
export const INITIAL_DIALOG_STATE = {
  isOpen: false,
  dialog: INITIAL_DIALOG,
  methods: {},
  props: {},
}
const DIALOGS_MAP = new Map(DIALOGS_LIST.map(dialog => [dialog.name, dialog]))

export const DIALOGS = {
  list: DIALOGS_LIST,
  map: DIALOGS_MAP
}
/**
 * @typedef {Object} SearchOptions
 * @property {Array<String>} keys
 */
/**
 * @typedef {Object} KarachordsSubMenu
 * @property {SearchOptions} searchOptions
 * @property {String} [secondaryTextProp]
 * @property {String} name
 * @property {String} [header]
 * @property {KarachordsDialog} [filterDialog]
 */
export const SUB_MENU_NAMES = {
  SONGS: 'Songs',
  LISTS: 'Lists',
}
/** @type {Array<KarachordsSubMenu>} */
const SUB_MENUS_LIST = [
  {
    name: SUB_MENU_NAMES.SONGS,
    header: 'Library',
    searchOptions: { keys: ['name', 'artist'] },
    secondaryTextProp: 'artist',
    filterDialog: DIALOGS_MAP.get(DIALOG_NAMES.SONG_FILTERS)
  },
  {
    name: SUB_MENU_NAMES.LISTS,
    header: 'Lists',
    searchOptions: { keys: ['name'] },
    filterDialog: DIALOGS_MAP.get(DIALOG_NAMES.LIST_FILTERS)
  }
];

const SUB_MENUS_MAP = new Map(SUB_MENUS_LIST.map(subMenu => [subMenu.name, subMenu]));

export const SUB_MENUS = {
  list: SUB_MENUS_LIST,
  map: SUB_MENUS_MAP
}


export const STATE_DATA_PATHS = new Map([
  [DATA_TYPES.lists, { list: 'lists.list', map: 'lists.map', parent: 'lists', max: 'lists.max' }],
  [DATA_TYPES.songs, { list: 'songs.list', map: 'songs.map', parent: 'songs', max: 'songs.max' }],
  [DATA_TYPES.artists, { list: 'artists.list', set: 'artists.set', parent: 'artists', max: 'artists.max' }]
])

export const VIEW_NAMES = {
  HOME: "Home",
  LIBRARY: "Library",
  SETLISTS: "Setlists",
  SETTINGS: "Settings",
}
/** @type {Array<KarachordsView>} */
const VIEWS_LIST = [
  {
    name: VIEW_NAMES.HOME,
    text: "Home",
    header: "Home",
    Icon: HomeIcon,
    Main: HomePanel,
  },
  {
    name: VIEW_NAMES.LIBRARY,
    header: "Library",
    text: "Library",
    Icon: LibraryBooks,
    Main: SongFormView,
    stateItemsLocation: STATE_DATA_PATHS.get(DATA_TYPES.songs).map,
    dataType: DATA_TYPES.songs,
    subMenu: SUB_MENUS.map.get(SUB_MENU_NAMES.SONGS),
  },
  {
    name: VIEW_NAMES.SETLISTS,
    header: "Setlists",
    text: "Setlists",
    Icon: PlaylistPlay,
    Main: ListFormView,
    stateItemsLocation: STATE_DATA_PATHS.get(DATA_TYPES.lists).map,
    dataType: DATA_TYPES.lists,
    subMenu: SUB_MENUS.map.get(SUB_MENU_NAMES.LISTS),
  },
  {
    name: VIEW_NAMES.SETTINGS,
    header: "Settings",
    text: "Settings",
    Icon: Settings,
    Main: SettingsPanel,
  }
]

const VIEWS_MAP = new Map(VIEWS_LIST.map(view => [view.name, view]));

export const INITIAL_VIEW = VIEWS_LIST[0];
/** @type {import('../appState').MainState} */
export const INITIAL_MAIN_STATE = {
  Component: INITIAL_VIEW.Main,
  view: INITIAL_VIEW,
  item: null,
  filters: [],
}
/**
 * @typedef {Object} KarachordsViews
 * @property {Array<KarachordsView>} list
 * @property {Map<String, KarachordsView>} map
 */
export const VIEWS = {
  list: VIEWS_LIST,
  map: VIEWS_MAP,
}

export const GUMROAD_DONATION_LINK = "https://gum.co/ZtuxH?wanted=true";

export const CONTACT_LINK = "https://forms.gle/oGs89ibjnWMVxq2S8";

export const CONTACT_LINK_BUSINESS_INQUIRY = "https://docs.google.com/forms/d/e/1FAIpQLSdiBtc5hXLXuAMvzgfZrX0_a73fm_hU9T_sjYNOsGM-ANEqWg/viewform?usp=pp_url&entry.351274925=Business+/+advertising+inquiry";

export const CONTACT_LINK_BUG_REPORT = "https://docs.google.com/forms/d/e/1FAIpQLSdiBtc5hXLXuAMvzgfZrX0_a73fm_hU9T_sjYNOsGM-ANEqWg/viewform?usp=pp_url&entry.351274925=App+feedback+/+bug+report";

export const CONTACT_LINK_MAILING_LIST = "https://forms.gle/p1L3SWUcGHidnxyn8";

export const adIDs = {
  listFormDonate: 'list-form-ad-donate',
  songFormDonate: 'song-form-ad-donate',
  homeAdDonate: 'home-ad-donate',
  homeButtonDonate: 'home-button-donate',
  settingsDonate: 'settings-ad-donate'
}

export const editModes = {
  freehand: 'freehand',
  text: 'text',
  symbol: 'symbol',
  layers: 'layers',
  selecting: 'selecting'
}

export const tagetEditModeIcons = {
  [editModes.freehand]: Gesture,
  [editModes.text]: TextFields,
  [editModes.symbol]: MusicNote,
  [editModes.selecting]: ArrowBack,
}
export const filtersPaths = {
  SUB_DRAWER: 'nav.subMenu.filters',
  MAIN: 'main.filters'
}

export const authComponentName = `${DIALOG_NAMES.AUTH}Dialog`
const dialogImpressionEventSuffixAuth = 'dialog_impression_auth';
const dialogImpressionEventSuffixSettings = "dialog_impression_settings";

export const tosComponentName = 'ToSInfoDialog';
export const tosImpressionEventAuth = `${tosComponentName}_${dialogImpressionEventSuffixAuth}`;
export const tosImpressionEventSettings = `${tosComponentName}_${dialogImpressionEventSuffixSettings}`

export const privacyComponentName = 'PrivacyInfoDialog';
export const privacyImpressionEventAuth = `${privacyComponentName}_${dialogImpressionEventSuffixAuth}`;
export const privacyImpressionEventSettings = `${privacyComponentName}_${dialogImpressionEventSuffixSettings}`;

export const faqComponentName = "FAQInfoDialog";
export const faqImpressionEventAuth = `${faqComponentName}_${dialogImpressionEventSuffixAuth}`

export const featuresComponentName = "FeaturesInfoDialog";
export const featuresImpressionEventAuth = `${featuresComponentName}_${dialogImpressionEventSuffixAuth}`

export const signInConversionEvent = "initialSignUp";

/** @type {import('../components/dialogs/Info').InfoDialogProps} */
export const tosDialogProps = {
  headerText: "Terms of Service",
  content: terms.tos,
};
/** @type {import('../components/dialogs/Info').InfoDialogProps} */
export const privacyDialogProps = {
  headerText: "Privacy Policy",
  content: terms.privacy,
};

/** @type {import('../components/dialogs/Info').InfoDialogProps} */
export const creditsDialogProps = {
  headerText: "Credits",
  content: terms.credits,
};

const year = (new Date()).getFullYear();
export const copyrightText = `Copyright © ${year} Codetta Labs. All rights reserved.`;

/** 
 * @typedef {String} ShareType 
 * 
 * @enum {ShareType}
 */
export const SHARE_TYPES = {
  song: 'song',
  list: 'list',
}

export const SHARE_TYPE_TO_DATA_TYPE = {
  [SHARE_TYPES.song]: DATA_TYPES.songs,
  [SHARE_TYPES.list]: DATA_TYPES.lists
}

export const ROLES = {
  viewer: 'viewer',
  editor: 'editor',
  admin: 'admin',
  owner: 'owner',
}
const ROLE_DESCRIPTIONS = {
  [ROLES.viewer]: "Viewers can view this item or add it to their own setlists, but can't edit it or its annotations",
  [ROLES.editor]: "Editors can do everything a Viewer can, plus they can modify its annotations",
  [ROLES.owner]: "Owners have full control over this item, and can edit anything about it, including deleting it for everyone (if they wish)",
  [ROLES.admin]: "Admins have nearly full control over the item and can edit anything about it. They cannot delete the item"
}
export const ROLE_COPY = {
  [ROLES.viewer]: {
    display: "Viewer",
    description: ROLE_DESCRIPTIONS.viewer,
  },
  [ROLES.editor]: {
    display: "Editor",
    description: ROLE_DESCRIPTIONS.editor
  },
  [ROLES.admin]: {
    display: "Admin",
    description: ROLE_DESCRIPTIONS.admin
  },
  [ROLES.owner]: {
    display: "Owner",
    description: ROLE_DESCRIPTIONS.owner
  }
}

export const ITEMS_ON_LIST_REVOKE_COPY = `Items shared with a list cannot be revoked, they must be removed from the list or the whole list must be revoked`;


export const DELETE_ROLES = new Set([ROLES.owner])
export const EDIT_ROLES = new Set(Array.from(DELETE_ROLES).concat([ROLES.admin]));
export const MODIFY_NOTES_ROLES = new Set(Array.from(EDIT_ROLES).concat(([ROLES.editor])));

export const DELETE_SHARE_TYPES = {
  cascade: "cascade",
  remove: "remove",
  revoke: "revoke",
}

export const REVOKE_SHARE_COPY = {
  revokeAll: {
    confirm: `Everyone except for you will lose access to`
  }
}

export const ADD_TO_SHARED_LIST_COPY = "Adding your own items to a setlist shared with you will give the owner of the list Admin access to these items. Everyone else will get these items with the same role they have on the list.";