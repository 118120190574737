import { makeStyles } from '@material-ui/core/styles';
import { getBreakpoints } from '../../utils/theme';


export const drawerWidth = 200;
export const getDrawerClosedSpacing = (theme) => {
  return theme.spacing(9) + 1;
};
export const useDrawerStyles = makeStyles((theme) => {
  const spacing = getDrawerClosedSpacing(theme);
  // const mobilePortrait = theme.breakpoints.down('xs');
  // const tablet = theme.breakpoints.down('md');
  const breakpoints = getBreakpoints(theme);
  const { laptop, mobilePortrait } = breakpoints;
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      maxWidth: '25%',
      [mobilePortrait]: {
        display: 'none',
      }
    },
    drawerOpen: {
      maxWidth: '12.25vw',
      flexBasis: '12.25%',
      border: '0px',
      [laptop]: {
        maxWidth: '25%',
        flexBasis: '25%'
      },
    },
    drawerClose: {
      overflowX: 'hidden',
      width: spacing,
      border: '0px',
    },

    menuButton: {
      paddingLeft: `${spacing / 3}px`,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    subMenu: {
      overflow: 'hidden auto',
      width: '100%'
    },
    hide: {
      display: 'none !important',
    },
    subMenuMobileItemSelected: {
    },
    menuItem: {
      minHeight: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
    },
    menuClosed: {
      [mobilePortrait]: {
        display: 'none'
      }
    },
    menuToggleOpenButton: {
      backgroundColor: theme.palette.primary.main,
    },
  };
});
