import { Badge, Button, Grid } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useContext } from 'react';
import * as actions from '../../actions';
import { Context } from '../../Context';

/**
 * 
 * @param {React.Dispatch<import('../../actions').Action>} dispatch 
 * @param {String} name 
 * @param {import('../../appState').DialogProps} props 
 * @param {import('../../appState').DialogMethods} methods
 * @return {() => void} 
 */
const handleOpenDialog = (dispatch, name, props, methods) => () => {
  /** @type {import('../../reducers/functions').OpenDialogPayload} */
  const payload = { dialog: name, props, methods }
  dispatch({ type: actions.OPEN_DIALOG, payload });
}
/**
 * @typedef {Object} HeadedItemsListFilterButtonProps
 * @property {Array<Object>} filters
 * @property {React.Dispatch<*>} setFilters
 * @property {import('../../constants').KarachordsDialog} filterDialog
 * @property {String} filtersPath
 * @param {HeadedItemsListFilterButtonProps} props 
 */
export const HeadedItemsListFilterButton = (props) => {
  const { filters, setFilters, filterDialog, filtersPath } = props;
  const filtersCount = filters ? filters.length : null;
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  if (!filterDialog) {
    return null;
  }
  const { dispatch } = context;
  return (
    <Grid item>
      <Badge badgeContent={filtersCount} color="secondary">
        <Button
          onClick={handleOpenDialog(dispatch, filterDialog.name, { filtersPath }, { setFilters })}
          startIcon={<FilterList />}
        >
          {"Filters"}
        </Button>
      </Badge>
    </Grid>
  );
};
