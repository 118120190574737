import { IconButton } from '@material-ui/core';
import React, { useContext } from 'react';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { getEditModeIcon, selectEditModeButtonOnClick } from './utils';

/**
 * @typedef {Object} SelectEditModeProps
 * @property {String} editMode
 * @param {SelectEditModeProps} props 
 */
export const SelectEditModeButton = (props) => {
  const { editMode: targetEditMode } = props;
  const { editButton } = usePlayStyles();
  const { state, methods } = useContext(PlayContext);
  const { editMode: currentEditMode } = state;
  const { setEditMode } = methods;

  const Icon = getEditModeIcon(targetEditMode, currentEditMode);
  // TODO: when changing edit modes, delete empty notes
  return (
    <IconButton className={editButton} onClick={selectEditModeButtonOnClick(currentEditMode, targetEditMode, setEditMode)}>
      <Icon fontSize={"large"} />
    </IconButton>
  );
};
