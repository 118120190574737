import { makeStyles } from "@material-ui/core";
import { getBreakpoints } from "../../utils/theme";

export const useNavStyles = makeStyles((theme) => {
  const { mobilePortrait } = getBreakpoints(theme);
  return {
    mobileNav: {
      display: 'none',
      [mobilePortrait]: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        width: '100%'
      }
    },
    selectedNavAction: {
      backgroundColor: `rgba(255, 255, 255, 0.16)`,
    }
  }
})