import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
  props: {
    MuiIconButton: {
      centerRipple: false,
    },
    MuiTable: {
      size: 'small',
      padding: 'checkbox',
    },
  },
});

export default theme;