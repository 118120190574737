import { createContext } from 'react';

const noOp = () => null;
/** @type {PlayMethods} */
const initialPlayMethods = {
  setIndex: noOp,
  setCurrentFile: noOp,
  setPages: noOp,
  setPage: noOp,
  setEditMode: noOp,
  setActiveNote: noOp,
  setNotes: noOp,
  setPageScale: noOp,
  setFreehandEditRef: noOp,
};
/** @type {PlayViewState} */
const initialPlayState = {
  currentFile: null,
  currentSong: null,
  page: null,
  pages: null,
  editMode: null,
  EditPanelComponent: noOp,
  activeNote: null,
  notes: [],
  freehandEditRef: null,
  pageScale: null,
};
/**
 * @typedef {Object} PlayContextType
 * @property {PlayViewState} state
 * @property {PlayMethods} methods
 * 
 * @typedef {import('./playConstants').DIALOG_MESSAGES} DialogMessage
 * 
 * @typedef {import('./utils').Font} Font
 * @typedef {import('./utils').Note} Note
 * 
 * @typedef {Note & {isResizing?: boolean}} NoteWithResizing
 *
 * @typedef {Object} PlayViewState
 * @property {import('./../forms/song/SongForm').Song} currentSong
 * @property {import('./index').SongFile} currentFile
 * @property {Number} page
 * @property {Number} pages
 * @property {String} editMode
 * @property {React.FunctionComponent} EditPanelComponent
 * @property {NoteWithResizing} activeNote
 * @property {Array<import('./utils').Note>} notes
 * @property {Number} pageScale
 * @property {React.RefObject} freehandEditRef
 * @property {String} strokeColor
 * @property {Number} strokeWidth
 * @property {String} pageHeight
 * @property {boolean} isErasing
 * @property {Font} font
 * @property {import('./utils').PageLayers} layers
 * @property {Number} currentLayerIndex
 * @property {boolean} isLoading
 * @property {DialogMessage} dialogMessage
 * @property {boolean} wasEdited
 * @property {import('../../reducers/functions').Notification} notification
 *
 * @typedef {Object} PlayMethods
 * @property {(index:Number) => void} setIndex
 * @property {(file:import('./index').SongFile) => void } setCurrentFile
 * @property {(pages:Number) => void} setPages
 * @property {(pages:Number) => void } setPage
 * @property {(editMode:String) => void} setEditMode
 * @property {(note:NoteWithResizing) => void} setActiveNote
 * @property {(notes:Array<import('./utils').Note>) => void} setNotes
 * @property {(pageScale: Number) => void} setPageScale
 * @property {(ref: React.RefObject<import('react-sketch-canvas').ReactSketchCanvas>) => void} setFreehandEditRef
 * @property {(color:String) => void} setStrokeColor
 * @property {(width:Number) => void} setStrokeWidth
 * @property {(height:String) => void} setPageHeight
 * @property {(isErasing: boolean) => void} setIsErasing
 * @property {(font:Font) => void} setFont
 * @property {(layerIndex:Number) => void} setCurrentLayerIndex
 * @property {(layers:import('./utils').PageLayers) => void} setLayers
 * @property {(isLoading:boolean) => void} setIsLoading
 * @property {(dialogMessage:DialogMessage) => void} setDialogMessage
 * @property {(wasEdited:boolean) => void} setWasEdited
 * @property {React.Dispatch<import('../../reducers/functions').Notification>} setNotification
 * @property {() => void} nextPage
 * @property {() => void} prevPage
 */
/** @type {PlayContextType} */
const initialContext = { state: initialPlayState, methods: initialPlayMethods };
export const PlayContext = createContext(initialContext);
