import { Grid } from '@material-ui/core';
import React from 'react';
import { DraggableListSong } from './DraggableListSong';

export const DraggableListSongContainer = (props) => {
  const { provided, songs, remove, add, style, canEdit } = props;
  const { innerRef, droppableProps, placeholder } = provided;
  const rowProps = {
    container: true,
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  };
  const cellProps = {
    text: {
      xs: 5,
    },
    icon: {
      xs: 1
    }
  };
  return (
    <Grid container item direction={"column"} ref={innerRef} {...droppableProps} style={style}>
      {songs.reduce((reduced, song, index) => {
        if (!song) {
          return reduced;
        }
        const { id } = song;
        const key = `song-${id}-${index}`;
        const draggableListSongProps = { cellProps, rowProps, song, key, index, provided, add, remove, draggableId: key, canEdit };
        reduced.push(<DraggableListSong key={key} {...draggableListSongProps} />);
        return reduced;
      }, [])}
      {placeholder}
    </Grid>
  )
}