import get from 'lodash.get';
import set from 'lodash.set';
import { DATA_TYPES, filtersPaths, STATE_DATA_PATHS, VIEWS } from '../constants';

/**
 * 
 * @typedef {import('../appState').PlayState} PlayState
 * @typedef {import('./../components/forms/song/SongForm').Song} Song
 * @typedef {import('../components/forms/list/').List} List
 * @typedef {import('../App').KarachordsState} KarachordsState
 * @typedef {import('./functions').SelectViewPayload} SelectViewPayload
 */
/**
 * 
 * @param {String} id target id of song to update in the songs list 
 * @param {Song} updatedSong song to replace target with
 * @return {(iteratedSong: Song) => Song}
 */
export function updateSongInList(id, updatedSong) {
  return (iteratedSong) => {
    const { id: currentId } = iteratedSong;
    if (currentId === id) {
      return updatedSong;
    }
    return iteratedSong;
  };
}

/**
 * 
 * @param {KarachordsState} state 
 * @param {SelectViewPayload} payload 
 */
export function shouldIgnoreSelectView(state, payload) {
  const { name, item } = payload
  const currentView = state.main.view;
  const currentItem = state.main.item;
  return currentView.name === name && currentItem === item;
}

/**
 * 
 * @param {KarachordsState} state 
 * @param {Song} song
 * @return {Map<String, List>}
 */
export function updateListsMapWithNewSong(state, song) {
  const { id } = song;
  /** @type {Map<Song, List>} */
  const listsMap = get(state, 'lists.map', new Map());
  const updatedListsMap = new Map();
  listsMap.forEach(list => {
    const { songs, id: listId } = list;
    const updatedSongs = songs.map(updateSongInList(id, song));
    const updatedList = Object.assign({}, list, { songs: updatedSongs });
    updatedListsMap.set(listId, updatedList);
  });
  return updatedListsMap;
}

/**
 * @param {Song} song 
 * @param {KarachordsState} state 
 */
export const updateSongInState = (song, state) => {
  const map = state.songs.map;
  const { id } = song;
  map.set(id, song);
  const list = Array.from(map.values());
  state.songs.list = list
  state = updateArtists([song], state);
  /** @type {Map<String, List>} */
  const updatedListsMap = updateListsMapWithNewSong(state, song);
  state.lists.map = updatedListsMap;
  state.lists.list = Array.from(updatedListsMap.values());
  const mainItem = state.main.item;
  if (mainItem && map.has(mainItem.id)) {
    // we have main item and it was in the data we just updated
    // therfore, copy over changes to main item so UI stays updated
    state.main.item = map.get(mainItem.id);
  }
  const subMenu = state.nav.subMenu;
  if (subMenu.map.has(id)) {
    // if we have a subMenu, we're editing songs or lists
    // update the subMenu copies with the new data
    state.nav.subMenu.map = map;
    state.nav.subMenu.items = list;
  }
  const { play } = state;
  const { songs } = play;
  if (songs && songs.length && songs.length > 0) {
    const updatedPlaySongs = songs.map(updateSongInList(id, song))
    state.play.songs = updatedPlaySongs
  }
  return state;
}

/**
 * 
 * @param {Array<import('./functions').Item>} data
 * @param {KarachordsState} updated
 * @return {KarachordsState} state updated with new artists 
 */
export function updateArtists(data, updated) {
  const hashSet = updated.artists.set;
  // TODO FILTERING: Find a way to invalidate any artists that are no longer relevant - might need to be with a timer and a background refresh?
  data.forEach((song) => {
    const { artist } = song;
    if (!hashSet.has(artist)) {
      hashSet.add(artist);
    }
  });
  const setPath = STATE_DATA_PATHS.get(DATA_TYPES.artists).set;
  set(updated, setPath, hashSet);
  const listPath = STATE_DATA_PATHS.get(DATA_TYPES.artists).list;
  const list = Array.from(hashSet.values());
  set(updated, listPath, list);
  return updated;
}


/**
 * 
 * @param {KarachordsState} state 
 * @param {import('../actions').Action} payload 
 */
export function pushPlayToHistory(state, payload) {
  const { history } = state;
  history.push(`/play`, payload);
}

/**
 * 
 * @param {KarachordsState} state 
 * @param {PlayState} updatedPlay 
 * @return {Array<Song>}
 */
export function getMergedPlaySongs(state, updatedPlay) {
  const { play } = state;
  const { songs } = play;
  const { songs: updatedSongs } = updatedPlay;
  const updatedSongsMap = new Map(updatedSongs.map(song => [song.id, song]));
  const nextPlaySongs = songs.map(song => {
    const { id } = song;
    const mappedSong = updatedSongsMap.get(id);
    if (mappedSong) {
      return mappedSong;
    }
    return song;
  });
  return nextPlaySongs;
}


/**
 * 
 * @param {KarachordsState} state
 * @return {KarachordsState}
 */
export const clearAllFilters = (state) => {
  Object.values(filtersPaths).forEach((path) => {
    const filters = get(state, path, null);
    if (filters && filters.length > 0) {
      set(state, path, []);
    }
  })
  return state;
}

/**
 * 
 * @param {String} view 
 * @param {KarachordsState} state 
 * @return {Map} Map of items
 */
export const getItemsMap = (view, state) => {
  const stateItemsLocation = VIEWS.map.get(view).stateItemsLocation;
  if (stateItemsLocation) {
    const items = get(state, stateItemsLocation, new Map());
    return items;
  }
  return null;
}