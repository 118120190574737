import get from 'lodash.get';

/**
 * 
 * @param {Object} object 
 * @param {Array<(object:Object) => boolean>} tests 
 */
export const didPassTests = (object, tests) => {
  const finalTests = [...tests]
  for (let i = 0; i < finalTests.length; i++) {
    const test = finalTests[i];
    const result = test(object);
    if (!result) {
      return false
    }
  }
  return true;
}

/**
 *
 * @param {import('../../components/drawers/utils').Song} song
 * @returns {boolean}
 */
export const isSongAndHasFiles = (song) => {
  const bufferType = get(song, 'files[0].buffer.type', false);
  const bufferData = get(song, 'files[0].buffer.data', false);
  const hasFiles = (Boolean(bufferType) && Boolean(bufferData));
  return hasFiles;
};

/**
 * 
 * @param {import('../../components/forms/song/SongForm').KarachordsContext} context 
 * @param {String} id 
 * @param {String} path string path in context to the item map
 * @return {Object}
 */
export function getItemFromContextMap(context, id, path) {
  const map = get(context, path, new Map());
  const stateSong = map.get(id);
  return stateSong;
}
/**
 * 
 * @param {import('../../components/forms/list/ListSongPlaceholder').List} list
 * @returns {boolean}
 */
export const songsHaveFiles = (list) => {
  const { songs } = list;
  return songs.reduce((acc, song) => {
    if (!acc) {
      return acc;
    }
    return isSongAndHasFiles(song);
  }, true)
}