import React from 'react';
import { Grid, Typography } from '@material-ui/core';


export const ListSongPlaceholder = () => {
  return (
    <Grid item>
      <Typography variant={"h6"}>
        {"Please add some items from your library to this setlist"}
      </Typography>
    </Grid>
  );
};
