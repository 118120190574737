import React, { useRef } from 'react';
import { Search } from '@material-ui/icons';
import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core';
/**
 *
 * @typedef {React.Props & Object} SubDrawerSearchProps
 * @property {(String:search) => void} setSearch
 */
/**
 *
 * @param {SubDrawerSearchProps} props
 */
export const HeadedItemsListSearch = (props) => {
  const { setSearch } = props;
  const ref = useRef();
  return (
    <React.Fragment>
      <Grid item>
        <TextField size={"small"} placeholder={"Search"} onChange={(e) => { setSearch(e.target.value); }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => {
                  if (ref && ref.current) {
                    // @ts-ignore
                    ref.current.focus();
                  }
                }}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
            inputProps: {
              ref
            },
          }} />
      </Grid>
    </React.Fragment>
  );
};
