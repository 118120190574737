import { OPEN_DIALOG, SET_DATA } from '../../actions';
import { DIALOG_NAMES, SHARE_TYPE_TO_DATA_TYPE, SHARE_TYPES } from '../../constants';
import { updateSongsOnList } from '../../utils';
import { dispatchFetchErrorNotification } from '../../utils/notfications';
import { createShare } from '../../utils/persistence';

/**
 * 
 * @param {import('../../constants').Item} item 
 * @param {import('../../constants').ShareType} shareType 
 * @param {import('../../reducers/functions').AssignableRole} [role]
 * @returns {SharedDetails}
 */
const getExistingShare = (item, shareType, role) => {
  const { shares = [] } = item;
  if (shares.length === 0) {
    return null
  }
  const { name: targetRoleName } = role
  return shares.find((sharedDetails) => {
    const { type, id, role } = sharedDetails;
    return id && type === shareType && (targetRoleName ? role === targetRoleName : true);
  })
}
/**
 * 
 * @param {import('../../utils/persistence').GetShareData} getShareData
 * @return {SharedDetails}
 */
const getSharedDetailsFromGetShareData = (getShareData) => {
  const { createdAt, type, role: roleName, source, id: shareId } = getShareData;
  const sharedDetails = { id: shareId, createdAt, type, role: roleName }
  if (source) {
    Object.assign(sharedDetails, { source })
  }
  return sharedDetails;
}
/**
 * 
 * @param {import('../../constants').Item} item 
 * @param {import('../../constants').ShareType} shareType 
 * @param {import('../../Context').KarachordsContext} context
 * @param {import('../../reducers/functions').AssignableRole} [role] if passed, will return the share that matches this roleId, not the first role in the list
 * @return {Promise<import('../../appState').DialogShare>}
 */
// @ts-ignore
export const getShareForItem = async (item, shareType, context, role = {}) => {
  const { firebase, dispatch } = context
  const existingShare = getExistingShare(item, shareType, role);
  let share = null;
  if (existingShare) {
    share = existingShare;
    const { roles } = item;
    share = Object.assign({}, share, { roles })
  } else {
    const userToken = await firebase.auth().currentUser.getIdToken();
    const { id } = item;
    const resp = await createShare(userToken, shareType, id, role.id);
    if (resp.status !== 200) {
      dispatchFetchErrorNotification(resp, dispatch);
      return null;
    }
    /** @type {import('../../utils/persistence').GetShareResponse} */
    const { data } = resp;
    const updatedItem = getUpdatedItemFromGetShareData(data, item);
    share = data;
    if (shareType === SHARE_TYPES.list) {
      updateSongsOnList(updatedItem, context, handleUpdateSongWithSharedDetails(data));
    }
    const payload = { data: [updatedItem], dataType: SHARE_TYPE_TO_DATA_TYPE[shareType], shouldOverwrite: false, isSilent: true };
    dispatch({ type: SET_DATA, payload });
  }
  return share;
}
/**
 * @param {import('../../utils/persistence').KarachordsContext} context
 * @param {import('../../constants').Item} item
 * @param {import('../../constants').ShareType} shareType
 * @param {(isLoading: boolean) => void} [setIsLoading]
 * @param {import('../../appState').DialogShare | null} [initialShare]
 * @return {(e:React.SyntheticEvent) => void}
 */
export const handleOpenShareDialog = (context, item, shareType, setIsLoading = () => {}, initialShare) => async () => {
  const { dispatch } = context;
  setIsLoading(true);
  let share = initialShare;
  if (!share) {
    share = await getShareForItem(item, shareType, context);
  }
  if (!share) {
    console.error('handleOpenShareDialog() - failed to get share for item', item);
    return;
  }
  const updatedItem = getUpdatedItemFromGetShareData(share, item);
  /** @type {import('../../reducers/functions').OpenDialogPayload} */
  const payload = { dialog: DIALOG_NAMES.SHARE, props: { item: updatedItem, itemType: shareType, share } };
  dispatch({ type: OPEN_DIALOG, payload });
}



function handleUpdateSongWithSharedDetails(getShareData) {
  return (song) => {
    const { createdAt, type, role, id, source } = getShareData;
    /** @type {SharedDetails} */
    const sharedDetails = { createdAt, type, role, id, source };
    const { shares = [] } = song;
    const updatedShares = shares.slice();
    updatedShares.push(sharedDetails);
    const out = Object.assign({}, song, { shares: updatedShares });
    return out;
  };
}

/**
 * 
 * @param {import('../../utils/persistence').GetShareData} data 
 * @param {import('../../reducers/functions').Item} item
 * @return {import('../../reducers/functions').Item}
 */
export function getUpdatedItemFromGetShareData(data, item, ) {
  const sharedDetails = getSharedDetailsFromGetShareData(data);
  /** @type {import('../../utils/persistence').GetShareData} */
  const { roles } = data;
  const updatedItem = getUpdatedItemWithShares(sharedDetails, item, roles);
  return updatedItem;
}

/**
 * 
 * @param {SharedDetails} sharedDetails 
 * @param {import('../../reducers/functions').Item} item 
 * @param {Array<import('../../reducers/functions').AssignableRole>} roles
 * @returns {import('../../reducers/functions').Item}
 */
export function getUpdatedItemWithShares(sharedDetails, item, roles) {
  const { shares = [] } = item;
  let wasUpdated = false;
  const updatedShares = shares.map((share) => {
    const { id } = share;
    if (id === sharedDetails.id){
      wasUpdated = true;
      return sharedDetails;
    }
    return share;
  });
  if (!wasUpdated) {
    updatedShares.push(sharedDetails);
  }
  /** @type {import('../../reducers/functions').Item} */
  const updatedItem = Object.assign({}, item, { shares: updatedShares, roles });
  return updatedItem;
}