import React, { useContext } from 'react';
import { Container } from '@material-ui/core';
import { usePlayStyles } from "./PlayStyles";
import { PlayContext } from './PlayContext';

export const EditPanel = () => {
  const context = useContext(PlayContext);
  const { editMode, EditPanelComponent } = context.state;
  const { editPanelContainer } = usePlayStyles();
    if (!editMode) {
    return null;
  }
  return (
    <Container maxWidth={false} className={editPanelContainer}>
      <EditPanelComponent />
    </Container>
  );
};
