/** @enum {String} */
const ERROR_MESSAGES = {
  PARAMETERS: 'Not all required parameters were provided',
  TOO_MANY_FILES: 'Too many files attempted to be saved for the song',
  LIST_TOO_LONG: 'Too many songs attempted to be saved in the list',

  UNAUTHORIZED: 'You are not authorized to perform this action',
  PERMISSIONS: 'You do not have the required permissions to perform this action. Ask the person who shared this with you to send you a new link with upgraded permissions.',

  SCRAPER_ERROR: 'Scraper failed to find meaningful data',
  INTERNAL_ERROR: 'We\'re sorry, something appears to have gone wrong on our end. Please try again later.',

  REAUTH_REQUIRED: 'Your session has expired, please log out and log back in',

  MAX_SONGS_EXCEEDED: "You've added too many entries to your library. Please upgrade your account or delete some before trying to add more.",
  MAX_LISTS_EXCEEDED: "You've created too many setlists. Please upgrade your account or delete some before trying to add more.",

  INVALID_SHARE_ID: "We couldn't find the shared item you tried to access. Please make sure you used the full link, or contact the person who shared this with you.",
}

const errors = new Map(Object.entries(ERROR_MESSAGES).map(([key, value]) => {return [key, value]}))
/**
 * 
 * @param {ERROR_MESSAGES} errEnum 
 */
export const getPersistanceErrorMessage = (errEnum) => {
  const message = errors.get(errEnum)
  if (!message) {
    return ERROR_MESSAGES.INTERNAL_ERROR
  }
  return message
}