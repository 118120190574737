import { SELECT_VIEW } from '../../actions';

/**
 *
 * @param {React.Dispatch<import('../../actions').Action>} dispatch
 * @return {(e:React.ChangeEvent, name:String) => void}
 */
export function handleMobileNavChange(dispatch) {
  return (e, name) => {
    /** @type {import('../../reducers/functions').SelectViewPayload} */
    const payload = { name };
    dispatch({ type: SELECT_VIEW, payload });
  };
}