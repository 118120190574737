import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { CLOSE_DIALOG, RESET_STATE, SET_LOADING } from '../../actions';
import { adIDs, CONTACT_LINK, CONTACT_LINK_BUG_REPORT, copyrightText, creditsDialogProps, privacyComponentName, privacyDialogProps, privacyImpressionEventSettings, tosComponentName, tosDialogProps, tosImpressionEventSettings } from '../../constants';
import { Context } from '../../Context';
import { handleDisplayLegalDialog, handleLogImpression } from '../../utils';
import { DonateAd } from '../ads';

/**
 * 
 * @param {import('../../Context').KarachordsContext} context
 * @return {(e:React.SyntheticEvent) => void}
 */
const handleSignOut = (context) => async (e) => {
  e.preventDefault();
  const { dispatch, unsubscribeFromAuthState, firebase } = context;
  dispatch({ type: SET_LOADING, payload: { value: true } });
  await firebase.auth().signOut();
  if (unsubscribeFromAuthState) {
    unsubscribeFromAuthState();
  }
  dispatch({ type: RESET_STATE });
  dispatch({ type: SET_LOADING, payload: { value: false } });
}
/**
 * 
 * @param {React.Dispatch<import('../../actions').Action>} dispatch 
 */
const handleCloseLegalDialog = (dispatch) => async () => {
  dispatch({ type: CLOSE_DIALOG });
}
export const SettingsPanel = () => {
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { dispatch, analytics } = context;
  const legalDialogMethods = {
    onClose: handleCloseLegalDialog(dispatch)
  }
  const displayTerms = handleDisplayLegalDialog(
    tosDialogProps,
    legalDialogMethods,
    dispatch,
    handleLogImpression(tosImpressionEventSettings, tosComponentName, analytics)
  )
  const displayPrivacyPolicy = handleDisplayLegalDialog(
    privacyDialogProps,
    legalDialogMethods,
    dispatch,
    handleLogImpression(privacyImpressionEventSettings, privacyComponentName, analytics)
  )
  // const displayCredits = handleDisplayLegalDialog(
  //   creditsDialogProps,
  //   legalDialogMethods,
  //   dispatch,
  //   handleLogImpression(privacyImpressionEventSettings, privacyComponentName, analytics)
  // )
  return (
    <Grid item container spacing={2} alignItems={"center"} direction={"column"} style={{ paddingTop: '8px' }}>
      <DonateAd id={adIDs.settingsDonate} />
      <Grid item>
        <Typography variant='h6'>
          {"Settings"}
        </Typography>
      </Grid>
      <Grid item>
        <Button color={"secondary"} href={CONTACT_LINK_BUG_REPORT} target={"_blank"} variant={"contained"}>
          {"Report a bug"}
        </Button>
      </Grid>
      <Grid item>
        <Button color={"primary"} href={CONTACT_LINK} target={"_blank"} variant={"contained"}>
          {"Contact the Developer"}
        </Button>
      </Grid>
      <Grid item>
        <Button variant={"contained"} onClick={handleSignOut(context)}>
          {"Sign out"}
        </Button>
      </Grid>
      <Grid item>
        <Button color={"default"} onClick={displayTerms}>
          {"Terms of Service"}
        </Button>
      </Grid>
      <Grid item>
        <Button color={"default"} onClick={displayPrivacyPolicy}>
          {"Privacy Policy"}
        </Button>
      </Grid>
      {/* <Grid item>
        <Button color={"default"} onClick={displayCredits}>
          {"Credits"}
        </Button>
      </Grid> */}
      <Grid container item direction={"column"} justifyContent={"flex-end"} alignItems={"center"}>
        <Typography variant={"subtitle2"}>
          {copyrightText}
        </Typography>
      </Grid>
    </Grid>
  )
}