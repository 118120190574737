import { stringValidators, fileValidators } from './validators';
/**
 * @param {import('./validate').Validators} validators
 * @param {String | File} value
 * @return {String} message
 */
const getMessage = (validators, value) => {
  for (let i = 0; i < validators.length; i++) {
    const validator = validators[i];
    const { test, message } = validator;
    if (!test(value)) {
      return message;
    }
  }
};
/**
 * 
 * @param {import('./validate').SongValues} values 
 * @param {import('./validate').SongValidators} valueValidators 
 * @return {Object<string, string>}
 */
export const getSongFormValidationErrors = (values, valueValidators) => {
  const errors = {};
  
  Object.entries(values).forEach(([key, value]) => {
    const validators = valueValidators[key];
    if (validators) {
      const message = getMessage(validators, value);
      if (message) {
        errors[key] = message;
      }
    }
  });
  
  // @ts-ignore
  return errors;
};
/**
 * @typedef {Object} SongValues
 * @property {String} name
 * @property {String} artist
 * @property {String} fileID
 * @property {File} file
 * @property {String} filename
 * 
 * @typedef {function} Test
 *
 * @typedef {Object} Validator
 * @property {Test} test
 * @property {String} message
 *
 * @typedef {Array<Validator>} Validators
 * 
 * @typedef {Object} SongValidators
 * @property {Validators} name
 * @property {Validators} artist
 * @property {Validators} [tonality]
 * @property {Validators} [file]

 * @param { SongValues } values
 * @return { Object<String, String> }
*/
export const validateSongForm = (values) => {
  /**
   * @type {SongValidators}
   */
  const validators = {
    name: stringValidators,
    artist: stringValidators,
    file: fileValidators
  };
  const errors = getSongFormValidationErrors(values, validators);
  return errors;
};
