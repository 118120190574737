import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import externalLinks from 'remark-external-links';
import unwrapImages from 'remark-unwrap-images';
import { Context } from '../../Context';
/**
 * @typedef {Object} InfoDialogProps
 * @property {String} content
 * @property {String} headerText
 * @typedef {import('../../appState').DialogMethods & {onClose: () => void}} InfoDialogMethods
 */

export const InfoDialog = () => {
  /** @type {import('../../utils/persistence').KarachordsContext} */
  const { state } = useContext(Context);
  const { onClose } = state.dialog.methods;
  /** @type {InfoDialogProps} */
  // @ts-ignore
  const { content, headerText } = state.dialog.props;
  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h5"}>
            {headerText}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <ReactMarkdown plugins={[[externalLinks, {target: '_blank'}], [unwrapImages]]}>
          {content}
        </ReactMarkdown>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {"Close"}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
