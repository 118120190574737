import { DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { handleCloseDialog } from './utils';

/**
 * @typedef {Object} DialogHeaderProps
 * @property {String} title
 * @property {React.Dispatch<import('../../actions').Action>} dispatch
 * @property {Boolean} isLoading
 * 
 * @param {DialogHeaderProps} props 
 */
export const DialogHeader = (props) => {
  const { title, dispatch, isLoading } = props;
  return (
    <DialogTitle disableTypography={true}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="h6">
          {title}
        </Typography>
        <IconButton onClick={handleCloseDialog(dispatch)} disabled={isLoading}>
          <Close />
        </IconButton>
      </Grid>
    </DialogTitle>
  );
}
