export const exists = (value) => {
  if (!value) {
    return false;
  }
  return true;
};
export const hasLength = (value) => {
  if (!value.length) {
    return false;
  }
  return true;
};
export const lengthLessThanOrEqualTo = (max) => (value) => {
  const length = value.length;
  if (length > max) {
    return false;
  }
  return true;
};

/**
 * 
 * @param {File} file
 * @returns {Boolean} 
 */
export const isSupportedFile = (file) => {
  const { type } = file;
  const supportedFiles = new Set(['application/pdf']);
  if (!supportedFiles.has(type)) {
    return false;
  }
  return true;
}
/**
 * @param {Number} mb
 * @returns {(file:File) => Boolean}
 */
export const isLessThanMegabytes = (mb) => (file) => {
  const bytes = mb * 1000 * 1000;
  if (file.size > bytes) {
    return false;
  }
  return true;
}
/**
 * 
 * @param {File} file
 */
export const hasSize = (file) => {
  if (!file.size) {
    if (file.size === null || file.size === undefined) {
      return false;
    }
  }
  return true;
}