import { getUrl, saveOne } from '.';
import * as persistenceContstants from './constants';

/**
 * 
 * @param {Array<*>|null} files 
 * @param {*} songId 
 */
const getSaveSongMethod = (files, songId) => {
  if (files || !songId) {
    return persistenceContstants.METHODS.POST;
  }
  return persistenceContstants.METHODS.PUT;
}

/**
 * 
 * @param {Object} requiredData 
 * @param {Object} optionalData 
 */
const getRequestData = (requiredData, optionalData) => {
  const data = {};
  const optionalEntries = Object.entries(optionalData);
  optionalEntries.forEach(([key, value]) => {
    if (key && value) {
      data[key] = value;
    }
  })
  const requiredEntries = Object.entries(requiredData);
  requiredEntries.forEach(([key, value]) => {
    data[key] = value
  })
  return data;
}
/**
 * @typedef {import('../../Context').KarachordsContext} KarachordsContext
 * @typedef {Object} SaveSongParams
 * @property {String} name 
 * @property {String} artist 
 * @property {String} [tonality] 
 * @property {KarachordsContext} context
 * @property {File} [file] 
 * @property {String} [fileID]
 * @property {String} [id]
 * @param {SaveSongParams} params
 */
export async function saveSong({ name, artist, tonality, file, fileID: fileId, id, context }) {
  const files = getSaveSongFiles(file, fileId);
  const method = getSaveSongMethod(files, id)
  const userToken = await getUserTokenFromContext(context);
  const url = getUrl(persistenceContstants.SONGS_URL, id)
  const data = getRequestData({ name, artist }, {files, tonality});
  /** @type {import('.').SaveOneRequestOptions} */
  const options = { method };
  /** @type {import('.').SaveOneRequest} */
  const request = { url, userToken, options };
  const resp = await saveOne(request)(data);
  return resp;
}
/**
 * @param {String} songUrl
 * @param {String} songId
 * @param {String} fileId
 */
const getSaveSongFileLayersUrl = (songUrl, songId, fileId) => {
  const url = getUrl(songUrl, songId, new Map([['file', fileId], ['layers', null]]))
  return url;
}
/**
 * @typedef {Object} FilePagesLayersData
 * @property {import('../../components/play/utils').FilePagesLayers} [data]
 * @typedef {import('.').FetchResponse & FilePagesLayersData} SaveSongFileLayersResponse
 * 
 * @typedef {Object} SaveSongFileLayersParams
 * @property {import('./../../components/play/utils').FilePagesLayers} filePagesLayers
 * @property {String} id songId
 * @property {KarachordsContext} karachordsContext
 * @param {SaveSongFileLayersParams} params
 * @return {Promise<SaveSongFileLayersResponse>}
 */
export const saveSongFileLayers = async (params) => {
  const { id, filePagesLayers, karachordsContext } = params;
  const { fileId } = filePagesLayers;
  const userToken = await getUserTokenFromContext(karachordsContext);
  const data = { id, filePagesLayers };
  const url = getSaveSongFileLayersUrl(persistenceContstants.SONGS_URL, id, fileId)
  /** @type {import('.').SaveOneRequestOptions} */
  const options = { method: persistenceContstants.METHODS.PUT }
  /** @type {import('.').SaveOneRequest} */
  const request = { url, userToken, options }
  const resp = await saveOne(request)(data);
  return resp;
}

/**
 * @param {File} file
 * @param {String} fileId
 * @return {Array<{File:File, type:String, [id:String]}>}
 */
function getSaveSongFiles(file, fileId) {
  const files = [];
  if (file) {
    let uploadedFile = { File: file, type: file.type };
    if (fileId) {
      uploadedFile.id = fileId;
    }
    files.push(uploadedFile);
  }
  if (files.length < 1) {
    return null;
  }
  return files;
}

/**
 * 
 * @param {KarachordsContext} context 
 */
async function getUserTokenFromContext(context) {
  const user = context.firebase.auth().currentUser;
  const userToken = await user.getIdToken();
  return userToken;
}
/**
 * @typedef {Object} DeleteFilePagesLayerRequest
 * @property {String} fileId
 * @property {String} songId
 * @property {KarachordsContext} context
 */
/**
 * @param {DeleteFilePagesLayerRequest} request
 * @return {Promise<import('.').FetchResponse>}
 */
export const deleteFilePagesLayers = async (request) => {
  const { songId, fileId, context } = request;
  const userToken = await getUserTokenFromContext(context);
  /** @type {import('../../components/play/utils').FilePagesLayers} */
  const filePagesLayers = {pagesLayers: {}, fileId};
  const data = {id: songId, filePagesLayers};
  /** @type {import('.').SaveOneRequestOptions} */
  const options = {
    method: persistenceContstants.METHODS.PUT,
  };
  const req = {
    url: getSaveSongFileLayersUrl(persistenceContstants.SONGS_URL, songId, fileId),
    userToken,
    options,
  }
  const resp = await saveOne(req)(data);
  return resp;
}