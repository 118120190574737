import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import React from 'react';
import { handleShowConfirmActionDialog } from '../../dialogs/utils';
import { handleDeleteSong } from './utils';
import { copy } from './SongFormHeaderMenu';

export const DeleteSongMenuItem = (props) => {
  const { context, hidden } = props;
  const { dispatch } = context;
  if (hidden) {
    return null;
  }
  return (
    <MenuItem onClick={handleShowConfirmActionDialog(
      copy.delete,
      handleDeleteSong(context, props.initialValues, props.setIsLoading),
      dispatch
    )}>
      <ListItemIcon>
        <DeleteForever color={"secondary"} />
      </ListItemIcon>
      <ListItemText>
        {"Delete item"}
      </ListItemText>
    </MenuItem>
  );
};
