import * as constants from './constants';
import { createHashHistory } from 'history';

/**
 * @typedef {Object} DialogState
 * @property {Boolean} isOpen
 * @property {import('./constants').KarachordsDialog} dialog
 * @property {DialogMethods} methods
 * @property {DialogProps} props
 *
 * @typedef {Object} DialogMethods
 * @property {(filters:Array<import('./components/lists/utils').Filter>) => void} [setFilters]
 * @property {() => Promise<void>} [onClose] onClose async method passed to auth form - auto signs up a non-signed in user
 * @property {(e?:React.SyntheticEvent) => Promise<void>} [onConfirm]
 * @property {() => void} [afterConfirm]
 *
 * @typedef {Object} DialogProps
 * @property {Array<import('./components/lists/utils').Filter>} [filters]
 * @property {String} [displayText]
 * @property {String} [filtersPath]
 * @property {String} [headerText]
 * @property {String} [ctaText]
 * @property {String} [content]
 * @property {import('./constants').Item} [item]
 * @property {import('./constants').DATA_TYPES} [itemType]
 * @property {DialogShare} [share]
 * @property {Number} [listSize]
 * 
 * @typedef {SharedDetails & {roles: Array<AssignableRole>}} DialogShare
 * 
 * @typedef {Object} AssignableRole
 * @property {String} name
 * @property {String} id
 *
 * @typedef {Object} DataState
 * @property {Array<Object>} list
 * @property {Map<String, Object>} [map]
 * @property {Set<String>} [set]
 * @property {Number} max
 * @property {Number} [maxSize] 
 *
 * @typedef {DataState} ListsState
 * @property {Map<String, List>} [map]
 * @property {Array<List>} [list]
 *
 * @typedef {Map<String, import('./components/notifications').Notification>} NotificationsMap
 * @typedef {Object} NotificationsState
 * @property {NotificationsMap} map
 * @property {Array<Notification>} list
 *
 * @typedef {Object} PlayState
 * @property {Number} [currentIndex]
 * @property {Array<import('./components/forms/song/SongForm').Song>} songs
 *
 * @typedef {Object} MainState
 * @property {React.FunctionComponent} Component
 * @property {import('./constants').KarachordsView} view
 * @property {import('./constants').Item} item
 * @property {Array<import('./components/lists/utils').Filter>} filters
 *
 * @typedef {Object} NavState
 * @property {MenuState} menu
 * @property {SubMenuState} subMenu
 *
 * @typedef {Object} SubMenuState
 * @property {Array<import('./constants').Item>} items
 * @property {Map<String, import('./constants').Item>} map
 * @property {Array<import('./components/lists/utils').Filter>} filters
 *
 * @typedef {Object} MenuState
 * @property {Boolean} isOpen
 *
 * @typedef {DataState} SongsState
 * @property {Map<String, import('./components/forms/song/SongForm').Song>} [map]
 * @property {Array<import('./components/forms/song/SongForm').Song>} [list]
 *
 * @typedef {Object} ArtistsState
 * @property {Map<String, String>} [map]
 * @property {Set<String>} [set]
 * @property {Array<String>} [list]
 *
 * @typedef {Object} KarachordsState
 * @property {ArtistsState} artists
 * @property {DialogState} dialog
 * @property {Boolean} isLoading
 * @property {ListsState} lists
 * @property {NotificationsState} notifications
 * @property {PlayState} play
 * @property {MainState} main
 * @property {NavState} nav
 * @property {SongsState} songs
 * @property {import('history').History} history
 * @property {{id:String}} pendingShare
 */
/** @type {KarachordsState} */
export const initialState = {
  artists: {
    list: [],
    map: new Map(),
    set: new Set(),
  },
  dialog: constants.INITIAL_DIALOG_STATE,
  isLoading: true,
  lists: {
    list: [],
    map: new Map(),
    max: 0,
  },
  notifications: {
    list: [],
    map: new Map(),
  },
  play: {
    songs: null,
    currentIndex: 0,
  },
  main: constants.INITIAL_MAIN_STATE,
  nav: {
    menu: {
      isOpen: true
    },
    subMenu: {
      items: null,
      map: null,
      filters: [],
    }
  },
  songs: {
    list: [],
    map: new Map(),
    max: 0,
  },
  history: createHashHistory(),
  pendingShare: {id: null},
};
