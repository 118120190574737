import get from 'lodash.get';
import React, { useEffect } from 'react';
// @ts-ignore
import { Document, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { DEFAULT_PAGE_HEIGHT } from './playConstants';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'
/**
 * 
 * @param {import('react-pdf').PDFPageProxy} page
 * @returns {Number}
 */
const getPageScale = (page) => {
  const height = get(page, 'height', null);
  const originalHeight = get(page, 'originalHeight', null);
  let scale = null;
  if (height && originalHeight) {
    scale = page.height / page.originalHeight;
  }
  // const scale = get(pageRef, 'current.scale', null);
  return scale;
}

/**
 * 
 * @param {import('react-pdf').PDFPageProxy} pdfPageProxy 
 * @returns {String}
 */
const getPageHeight = (pdfPageProxy) => {
  const height = get(pdfPageProxy, 'height', null);
  if (!height) {
    return height;
  }
  return `${height}px`;
}

/**
 * @param {(scale:Number) => void} setPageScale
 * @param {(height:String) => void} setPageHeight
 * @param {(pageProxy: import('react-pdf').PDFPageProxy) => void} [setPageProxy]
 * @return {(pdfPageProxy:import('react-pdf').PDFPageProxy) => void}
 */
export const handlePageLoad = (setPageScale, setPageHeight, setPageProxy) => (pdfPageProxy) => {
  const scale = getPageScale(pdfPageProxy);
  if (!scale) {
    console.warn('unable to get page scale, not rendering notes');
  } else {
    setPageScale(scale);
  }
  const height = getPageHeight(pdfPageProxy);
  if (!height) {
    console.warn('unable to get page height, not rendering notes');
  } else {
    setPageHeight(height);
  }
  if (setPageProxy) {
    setPageProxy(pdfPageProxy);
  }

}

/**
 * 
 * @param {SongDocProps} prevProps 
 * @param {SongDocProps} nextProps 
 */
const songDocPropsAreEqual = (prevProps, nextProps) => {
  const checkProps = ["data"];
  const areEqual = checkProps.reduce((currResult, propName) => {
    if (currResult === false) {
      return false;
    }
    const prev = prevProps[propName];
    const next = nextProps[propName];
    if (prev !== next) {
      return false;
    }
    if (!prev || !next) {
      return false
    }
    if (prev.length && next.length) {
      // check arrays
      if (prev.length !== next.length) {
        return false;
      }
    } else {
      // check numbers
      if (prev !== next) {
        return false
      }
    }
    return true;
  }, true)
  return areEqual;
}
/**
 * @typedef {Object} SongDocProps
 * @property {Uint8Array} data
 * @property {(pdf: import('react-pdf').pdfjs.PDFDocumentProxy) => void } onLoadSuccess
 * @property {import('./PlayContext').PlayMethods} methods
 * 
 * @param {SongDocProps} props
 */
export const SongDocComponent = (props) => {
  const { data, onLoadSuccess, methods } = props
  const { setPageScale, setPageHeight } = methods;
  // const pageRef = useRef();
  useEffect(() => {
    // onPageLoad(setPageScale, setPageHeight, canvasRef, pageRef)();
    return () => {
      setPageScale(null);
      setPageHeight(DEFAULT_PAGE_HEIGHT)
    }
  })
  return (
    <Document
      file={{ data: new Uint8Array(data) }}
      onLoadSuccess={onLoadSuccess}
     />
  )
};

export const SongDoc = React.memo(SongDocComponent, songDocPropsAreEqual);

// export const SongDoc = SongDocComponent;