import { Grid, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { editModes } from '../../constants';
import { FontColorSelector } from './FontColorSelector';
import { usePlayStyles } from "./PlayStyles";
import { SaveCurrentSongButton } from './SaveCurrentSongButton';
import { SelectEditModeButton } from './SelectEditModeButton';
import { handleClick, SymbolPicker } from './SymbolPicker';

export const EditSymbolPanel = () => {
  const classes = usePlayStyles();
  const { editPanel, controlsButton } = classes;
  const [isPickerOpen, setPickerIsOpen] = useState(false);
  const toggleSymbolPicker = () => {
    const nextValue = !isPickerOpen;
        setPickerIsOpen(nextValue);
  }
  return (
    <Grid className={editPanel} container alignItems={"center"} justifyContent={"space-around"} direction={"row"} wrap={"nowrap"}>
      <Grid container item alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <SymbolPicker isOpen={isPickerOpen} setIsOpen={setPickerIsOpen} />
        <IconButton className={controlsButton} onClick={handleClick(toggleSymbolPicker)}>
          <Add />
        </IconButton>
      </Grid>

      <Grid container item alignItems={"center"} justifyContent={"center"}>
        <FontColorSelector />
      </Grid>

      {/* <Grid item container justifyContent={'center'}>
        <LayerSelector />
      </Grid> */}

      <Grid container item alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <SaveCurrentSongButton />
      </Grid>

      <Grid container item alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <SelectEditModeButton editMode={editModes.selecting} />
      </Grid>
    </Grid>
  );
};
