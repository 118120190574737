import { Container, makeStyles } from '@material-ui/core';
import { getOrientation } from 'o9n';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { EditArea } from './EditArea';
import { Pages } from "./Pages";
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { SongDoc } from './SongDoc';
import { getDndProviderBackend } from './utils';

export const songPageStyles = makeStyles(() => {
  return {
    cachedPage: {
      display: 'none'
    },
    swipeOverlay: {
      zIndex: 11,
      position: 'fixed',
      top: 0,
      // height: '100%',
      width: '100%'
    },
    toBack: {
      zIndex: 0
    },
  };
});

/** 
 * @param {(pages:Number) => setPages} setPages
 * @param {(pdf: import('react-pdf').pdfjs.PDFDocumentProxy) => void} setPdfProxy
 * @return {(pdfProx:import('react-pdf').pdfjs.PDFDocumentProxy) => void}
 * (pdfProx:import('pdfjs-dist').PDFDocumentProxy)
 */
export const handleDocLoadSuccess = (setPages, setPdfProxy) => (pdfProxy) => {
  const { numPages } = pdfProxy;
  setPages(numPages);
  setPdfProxy(pdfProxy);
};
const PAGE_WIDTH_PERCENTAGE = 0.987;
/**
 * 
 * @param {window} passedWindow
 * @param {import('react-pdf').PDFPageProxy} pdfPageProxy
 * @param {(width: Number) => void} setWidth
 * @param {(height: String) => void} setPageHeight
 * @param {(scale:Number) => void} setPageScale
 * @return {(e: Event) => void};
 */
const handleChangeDeviceOrientation = (passedWindow, pdfPageProxy, setWidth, setPageHeight, setPageScale, pageRef) => () => {
  setTimeout(() => {
    // At least in dev tools, the window hasn't updated with the new width until a few milliseconds after this event fires
    // So, wait a bit and hope it works out ¯\_(ツ)_/¯
    setWidth(passedWindow.innerWidth * PAGE_WIDTH_PERCENTAGE);
    pageRef.loadPage()
  }, 50);
}
const orientation = getOrientation();
export const PDFSong = () => {
  const context = useContext(PlayContext);
  const { methods, state } = context;
  const { currentFile: file, page, pages } = state;
  const { setPageScale, setPages, setPageHeight } = methods;
  const { buffer } = file;
  const classes = usePlayStyles();
  const { id: fileId } = file
  const [width, setWidth] = useState(window.innerWidth * PAGE_WIDTH_PERCENTAGE)
  const [pdfProxy, setPdfProxy] = useState(null);
  const [pageProxy, setPageProxy] = useState(null);
  const pageRef = createRef();
  useEffect(() => {
    const currPageRef = pageRef.current;
    orientation.onchange = handleChangeDeviceOrientation(window, pageProxy, setWidth, setPageHeight, setPageScale, currPageRef)
    return () => {
      orientation.onchange = null
    }
  }, [pageRef, setPageHeight])
  
  /** @type {Uint8Array} */
  // @ts-ignore
  const { data } = buffer;
  const canvasRef = createRef();
  // const handlers = useSwipeable({
  //   onSwipedRight: handleSwipeRight(prevPage),
  //   onSwipedLeft: handleSwipeLeft(nextPage)
  // })
  /** @type {import('./Pages').PagesProps} */
  const pagesProps = { page, pages, pageRef, width, canvasRef, setPageScale, setPageHeight, setPageProxy, fileId, pdfProxy}
  return (
    <Container className={classes.pdfContainer} disableGutters={true}>
      <DndProvider backend={getDndProviderBackend()} options={{ enableMouseEvents: true }}>
        <EditArea />
        <SongDoc
          data={data}
          // @ts-ignore
          onLoadSuccess={handleDocLoadSuccess(setPages, setPdfProxy)}
          methods={methods}
        />
        <Pages {...pagesProps} />
        {/* <span
          style={{height: pageHeight}}
          className={clsx(swipeOverlay, {
            [toBack]: Boolean(editMode)
          })}
          
          {...handlers}
          id={"swipe-overlay"}
        /> */}
        
      </DndProvider>
    </Container>
  );
};




// function handleSwipeLeft(nextPage) {
//   return (e) => {
//     const { velocity } = e;
//     if (velocity < 0.25) {
//       return;
//     }
//     nextPage();
//   };
// }

// function handleSwipeRight(prevPage) {
//   return (e) => {
//     const { velocity } = e;
//     if (velocity < 0.25) {
//       return;
//     }
//     prevPage();
//   };
// }

