import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useDrop } from 'react-dnd';
import { DraggableResizableContainer } from './DraggableResizeableContainer';
import { allowedDraggingModes, noteType } from "./playConstants";
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { isEditComponentActive, removeNote, updateNote } from './utils';

/**
 * 
 * @param {Number} index 
 * @param {Number} currentLayerIndex
 * @return {boolean}
 */
const isNotesOverlayActive = (index, currentLayerIndex) => {
  if (index === currentLayerIndex) {
    return true
  }
  return false
}
/**
 * @param {Array<import('./utils').Note>} notes 
 */
const renderNotes = (notes = []) => {
  return notes.map((note) => {
    const { id } = note;
    return (
      <DraggableResizableContainer
        note={note}
        key={`note-draggable-container-${id}`}
      />
    );
  });
}

const noteHasNonEmptyText = (note) => {
  if (!note.text) {
    return true
  }
  return false;
}
/**
 * 
 * @param {import('./utils').Note} activeNote 
 */
export const activeNoteIsEmpty = (activeNote) => {
  const { type } = activeNote;
  const tests = {
    [noteType.symbol]: [],
    [noteType.text]: [
      noteHasNonEmptyText
    ],
    [noteType.freehand]: [],
  }
  const selectedTests = tests[type] || [];
  return selectedTests.reduce((prev, test) => {
    if (prev === true) {
      return true
    }
    return test(activeNote);
  }, false)
}
/**
 * 
 * @param {boolean} isActive 
 * @param {import('.').PageLayers} layers 
 * @param {Number} index
 * @return {Number}
 */
export const getZIndex = (isActive, layers, index) => {
  // if (isActive) {
  //   return 100;
  // }
  const layersCount = Object.values(layers).length;
  const zIndex = 10 + (layersCount - index);
  return zIndex;
}

/**
 * 
 * @param {import('./utils').Layer} layer 
 */
export const isNotesOverlayHidden = (layer) => {
  const { isHidden } = layer;
  return isHidden;
}
/**
 * 
 * @param {{x: number, y:number}} obj 
 * @param {Number} pageScale 
 * @return {{x:number, y:number}}
 */
const unscaleObjectFromPage = (obj, pageScale) => {
  if (!obj || !pageScale) {
    return obj
  }
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = value / pageScale;
    return acc;
  }, {x: null, y: null});
}
/**
 * @typedef {Object} NotesOverlayProps
 * @property {import('./utils').Layer} layer
 * @param {NotesOverlayProps} props 
 */
export const NotesOverlay = (props) => {
  const { layer } = props;
  const { notes, idx } = layer;
  const { state, methods } = useContext(PlayContext);
  const { pageScale, editMode, activeNote, currentLayerIndex, layers, pageHeight } = state;
  const { setNotes, setActiveNote } = methods;
  const [ justDropped, setJustDropped ] = useState(false);
  const afterDrop = () => {
    setTimeout(() => {
      setJustDropped(false);
    }, 1)
  }
  const { dragAndDropEditComponent, editComponent } = usePlayStyles();
  const [, dropRef] = useDrop({
    accept: [noteType.text, noteType.symbol, noteType.freehand],
    drop: (item, monitor) => {
      const { isResizing } = activeNote;
      if (isResizing) {
        return;
      }
      // const { id } = item;
      const { x: originalX, y: originalY } = activeNote;
      const { x: diffX, y: diffY } = unscaleObjectFromPage(monitor.getDifferenceFromInitialOffset(), pageScale);
      const newX = getNewCoord(originalX, diffX);
      const newY = getNewCoord(originalY, diffY);
      const note = Object.assign({}, activeNote, item, { x: newX, y: newY });
      const updatedNote = updateNote(note, notes, setNotes);
      setActiveNote(updatedNote);
      setJustDropped(true);
      afterDrop();
    },
    collect: monitor => ({ isOver: !!monitor.isOver() })
  });
  const isHidden = isNotesOverlayHidden(layer);
  if (isHidden) {
    return null
  }
  const isActive = isNotesOverlayActive(idx, currentLayerIndex) && isEditComponentActive(editMode, ...allowedDraggingModes);
  const zIndex = getZIndex(isActive, layers, idx);
  const style = { zIndex };
  if (pageHeight) {
    Object.assign(style, { height: pageHeight });
  }
  return (
    <div
      ref={isActive ? dropRef : null}
      style={style}
      className={clsx(editComponent, dragAndDropEditComponent)}
      id={"notes-overlay"}
      onClick={() => {
        if (activeNote) {
          if (activeNoteIsEmpty(activeNote)) {
            removeNote(activeNote, notes, setNotes);
          }
          if (!justDropped) {
            setActiveNote(null);
          }
        }
      }}>
      {renderNotes(notes)}
    </div>
  );
};

/**
 * 
 * @param {Number} original 
 * @param {Number} diff 
 */
function getNewCoord(original, diff) {
  let newCoord = original + diff;
  return newCoord
}

