
import { SET_DATA, UPDATE_MAX } from '../../actions';
import { dispatchFetchErrorNotification } from '../notfications';
/**
 * Handles result of a persistance.getAll request
 * @param {(action:import('../../actions').Action) => void} dispatch
 * @param {import('./../../constants').DATA_TYPES} dataType
 * @param {Boolean} shouldOverwrite
 * @return {(resp: import('../persistence').FetchResponse) => void} resp
 */
export const handleGetAll = (dispatch, dataType, shouldOverwrite = false) => (resp) => {
  const { data, status, statusText, max, maxSize } = resp;
  if (status !== 200) {
    console.error('error while loading init data', statusText);
    dispatchFetchErrorNotification(resp, dispatch);
    return;
  }
  /** @type {import('../../reducers/functions').SetDataPayload} */
  const setDataPayload = { data, dataType, shouldOverwrite };
  const setDataAction = { type: SET_DATA, payload: setDataPayload };
  dispatch(setDataAction);
  /** @type {import('../../reducers/functions').UpdateMaxPayload} */
  const updateMaxPayload = { maxSize, max, dataType };
  dispatch({type: UPDATE_MAX, payload: updateMaxPayload});
  return;
};