import { Close } from '@material-ui/icons';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import pickBy from 'lodash.pickby';
import { TouchBackend } from 'react-dnd-touch-backend';
import { v4 as createUUID } from 'uuid';
import { UPDATE_LOCAL_SONG } from '../../actions';
import { editModes, tagetEditModeIcons } from '../../constants';
import { getPersistanceErrorMessage } from '../../constants/errors';
import { saveSongFileLayers } from '../../utils/persistence';
import {
  allowedDraggingModes,
  DEFAULT_BORDER_WIDTH,
  DEFAULT_CONTAINER_SIZE, DEFAULT_FONT,
  DIALOG_BUTTON_TEXT, draggableItemsByNote, editComponents,
  editPanelComponents,
  noteType,
  symbolsByName
} from './playConstants';
import { controlsSize, doubleControlsSize } from './PlayStyles';


/**
 * 
 * @typedef {Object} Note
 * @property {Number} [x]
 * @property {Number} [y]
 * @property {String} [type]
 * @property {String} [text]
 * @property {String} [id]
 * @property {Font} [font]
 * @property {Symbol} [symbol]
 * @property {{width:Number, height:Number}} [size]
 * 
 * @typedef {Object} Font
 * @property {String} [fontFamily]
 * @property {String} [displayName]
 * @property {String} [fontSize]
 * @property {String} [color]
 * 
 * @typedef {Object} Path
 * @property {String} [transform]
 * @property {String} d
 * 
 * @typedef {Object} Symbol
 * @property {Array<Path>} paths
 * @property {String} [display]
 * @property {String} [name]
 * @property {String} viewBox
 * @property {Number} [strokeWidth]
 * @property {String} [transform]
 * 
 * @typedef {Object} Layer
 * @property {String} [name]
 * @property {Array<Note>} notes
 * @property {Number} idx
 * @property {Boolean} [isHidden]
 * 
 * @typedef {Object<number, Layer>} PageLayers
 * 
 * @typedef {Object<number, PageLayers>} PagesLayers
 * 
 */

/**
* @typedef {import('../forms/song/SongForm').Song} Song
* @typedef {import('../forms/song/SongForm').SongFile} SongFile
* 
* @typedef {Object} SaveCurrentSongLayersParams
* @property {Song} currentSong 
* @property {SongFile} currentFile 
* @property {Number} page
* @property {Number} pages
* @property {PageLayers} layers
* @property {import('./../../Context').KarachordsContext} karachordsContext
* @property {import('./PlayContext').PlayContextType} playContext
* 
* @typedef {Object} FilePagesLayers
* @property {PagesLayers} pagesLayers
* @property {String} fileId
*
*/

export const getDndProviderBackend = () => {
  return TouchBackend;
};

/**
 * 
 * @param {React.RefObject} pageRef 
 * @returns {Number} alwa
 */
export const getPageRefScale = (pageRef) => {
  const scale = get(pageRef, 'current.scale', null);
  return scale;
}

/**
 * 
 * @param {import('../forms/song/SongForm').Song} song
 * @return {SongFile} 
 */
export const getStartingFile = (song) => {
  if (song.files && song.files[0]) {
    return song.files[0];
  }
  return null;
}


/**
 * 
 * @param {String} editMode 
 */
export const getEditComponent = (editMode) => {
  const EditModeComponent = editComponents[editMode];
  if (EditModeComponent) {
    return EditModeComponent;
  }
  return editComponents.default;
};



/**
 * 
 * @param {String} editMode 
 * @return {React.FunctionComponent}
 */
export const getEditPanelComponent = (editMode) => {
  if (editMode === null) {
    return editPanelComponents.DefaultEditComponent;
  }
  const SelectedComponent = editPanelComponents[editMode];
  if (!SelectedComponent) {
    console.warn('Attempted to find edit mode component for mode', editMode, 'but could not, defaulting to', editModes.selecting);
    return editPanelComponents[editModes.selecting];
  }
  return SelectedComponent;
}

/**
 * 
 * @param {String} editMode
 * @return {boolean}
 */
export const notesOverlayIsDroppable = (editMode) => {
  const droppableEditModes = allowedDraggingModes;
  if (droppableEditModes.has(editMode)) {
    return true;
  }
  return false;
}

/**
 * @param {String} editMode 
 * @return {boolean}
 */
export const isComponentSelectable = (editMode) => {
  if (allowedDraggingModes.has(editMode)) {
    return true;
  }
  return false;
}

/**
 * 
 * @param {String} editMode 
 * @param {boolean} isResizing
 * @param {boolean} isActive
 * @return {boolean}
 */
export const isComponentDraggable = (editMode, isResizing, isActive) => {
  if (isResizing) {
    return false;
  }
  if (!isActive) {
    return false;
  }

  if (!isComponentSelectable(editMode)) {
    return false;
  }
  return true;
};


/**
 * @param {boolean} bool
 * @returns {Number} 0 for false, 1 for true
 */
export const getNumberForBoolean = (bool) => {
  return bool === false ? 0 : 1;
}
/**
 * @param {Number} number
 * @returns {boolean} 1 for true, 0 f
 */
export const getBooleanForNumber = (number) => {
  return number === 0 ? false : true;
}

/**
 * 
 * @param {Number} strokeWidth 
 * @param {boolean} isErasing 
 * @return {Number}
 */
export const getFinalStrokeWidth = (strokeWidth, isErasing) => {
  if (isErasing) {
    return strokeWidth * 2;
  }
  return strokeWidth;
}

/**
 * 
 * @param {string} color 
 * @param {boolean} isErasing
 * @return {string}
 */
export const getFinalStrokeColor = (color, isErasing) => {
  if (isErasing) {
    return 'white';
  }
  return color;
}

/**
 *
 * @param {String} targetEditMode
 * @param {String} currentEditMode
 * @return {import('@material-ui/icons').SvgIconComponent}
 */
export const getEditModeIcon = (targetEditMode, currentEditMode) => {
  const Icon = tagetEditModeIcons[targetEditMode];
  if (Icon && targetEditMode !== currentEditMode) {
    // target was identified and is different from current
    return Icon;
  }
  // Default case - Icon wasn't found so we're exiting out of edit mode
  return Close;
};

/**
 * 
 * @param {String} currentEditMode 
 * @param {String} targetEditMode 
 * @param {(editMode:String) => void} setEditMode 
 */
export const selectEditModeButtonOnClick = (currentEditMode, targetEditMode, setEditMode) => () => {
  if (currentEditMode === targetEditMode) {
    setEditMode(editModes.selecting);
    return;
  }
  setEditMode(targetEditMode);
};

/**
 * @param {Note} note 
 * @return {React.FunctionComponent} DraggableItem component
 */
export const getDraggableItem = (note) => {
  return draggableItemsByNote[note.type];
}

/** @type {Note} */
export const defaultNote = {
  x: 0,
  y: 0,
  font: DEFAULT_FONT,
  type: noteType.text,
}

/**
 * 
 * @param {Note} partialNote
 * @return {Note}
 */
const getNewNote = (partialNote) => {
  const newNote = Object.assign({}, defaultNote, partialNote)
  return newNote;
}
/**
 * @param {Note} partialNote
 * @param {Array<Note>} notes
 * @param {(newNotes:Array<Note>) => void} setNotes
 * @return {Note}
 */
export const addNote = (partialNote, notes, setNotes) => {
  const id = createUUID();
  const note = getNewNote(Object.assign({}, partialNote, { id }));
  const newNotes = Array.from(notes);
  newNotes.push(note);
  setNotes(newNotes);
  return note;
}

/**
 * Returns a function that returns false if a passed key is in the removeList, otherwise true
 * @param {Array<String>} removeList
 * @return {(value: any, key: String) => boolean}
 */
export const getRemovePredicate = (removeList) => (value, key) => {
  const removeSet = new Set(removeList);
  if (removeSet.has(key)) {
    return false;
  }
  return true;
};
/**
 * Updates the passed note. Removes `isResizing` from it's properties before setting
 * @param {Note} note
 * @param {Array<Note>} notes
 * @param {(newNotes:Array<Note>) => void} setNotes
 * @return {Note} the note that was updated
 */
export const updateNote = (note, notes, setNotes) => {
  const { id } = note;
  for (let i = 0; i < notes.length; i++) {
    const currNote = notes[i];
    const { id: currNoteId } = currNote;
    if (currNoteId === id) {
      const nextNote = pickBy(Object.assign({}, currNote, note), getRemovePredicate(["isResizing"]))
      const nextNotes = notes.slice();
      nextNotes[i] = nextNote;
      setNotes(nextNotes);
      return nextNote;
    }
  }
}

/**
 * 
 * @param {Note} note 
 * @param {Array<Note>} notes 
 * @param {(notes:Array<Note>) => void} setNotes 
 */
export const removeNote = (note, notes, setNotes) => {
  const { id: targetId } = note;
  const nextNotes = notes.slice().filter(({ id }) => id !== targetId);
  setNotes(nextNotes);
}

/**
 * 
 * @param {Note} note 
 * @param {Note} activeNote
 * @return {Boolean}
 */
export const isNoteActive = (note, activeNote) => {
  if (!note || !activeNote) {
    return false;
  }
  if (note.id === activeNote.id) {
    return true;
  }
  return false;
}

/**
 * 
 * @param {String} currentEditMode 
 * @param {Array<String>} targetModes
 * @return {boolean} 
 */
export const isEditComponentActive = (currentEditMode, ...targetModes) => {
  const isActive = targetModes.reduce((prevResult, currentTargetMode) => {
    if (prevResult === true || currentEditMode === currentTargetMode) {
      return true;
    }
    return false;
  }, false)
  return isActive;
}

/**
 * 
 * @param {function} func 
 * @param {(note:Note) => void} setActiveNote
 * @return {(params) => void}
 */
export const clearActiveNoteAnd = (func, setActiveNote) => (...params) => {
  setActiveNote(null);
  func(...params);
}

/**
 * 
 * @param {(font: Font) => void} setFont
 * @param {(partialNote: Note) => void} updateActiveNote
 * @return {(font: Font) => void}
 */
export const updateActiveNoteAndSetFont = (setFont, updateActiveNote) => (font) => {
  updateActiveNote({ font });
  setFont(font);
}

export const withSetActiveNote = (setFont, setActiveNote) => (currentActiveNote) => (newActiveNote) => {
  let font = get(newActiveNote, 'font', null);
  if (!font) {
    font = get(currentActiveNote, 'font', DEFAULT_FONT);
  }
  setActiveNote(newActiveNote);
  setFont(font);
}

// export const getDefaultLayer = () => {
//   return getEmptyLayer(1);
// }
export const getEmptyLayer = (idx) => {
  return { idx, notes: [], name: `Layer ${idx}` };
}
/**
 * 
 * @param {Number} page
 * @return {Number} 
 */
export const pageToSongFilePageLayerTargetIndex = (page) => {
  return page - 1;
}

/**
 * 
 * @param {SongFile} currentFile 
 * @param {Number} page
 * @return {PageLayers}
 */
export function getLayersForFilePage(currentFile, page) {
  const pageLayers = get(currentFile, 'pagesLayers', {});
  const layers = pageLayers[page] || getDefaultPageLayers();
  return layers;
}

/**
 * 
 * @param {PageLayers} layers 
 * @param {Number} currentLayerIndex
 * @return {Layer}
 */
export function getCurrentLayer(layers, currentLayerIndex) {
  return layers[currentLayerIndex];
}

/**
 * 
 * @param {Number} number 
 * @param {Number} pageScale
 * @return {Number}
 */
export const scaleToPage = (number, pageScale) => {
  return number * pageScale;
}
/**
* 
* @param {Number} number 
* @param {Number} pageScale
* @return {Number}
*/
export const unscaleFromPage = (number, pageScale) => {
  return number / pageScale;
}

/**
 * 
 * @param {String} fileContents
 * @return {Array<String>}
 */
export function getPathsFromSVGFile(fileContents) {
  const pathsRegex = /(?<=<path.* d=")(.*?)(?=")/gms;
  const paths = fileContents.match(pathsRegex);
  return paths;
}

/**
 * 
 * @param {Array<String>} viewBoxMatches 
 */
const getViewBox = (viewBoxMatches) => {
  if (!viewBoxMatches) {
    return null
  }
  const match = viewBoxMatches[0];
  return match;
}

/**
 * 
 * @param {String} fileContents
 * @return {String}
 */
export function getViewBoxFromSVGFile(fileContents) {
  const viewBoxRegex = /(?<=<svg.* viewBox=")(.*?)(?=")/gms;
  const viewBoxMatches = fileContents.match(viewBoxRegex);
  const viewBox = getViewBox(viewBoxMatches);
  return viewBox;
}

export const getLookedUpSymbol = (type, name, symbol) => {
  let lookedUpSymbol = null;
  if (type === noteType.symbol) {
    lookedUpSymbol = symbolsByName[name];
  } else if (type === noteType.freehand) {
    lookedUpSymbol = symbol;
  }
  return lookedUpSymbol;
}

/**
 * @param {Note} note
 * @param {Number} pageScale
 * @return {{fontSize: String, fontFamily: String, color: String}}
 */
export const getScaledInputStyle = (note, pageScale) => {
  const { font } = note;
  const { fontFamily, color } = font;
  return { fontSize: `${pageScale * 100}%`, fontFamily, color };
}

/**
 * @typedef {Object} NoteStyle
 * @property {Number} [top]
 * @property {Number} [left]
 * @property {String} [fontSize]
 * @param {Note} note
 * @param {Number} pageScale
 * @return {NoteStyle}
 */
export const getDraggableContainerStyle = (note, pageScale, isActive) => {
  const { font } = note;
  const { fontSize } = font;
  let { x, y } = note
  x = x * pageScale;
  y = y * pageScale;
  if (isActive) {
    x = x - DEFAULT_BORDER_WIDTH;
    y = y - DEFAULT_BORDER_WIDTH;
  }
  const style = { top: y, left: x, fontSize }
  return style;
}

/**
 * 
 * @param {Array<import('react-sketch-canvas').Point>} sortedPointsArray 
 * @param {String} fieldToCompare 
 * @param {Number} pageScale
 * @param {Number} strokeWidth
 */
export function getDeltaFromSortedPaths(sortedPointsArray, fieldToCompare, pageScale, strokeWidth) {
  // const padding = (DEFAULT_BORDER_WIDTH * 2);
  const padding = (strokeWidth * 2);
  // const padding = 10;
  const max = sortedPointsArray[0][fieldToCompare];
  const unsacaledMax = unscaleFromPage(max, pageScale);
  const unscaledPaddedMax = unsacaledMax + padding;
  const cielMax = Math.ceil(unscaledPaddedMax);

  const min = sortedPointsArray[sortedPointsArray.length - 1][fieldToCompare];
  const unscaledMin = unscaleFromPage(min, pageScale);
  // const unscaledPaddedMin = unscaledMin + padding;
  const floorMin = Math.floor(unscaledMin);
  // const floorMin = Math.floor(unscaledPaddedMin)
  return cielMax - floorMin;
}

export const DEFAULT_LAYERS = {
  1: getEmptyLayer(1)
}

/**
 * 
 * @param {PagesLayers} pagesLayers
 * @param {String} fileId
 * @return {FilePagesLayers} saveSongLayers
 */
const getSaveSongLayers = (pagesLayers, fileId) => {
  return { fileId, pagesLayers }
}


function getDefaultPageLayers() {
  return Object.assign({}, DEFAULT_LAYERS);
}
/**
 * 
 * @param {SongFile} currentFile 
 * @param {Number} page
 * @return {PagesLayers}
 */
function getFilePagesLayers(currentFile, page) {
  let pagesLayers = currentFile.pagesLayers || {};
  let pageLayers = pagesLayers[page];
  if (!pageLayers) {
    pagesLayers[page] = getDefaultPageLayers();
  }
  return pagesLayers;
}

/**
 * @param {boolean} state currentState boolean
 * @param {(nextStateVal: boolean) => void} setState function to set next state - toggleState will flip currentState boolean and set
 */
export const toggleState = (state, setState) => () => {
  setState(!state);
}

/**
 * @param {Array<() => Promise<void>>} asyncFuncsArr array of async functions that will be called with Promise.all()
 * @param {Array<Function>} [andThen] array of synchronus functions
 * @return {() => Promise<void>}
 */
export const callMultiple = (asyncFuncsArr, andThen) => async () => {
  const promises = asyncFuncsArr.map(func => func());
  await Promise.all(promises);
  if (andThen) {
    andThen.forEach(func => func());
  }
}

/**
 * 
 * @param {PageLayers} pageLayers 
 */
const pageLayersIsDefault = (pageLayers) => {
  const areEqual = isEqual(pageLayers, getDefaultPageLayers());
  return areEqual;
}

/**
 * 
 * @param {import('./utils').Song} song 
 * @param {import('./utils').Song} otherSong
 * @returns {boolean}
 */
export const areSongsEqual = (song, otherSong) => {
  const compareSong = getCompareSong(song);
  const otherCompareSong = getCompareSong(otherSong);
  const metadataAreEqual = isEqual(compareSong, otherCompareSong);
  if (!metadataAreEqual) {
    return false;
  }
  // const { files } = song;
  // const { files: otherFiles } = otherSong;
  // const filesAreEqual = areFilesEqual(files, otherFiles);
  return true;
}

/**
 * 
 * @param {SongFile} file 
 * @param {Number} page 
 * @param {PageLayers} nextPageLayers
 * @return {SongFile} new song file with merged pageLayers for selected page
 */
export const updateFileWithNextPageLayers = (file, page, nextPageLayers) => {
  const { pagesLayers = { [page]: getDefaultPageLayers() } } = file;
  
  const pageLayers = pagesLayers[page];
  const updatedPageLayers = Object.assign({}, pageLayers, nextPageLayers);
  const updatedPagesLayers = Object.assign({}, pagesLayers, { [page]: updatedPageLayers })
  const updatedFile = Object.assign({}, file, { pagesLayers: updatedPagesLayers })
  return updatedFile;
}


/**
 * @typedef {(song:Song) => void} setCurrentSong
 * @typedef {(newFile:SongFile) => void} setCurrentFile
 * 
 * @param {Song} currentSong
 * @param {setCurrentSong} setCurrentSong
 * @return {setCurrentFile}
 * 
 */
export const handleSetCurrentFile = (currentSong, setCurrentSong) => (newFile) => {
  const { files } = currentSong;
  const nextFiles = files.map(file => {
    const { id } = file;
    if (id === newFile.id) {
      return newFile;
    }
    return file;
  })
  const nextSong = Object.assign({}, currentSong, { files: nextFiles });
  setCurrentSong(nextSong);
}

/**
 * @typedef {(newNotes:Array<import('./utils').Note>) => void} setNotes
 * 
 * @param {Layer} currentLayer
 * @param {PageLayers} layers
 * @param {setLayers} setLayers
 * @return {setNotes}
 */
export const handleSetNotes = (currentLayer, layers, setLayers) => (notes) => {
  const nextCurrentLayer = Object.assign({}, currentLayer, { notes });
  const { idx } = nextCurrentLayer;
  const nextLayers = Object.assign({}, layers, { [idx]: nextCurrentLayer });
  setLayers(nextLayers);
}
/**
 * @typedef {(pageLayers:PageLayers) => void} setLayers
 * 
 * @param {SongFile} currentFile 
 * @param {setCurrentFile} setCurrentFile 
 * @param {Number} page
 * @return {setLayers}
 */
export const handleSetLayers = (currentFile, setCurrentFile, page) => (pageLayers) => {
  const nextFile = updateFileWithNextPageLayers(currentFile, page, pageLayers);
  setCurrentFile(nextFile);
}

/**
 * 
 * @param {import('./PlayContext').DialogMessage} dialogMessage
 * @return {import('./playConstants').DIALOG_BUTTON_TEXT}
 */
export const getDialogButtonText = (dialogMessage) => {
  if (!DIALOG_BUTTON_TEXT[dialogMessage]) {
    return {};
  }
  return DIALOG_BUTTON_TEXT[dialogMessage];
}

/**
* 
* @param {SaveCurrentSongLayersParams} params
* @return {(e?:import('react').SyntheticEvent) => Promise<void>}
*/
export const handleSaveCurrentSongLayers = (params) => async () => {
  const { currentSong, currentFile, page, layers, karachordsContext, playContext } = params;
  const { id } = currentSong;
  const { id: fileId } = currentFile;
  const pagesLayers = getFilePagesLayers(currentFile, page);
  const pageLayers = pagesLayers[page];
  const updatedPageLayers = Object.assign({}, pageLayers, layers)
  pagesLayers[page] = updatedPageLayers;
  // /** @type {import('./index').SongFile} */
  // const file = { name: fileName, id: fileId, type, buffer, pagesLayers: updatedPagesLayers };

  const saveSongLayers = getSaveSongLayers(pagesLayers, fileId);
  /** @type {import('./../../utils/persistence/song').SaveSongFileLayersParams} */
  const saveSongParams = { id, filePagesLayers: saveSongLayers, karachordsContext };
  const resp = await saveSongFileLayers(saveSongParams);
  const { data, status, error } = resp;
  const { methods } = playContext;
  methods.setIsLoading(false);
  if (status !== 200) {
    methods.setNotification({message: getPersistanceErrorMessage(error), options: {variant: "error"}});
    return
  }
  const { filePagesLayers } = data;
  const { pagesLayers: nextPagesLayers } = filePagesLayers;
  const { dispatch } = karachordsContext;
  const { files } = currentSong;
  let newCurrentFile;
  const updatedFiles = files.map((file) => {
    const { id } = file;
    if (id === currentFile.id) {
      /** @type {SongFile} */
      const updatedFile = Object.assign({}, currentFile, { pagesLayers: nextPagesLayers });
      newCurrentFile = updatedFile;
      return updatedFile;
    }
    return file;
  })
  const updatedSong = Object.assign({}, currentSong, { files: updatedFiles })
  /** @type {import('../../reducers/functions').UpdateLocalSongPayload} */
  const payload = { song: updatedSong, isSilent: true };
  dispatch({ type: UPDATE_LOCAL_SONG, payload })
  // update song in app memory
  methods.setCurrentFile(newCurrentFile);
  // update local song to play
  
  // disable loading spinner
  // methods.setShouldForceOverrideInterrupt(true);
  methods.setWasEdited(false);
  // if manually saved, should not trigger dialog to prompt user to save
  /** @type {import('../notifications').Notification} */
  const notification = { message: "Successfully saved all notes on this file!", options: { variant: 'success' } }
  methods.setNotification(notification)
}
/**
 * 
 * @param {Song} song
 * @return {Song} filters out default pagesLayers
 */
function getCompareSong(song) {
  /** @type {Array<SongFile>} */
  const emptySongFileArr = [];
  const songFiles = get(song, 'files', emptySongFileArr);
  const filteredSongFiles = songFiles.map(file => {
    const pagesLayers = get(file, 'pagesLayers', null);
    if (!pagesLayers) {
      return file;
    }

    const filteredPagesLayers = Object.entries(pagesLayers).reduce((filtered, [key, pageLayer]) => {
      if (!pageLayersIsDefault(pageLayer)) {
        if (!filtered) {
          // @ts-ignore
          filtered = {};
        }
        filtered[key] = pageLayer;
      }
      return filtered;
    }, null);

    if (!filteredPagesLayers) {
      // {buffer, id, name, string, type}
      return Object.entries(file).reduce((outFile, [key, value]) => {
        if (key !== 'pagesLayers') {
          outFile[key] = value;
        }
        return outFile;
      }, {});
    }
    const filteredFile = Object.assign({}, file, { pagesLayers: filteredPagesLayers });
    return filteredFile;
  });

  const compareSong = Object.assign({}, song, { files: filteredSongFiles });
  return compareSong;
}

/**
 * 
 * @param {SongFile} currentFile 
 * @param {Number} page
 * @param {PageLayers} layers 
 * @return {SongFile} updated SongFile
 */
export function getUpdatedFileWithPagesLayers(currentFile, page, layers) {
  const { pagesLayers } = currentFile;
  if (!layers || isEqual(layers, getDefaultPageLayers())) {
    // if no layers or notes have been added yet, don't do anything
    return currentFile;
  }
  const updatedPagesLayers = Object.assign({}, pagesLayers, { [page]: layers });
  const updatedFile = Object.assign({}, currentFile, { pagesLayers: updatedPagesLayers });
  return updatedFile;
}
/**
 * @return {boolean}
 */
export function isBrowserMobile() {
  const mobileTests = ["Mobi", "Android"];
  const isMobile = mobileTests.reduce((prevTestResult, testString) => {
    if (prevTestResult) {
      return prevTestResult;
    }
    return window.navigator.userAgent.includes(testString);
  }, false);
  return isMobile;
}

/**
 * 
 * @param {() => void} changeState function to change the state
 * @param {import('./PlayContext').DialogMessage} dialogMessage
 * @param {(message:import('./PlayContext').DialogMessage) => void} setDialogMessage
 * @param {boolean} wasEdited
 * @return {(shouldForceStateChange:boolean) => VoidFunction}
 */
export function handleInterruptableStateChange(changeState, dialogMessage, setDialogMessage, wasEdited) {
  return (shouldForceStateChange) => () => {
    if (shouldForceStateChange) {
      changeState();
      return;
    }
    if (wasEdited) {
      setDialogMessage(dialogMessage);
      return;
    }

    changeState();
  };
}

/**
 * @param {import('./PlayContext').PlayContextType} context
 * @returns {import('./SketchOverlay').HandleDrawCompletionParams}
*/
export const getHandleDrawCompletionParams = (context) => {
  const { state, methods } = context
  const { freehandEditRef, pageScale, font, strokeWidth, notes } = state;
  const { setNotes, setActiveNote, setEditMode } = methods;
  const params = { ref: freehandEditRef, pageScale, font, strokeWidth, notes, setNotes, setActiveNote, setEditMode };
  return params;
}

export function handleAfterResolvePrevSong(playPrev) {
  return () => { playPrev(); };
}

export function handleAfterResolveNextSong(playNext) {
  return () => { playNext(); };
}

export function handleAfterResolveExit(onClosePlayMode) {
  return () => { onClosePlayMode(true); };
}

export function handleAfterResolveExitFreehand(setDialogMessage, setWasEdited, setEditMode, freehandEditRef) {
  return () => {
    setDialogMessage(null);
    setWasEdited(false);
    setEditMode(editModes.selecting);
    if (freehandEditRef && freehandEditRef.current) {
      freehandEditRef.current.clearCanvas();
    }
  };
}

/**
 * 
 * @param {*} playContext 
 * @param {*} karachordsContext 
 * @return  {import('./utils').SaveCurrentSongLayersParams}
 */
export const getSaveCurrentSongParams = (playContext, karachordsContext) => {
  const { state } = playContext;
  const { currentSong, currentFile, page, pages, layers } = state;
  const saveCurrentSongParams = { currentSong, currentFile, page, pages, layers, karachordsContext, playContext };
  return saveCurrentSongParams
}

/**
 * 
 * @param {Number} measurement 
 */
export const getCenter = (measurement) => {
  return measurement / 2;
}
/**
 * 
 * @param {Window} window
 * @param {{width: Number, height: Number}} [containerSize] defaults to DEFAULT_CONTAINER_SIZE
 * @param {number} [pageScale] defaults to 1
 */
export const getUnscaledDefaultNoteOrigin = (window, containerSize = DEFAULT_CONTAINER_SIZE, pageScale = 1) => {
  let x = getCenter(window.innerWidth);
  let y = getCenter(window.innerHeight);
  const { width, height } = containerSize;
  x = x - getCenter(width);
  y = y - getCenter(height);
  x = unscaleFromPage(x, pageScale);
  y = unscaleFromPage(y + window.scrollY, pageScale);
  return {x, y}
}

/**
 * 
 * @param {Path} path
 * @return {Path}
 */
export function getPathProps(path) {
  const { d, transform } = path;
  const pathProps = { d };
  if (transform) {
    Object.assign(pathProps, { transform });
  }
  return pathProps;
}

/**
 * 
 * @param {() => void} changePage 
 * @param {() => void} changeSong 
 * @param {Boolean} noNextPage 
 */
export const getChangePageMethod = (changePage, changeSong, noNextPage) => {
  if (noNextPage) {
    return changeSong;
  }
  return changePage;
}

export const getPlayRootHeight = (pageHeight, editMode) => {
  if (editMode) {
    return `calc(${pageHeight} + ${doubleControlsSize}`
  }
  return `calc(${pageHeight} + ${controlsSize}`;
}

/**
 * @param {Array<Song>} currentSongs
 * @param {(songs:Array<Song>) => void} setCurrentSongs
 * @param {boolean} wasEdited
 * @param {(wasEdited: boolean) => void} setWasEdited
 * @return {(updatedSong:Song) => void}
 */
export const handleSetCurrentSong = (currentSongs, setCurrentSongs, wasEdited, setWasEdited) => (updatedSong) => {
  const { id: updatedSongId } = updatedSong;
  const songs = currentSongs.map((song) => {
    const { id } = song;
    if (updatedSongId === id) {
      if (wasEdited || !areSongsEqual(song, updatedSong)) {
        setWasEdited(true)
      }
      return updatedSong
    }

    return song;
  })
  setCurrentSongs(songs);
}

export function handleGoToPrevPage(noPrevPage, wasEdited, page, currentFile, layers, setCurrentFile, setPage, setActiveNote, setWasEdited, freehandEditRef) {
  return () => {
    if (noPrevPage) {
      return;
    }
    const songWasEdited = new Boolean(wasEdited);
    const newPage = page - 1;
    const currentPageUpdatedFile = getUpdatedFileWithPagesLayers(currentFile, page, layers);
    const newLayers = getLayersForFilePage(currentPageUpdatedFile, newPage);
    const newPageUpdatedFile = getUpdatedFileWithPagesLayers(currentPageUpdatedFile, newPage, newLayers);
    setCurrentFile(newPageUpdatedFile);
    setPage(newPage);
    setActiveNote(null);
    if (freehandEditRef && freehandEditRef.current) {
      freehandEditRef.current.clearCanvas();
    }
    if (!songWasEdited) {
      // if song was not edited before the page change, remark it as not edited
      setWasEdited(false);
    }
  };
}

export function handleGoToNextPage(noNextPage, wasEdited, page, currentFile, layers, setCurrentFile, setPage, setActiveNote, setWasEdited, freehandEditRef) {
  return () => {
    if (noNextPage) {
      return;
    }
    const songWasEdited = new Boolean(wasEdited);
    const newPage = page + 1;
    const currentPageUpdatedFile = getUpdatedFileWithPagesLayers(currentFile, page, layers);
    const newLayers = getLayersForFilePage(currentPageUpdatedFile, newPage);
    const newPageUpdatedFile = getUpdatedFileWithPagesLayers(currentPageUpdatedFile, newPage, newLayers);
    setCurrentFile(newPageUpdatedFile);
    setPage(newPage);
    setActiveNote(null);
    if (freehandEditRef && freehandEditRef.current) {
      freehandEditRef.current.clearCanvas();
    }
    
    if (!songWasEdited) {
      // if song was not edited before the page change, remark it as not edited
      setWasEdited(false);
    }
  };
}

export function handleToLayer(activeNote, moveNoteToLayer, layers, currentLayer, setLayers) {
  return (setCurrentLayerIndex) => (nextIndex) => {
    if (activeNote) {
      moveNoteToLayer(activeNote, layers, currentLayer, nextIndex, setLayers);
      // move active note from current layer to new layer
    }
    setCurrentLayerIndex(nextIndex);
  };
}

export function handleUpdateActiveNote(setActiveNote, activeNote, notes, setNotes, setFont) {
  return (newActiveNote) => {
    if (!newActiveNote) {
      setActiveNote(null);
      return;
    }
    const { font: newFont } = newActiveNote;
    const font = get(activeNote, 'font', {});
    const nextFont = Object.assign({}, font, newFont);
    const nextActiveNote = Object.assign({}, newActiveNote, { font: nextFont });
    updateNote(nextActiveNote, notes, setNotes);
    setFont(nextFont);
    setActiveNote(nextActiveNote);
  };
}

export function handleUpdateEditMode(setActiveNote, setEditMode) {
  return (editMode) => {
    setActiveNote(null);
    setEditMode(editMode);
  };
}

export function handleUpdateFont(font, setFont, activeNote, updateActiveNote) {
  return (newFont) => {
    const nextFont = Object.assign({}, font, newFont);
    setFont(nextFont);
    if (activeNote) {
      updateActiveNote(Object.assign({}, activeNote, { font: nextFont }));
    }
  };
}

export function handleClosePlayMode(onClose, resetLocalState) {
  return () => {
    resetLocalState();
    onClose();
  };
}