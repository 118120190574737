import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { Context } from '../../Context';
import get from 'lodash.get';
import { handleCloseDialog } from './utils';

const DEFAULT_CONFIRM_TEXT = 'This action is irreversible.';

/**
 * 
 * @param {function} confirm 
 * @param {function} close
 * @param {(isLoading: boolean) => void} setIsLoading
 * @param {() => void} [afterClose]
 */
const handleConfirm = (confirm, close, setIsLoading, afterClose = () => null) => async (e) => {
  setIsLoading(true);
  await confirm(e);
  close();
  afterClose();
}


export const ConfirmAction = () => {
  /** @type {import('../../utils/persistence').KarachordsContext} */
  const { state, dispatch } = useContext(Context);
  const displayText = get(state, 'dialog.props.displayText', DEFAULT_CONFIRM_TEXT);
  const header = get(state, 'dialog.dialog.header', null);
  const confirm = get(state, 'dialog.methods.onConfirm', () => null);
  const afterClose = get(state, 'dialog.methods.afterClose', () => null);
  const [isLoading, setIsLoading] = useState(false);
  const close = handleCloseDialog(dispatch);
  return (
    <React.Fragment>
      <DialogTitle disableTypography={true}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">
            {header}
          </Typography>
          <IconButton onClick={close} disabled={isLoading}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid direction={"row"} container item>
          <Typography>
            {displayText}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} color={"default"} onClick={close} disabled={isLoading}>
          {"Cancel"}
        </Button>
        <Button
          variant={"text"}
          color={"secondary"}
          onClick={handleConfirm(confirm, close, setIsLoading, afterClose)}
          disabled={isLoading}
        >
          {"Confirm"}
        </Button>
        {isLoading && <CircularProgress />}
      </DialogActions>
    </React.Fragment>
  )
}