import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { DIALOG_ACTIONS } from './playConstants';
import { PlayContext } from './PlayContext';
import { SaveCurrentSongDialogButton } from './SaveCurrentSongButton';
import { handleDrawCompletion } from './SketchOverlay';
import { callMultiple, getHandleDrawCompletionParams, getSaveCurrentSongParams, handleSaveCurrentSongLayers, toggleState } from './utils';

/**
 * 
 * @typedef {Object} PlayDialogActionsProps
 * @property {import('./playConstants').DIALOG_BUTTON_TEXT} dialogButtonText
 * @property {VoidFunction} afterResolveInterrupt
 */
/**
 * 
 * @param {PlayDialogActionsProps} props 
 */
export const SaveCurrentSongDialogActions = (props) => {
  const { dialogButtonText, afterResolveInterrupt } = props;
  const playContext = useContext(PlayContext);
  const karachordsContext = useContext(Context)
  const { state, methods } = playContext;
  const { isLoading } = state;
  const { setIsLoading } = methods;
  const onClick = async () => {
    toggleState(isLoading, setIsLoading);
    const onDrawCompletion = handleDrawCompletion(getHandleDrawCompletionParams(playContext));
    await onDrawCompletion();
    await handleSaveCurrentSongLayers(getSaveCurrentSongParams(playContext, karachordsContext))();
    afterResolveInterrupt();
  }
  return (
    <React.Fragment>
      <SaveCurrentSongDialogButton
        text={dialogButtonText.save}
        onClick={onClick}
      />
      <Button color={"secondary"} onClick={afterResolveInterrupt}>
        {dialogButtonText.skip}
      </Button>
    </React.Fragment>
  )
}

/**
 * 
 * @param {PlayDialogActionsProps} props
 */
export const RenderFreehandDrawingDialogActions = (props) => {
  const { dialogButtonText, afterResolveInterrupt } = props;
  const context = useContext(PlayContext);
  const params = getHandleDrawCompletionParams(context);
  return (
    <React.Fragment>
      <Button
        color={"secondary"}
        onClick={callMultiple([handleDrawCompletion(params)], [afterResolveInterrupt])}
      >
        {dialogButtonText.save}
      </Button>
      <Button color={"secondary"} onClick={afterResolveInterrupt}>
        {dialogButtonText.skip}
      </Button>
    </React.Fragment>
  )
}

/**
 * 
 * @param {DIALOG_MESSAGES} dialogMessage
 * @return {DIALOG_ACTIONS}
 */
export const getDialogActions = (dialogMessage) => {
  if (DIALOG_ACTIONS[dialogMessage]) {
    return DIALOG_ACTIONS[dialogMessage];
  }
  return SaveCurrentSongDialogActions
}
/**
 *
 * @param {*} props
 */
export const PlayViewDialog = (props) => {
  const { dialogMessage, setDialogMessage, dialogButtonText, getAfterResolve } = props;
  const afterResolveInterrupt = getAfterResolve(dialogMessage);
  const Actions = getDialogActions(dialogMessage)
  /** @type {PlayDialogActionsProps} */
  const actionsProps = { dialogButtonText, afterResolveInterrupt };
  return (
    <Dialog open={!!dialogMessage} onClose={() => {
      setDialogMessage(null);
    }}>

      <Grid container item direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
        <IconButton onClick={() => {
          setDialogMessage(null);
        }}>
          <Close />
        </IconButton>
      </Grid>

      <DialogContent>
        <DialogContentText>
          {dialogMessage}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Actions {...actionsProps} />
      </DialogActions>

    </Dialog>
  );
};


