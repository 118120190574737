import React, { useContext } from 'react';
import { NotesOverlay } from './NotesOverlay';
import { PlayContext } from './PlayContext';
import { SketchOverlay } from './SketchOverlay';

export const EditArea = () => {
  const { methods, state } = useContext(PlayContext);
  const { strokeColor, strokeWidth, layers } = state;
  const { setFreehandEditRef } = methods;
  const layersArr = Object.values(layers);
  return (
    <React.Fragment>
      {layersArr.map(layer => <NotesOverlay layer={layer} key={`notes-overlay-${layer.idx}`} />)}
      <SketchOverlay strokeColor={strokeColor} strokeWidth={strokeWidth} setFreehandEditRef={setFreehandEditRef} />
    </React.Fragment>
  );
};


