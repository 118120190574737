import { Button } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { handleShowConfirmActionDialog } from '../dialogs/utils';
import { handleRevokeShare } from '../utils';

const getCopy = (roleName) => {
  const confirm = `Anyone that you shared the ${roleName} role with for this item will no longer be able to access it.`
  return {
    confirm,
  }
}

/**
 * @typedef {Object} RevokeShareButtonProps
 * @property {SharedDetails} share the share targeted for revoking
 * @property {import('../../constants').Item} item
 * @property {() => void} [afterClose]
 * @property {boolean} [disabled]
 * @property {String} [className]
 * 
 * @param {RevokeShareButtonProps} props 
 */
export const RevokeShareButton = (props) => {
  const { share, item, disabled, className, afterClose } = props;
  const role = get(share, 'role', null)
  const context = useContext(Context);
  const { dispatch } = context;
  const dialogCopy = getCopy(role);
  return (
    <Button
      className={className}
      variant={"contained"}
      color={"secondary"}
      onClick={handleShowConfirmActionDialog(
        dialogCopy.confirm,
        handleRevokeShare(share, item, context),
        dispatch,
        afterClose
      )}
      startIcon={<RemoveCircle />}
      disabled={disabled}
      style={dialogCopy ? {} : { display: 'none' }}
    >
      {"Revoke"}
    </Button>
  );
}