import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import firebase from "firebase/app";
import get from 'lodash.get';
import React, { useContext } from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import ReactMarkdown from 'react-markdown';
import remarkHeadingId from 'remark-heading-id';
import { OPEN_DIALOG } from '../../actions';
import { DIALOG_NAMES, faqComponentName, faqImpressionEventAuth, featuresComponentName, featuresImpressionEventAuth, privacyComponentName, privacyDialogProps, privacyImpressionEventAuth, tosComponentName, tosDialogProps, tosImpressionEventAuth } from '../../constants';
import { Context } from '../../Context';
import { handleDisplayLegalDialog, handleLogImpression, logAnalyticsEvent } from '../../utils';
import contentJSON from './../../constants/content.json';
import { copy, onSignInSuccess } from './Auth';
import { useDialogStyles } from './styles';
import { handleOpenAuthDialog as handleOpenAuthDialog } from './utils';

/**
 * @typedef {Object} AuthFormProps
 * @property {firebase.auth.Auth} firebaseAuth
 * @property {firebase.analytics.Analytics} analytics
 * @property {() => void} closeDialog
 * @property {boolean} isLoading
 * @property {import('react').Dispatch<import('../../actions').Action>} dispatch
 * @param {AuthFormProps} props
 */
export const AuthForm = (props) => {
  const { firebaseAuth, analytics, closeDialog, isLoading, dispatch } = props;
  const { state } = useContext(Context);
  const { hidden, flexMarkdownContainer } = useDialogStyles();
  const openAuthDialog = handleOpenAuthDialog(dispatch);
  const dialogMethods = {
    onClose: openAuthDialog
  };

  const openFAQDialog = () => {
    /** @type {import('../../reducers/functions').OpenDialogPayload} */
    const payload = {
      dialog: DIALOG_NAMES.INFO,
      methods: dialogMethods,
      props: {
        headerText: "FAQ",
        content: contentJSON.faq
      }
    }
    dispatch({ type: OPEN_DIALOG, payload });
    logAnalyticsEvent(faqImpressionEventAuth, faqComponentName, analytics);
    analytics.setCurrentScreen(faqComponentName);
  }

  const openFeaturesDialog = () => {
    /** @type {import('../../reducers/functions').OpenDialogPayload} */
    const payload = {
      dialog: DIALOG_NAMES.INFO,
      methods: dialogMethods,
      props: {
        headerText: "Features",
        content: contentJSON.features
      }
    }
    dispatch({ type: OPEN_DIALOG, payload });
    logAnalyticsEvent(featuresImpressionEventAuth, featuresComponentName, analytics);
    analytics.setCurrentScreen(featuresComponentName);
  }
  const hasPendingShare = get(state, 'pendingShare.id', false);
  const firebaseUIConfig = getFirebaseUIConfig(closeDialog, dialogMethods, dispatch, analytics);
  return (
    <Grid container direction={"column"} alignItems={"center"}>
      <Grid item className={clsx({[hidden]: !hasPendingShare})}>
        <Alert variant={"filled"} severity={"success"}>{copy.pendingShare}</Alert>
      </Grid>
      <ReactMarkdown plugins={[[remarkHeadingId]]} className={flexMarkdownContainer}>
        {contentJSON.explainer}
      </ReactMarkdown>
      <Grid item>
        <Button color={"default"} variant={"outlined"} onClick={openFeaturesDialog}> {copy.features.link}</Button>
      </Grid>
      <Grid container item direction={"column"} alignItems={"center"}>
        <Typography>
          {copy.faq.intro}
        </Typography>
        <Button color={"primary"} variant={"text"} onClick={openFAQDialog}> {copy.faq.link} </Button>
      </Grid>
      <Grid item>
        <Typography>
          {copy.signedOut}
        </Typography>
      </Grid>
      <Grid item className={clsx({
        [hidden]: !isLoading
      })}>
        <CircularProgress />
      </Grid>
      <Grid item className={clsx({
        [hidden]: !isLoading
      })}>
        <Typography>
          {copy.signingIn}
        </Typography>
      </Grid>
      <Grid item id={"styled-auth"}>
        <StyledFirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={firebaseAuth} />
      </Grid>
    </Grid>
  );
};

/**
 * 
 * @param {() => void} closeDialog 
 * @param {import('./Info').InfoDialogMethods} legalDialogMethods 
 * @param {React.Dispatch<import('../../actions').Action>} dispatch 
 * @param {firebase.analytics.Analytics} analytics
 * @return {import('firebaseui').auth.Config}
 */
function getFirebaseUIConfig(closeDialog, legalDialogMethods, dispatch, analytics) {
  return {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // TODO: Enable facebook auth once more infrastructure in place
      // https://developers.facebook.com/apps/229300591538381/fb-login/quickstart/
    ],
    callbacks: {
      signInSuccessWithAuthResult: onSignInSuccess(closeDialog, analytics)
    },
    tosUrl: handleDisplayLegalDialog(
      tosDialogProps,
      legalDialogMethods,
      dispatch,
      handleLogImpression(tosImpressionEventAuth, tosComponentName, analytics)
    ),
    privacyPolicyUrl: handleDisplayLegalDialog(
      privacyDialogProps,
      legalDialogMethods,
      dispatch,
      handleLogImpression(privacyImpressionEventAuth, privacyComponentName, analytics)
    ),
  };
}

