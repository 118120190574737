import matchSort from 'match-sorter';
import { OPEN_DIALOG } from '../../actions';
import { DIALOG_NAMES } from '../../constants';
/**
 * If sort string and array of items, returns new array from matchSort
 * @param {Array<import('../../constants').Item>} items
 * @param {String} sortString
 * @param {Object} options
 * @return {Array<import('../../constants').Item>}
 */
export const searchItems = (items, sortString, options) => {
  if (!items || items.length < 1) {
    return items;
  }
  if (sortString) {
    // @ts-ignore
    return matchSort(items, sortString, options);
  }
  return items;
};

/**
 * 
 * @param {Array<import('../../constants').Item>} items
 * @return {Array<import('../../constants').Item>} new array of sorted items
 */
export const sortItems = (items) => {
  if (!items || items.length < 1) {
    return items;
  }
  let sorted = Array.from(items);
  //@ts-ignore
  return sorted.sort((a, b) => a.name.localeCompare(b.name));
}


/**
 * @typedef {Object} Filter
 * @property {String} property
 * @property {String} value
 */
/**
 * 
 * @param {Array<import('../../constants').Item>} items
 * @param {Array<Filter>} filters
 * @return {Array<import('../../constants').Item>} new array of sorted items
 */
export const filterItems = (items, filters) => {
  if (!items || items.length < 1 || !filters || filters.length < 1) {
    return items;
  }
  
  return items.filter((item) => {
    return itemMatchesFilters(item, filters)
  })
}
/**
 * 
 * @param {import('../../constants').Item} item 
 * @param {Array<Filter>} filters 
 */
export const itemMatchesFilters = (item, filters) => {
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i];
    const { property, value } = filter;
    if (item[property]) {
      return item[property] === value;
    }
  }
  return false;
}

/**
 * 
 * @param {import('./HeadedItemsList').HeadedItemListItemPrimaryAction} action
 * @param {import('../../constants').Item} item
 * @return {{button: boolean, onClick: function}|Object}
 */
export const getPrimaryActionProps = (action, item) => {
  let props = {};
  if (!action) {
    return props;
  }
  const { handlePrimaryAction } = action;
  if (handlePrimaryAction) {
    props =  {
      button: true,
      onClick: handlePrimaryAction(item),
    };
  }
  return props
}
/**
 * @param {string} secondaryTextProp
 * @param {import('../../constants').Item} item
 */
export const getSecondaryText = (secondaryTextProp, item) => {
  if (secondaryTextProp) {
    return item[secondaryTextProp];
  }
  return null;
}

/**
 * 
 * @param {import('../../constants').Item} item
 * @param {React.Dispatch<import('../../actions').Action>} dispatch
 */
export const handleOpenShareDetailsDialog = (item, dispatch) => () => {
  const hasShares = Boolean(item.shares && item.shares.length > 0);
  const hasShare = Boolean(item.share);
  if (!item || (!hasShare && !hasShares)) {
    return;
  }
  /** @type {import('../../reducers/functions').OpenDialogPayload} */
  const payload = { dialog: DIALOG_NAMES.SHARE_DETAILS, props: { item } };
  dispatch({ type: OPEN_DIALOG, payload });
}