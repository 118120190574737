import { createContext } from 'react';
import firebase from 'firebase/app';
/**
 * @typedef {Object} KarachordsContext
 * @property {(payload:import('./actions').Action) => void} dispatch
 * @property {firebase.Unsubscribe} [unsubscribeFromAuthState]
 * @property {import('./App').KarachordsState} state
 * @property {import('./constants').KarachordsViews} views
 * @property {firebase.analytics.Analytics} analytics
 * @property {firebase.remoteConfig.RemoteConfig} remoteConfig
 * @property {firebase} firebase
 */
/** @type {KarachordsContext} */
const initialContext = {
  state: {},
  dispatch: () => {},
  views: {},
  analytics: {},
  remoteConfig: {},
  firebase: {},
}
export const Context = createContext(initialContext);
