import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { CLOSE_DIALOG } from '../../actions';
import { Context } from '../../Context';
import { AuthForm } from './AuthForm';
import firebase from 'firebase/auth';
import get from 'lodash.get';
import { authComponentName, signInConversionEvent } from '../../constants';
import { logAnalyticsEvent } from '../../utils';

/**
 * 
 * @param {() => void} closeDialog
 * @param {firebase.analytics.Analytics} analytics
 * @return {(authResult: any, redirectUrl: String) => boolean}
 */
export const onSignInSuccess = (closeDialog, analytics) => (authResult) => {
  /** @type {firebase.auth.UserCredential} */
  const result = authResult
  const isNewUser = get(result, "additionalUserInfo.isNewUser", false);
  if (isNewUser) {
    logAnalyticsEvent(signInConversionEvent, authComponentName, analytics);
  }
  closeDialog();
  return false;
}


/**
 * 
 * @param {(isLoading:boolean) => void} setLoading 
 * @param {import('react').Dispatch<import('../../actions').Action>} dispatch 
 * @param {import('../../appState').DialogMethods} [methods]
 */
const handleCloseDialog = (setLoading, dispatch, methods) => {
  return async () => {
    setLoading(true);
    if (methods && methods.onClose) {
      await methods.onClose();
    }
    setLoading(false);
    dispatch({ type: CLOSE_DIALOG });
  };
};

/**
 * @typedef {Object} AuthDialogProps
 * @property {firebase} firebase
 * @property {firebase.auth.Auth} firebaseAuth
 * @property {function} closeDialog
 * 
 * @param {AuthDialogProps} props 
 */

export const copy = {
  signedIn: "You are signed in",
  signedOut: "Please sign up or sign in",
  asAnon: "Signing you in as anonymous...",
  signingIn: "Singing you in...",
  button: {
    signOut: "Sign out"
  },
  faq: {
    intro:"Want to know more about Karachords?",
    link:  "Read the FAQ",
  },
  features: {
    intro: "How does it work, and what can it do?",
    link: "Explore features",
  },
  pendingShare: "Someone has shared an item or setlist with you! Please sign in to see it"
}
export const AuthDialog = (props) => {
  const context = useContext(Context)
  const [isLoading, setLoading] = useState(false);
  const { signOutOnClick } = props;
  const { firebase, dispatch, analytics } = context;
  const dialog = context.state.dialog;
  const { methods } = dialog;
  const closeDialog = handleCloseDialog(setLoading, dispatch, methods);
  const firebaseAuth = firebase.auth();
  const formProps = {
    firebaseAuth,
    analytics,
    closeDialog,
    signOutOnClick,
    isLoading,
    dispatch
  }
  return (
    <Grid container direction="column" alignItems="center">
      <AuthForm {...formProps} />
    </Grid>
  );
};
