/* eslint-disable import/namespace */

import * as actions from '../actions';
import * as functions from './functions'

const reducers = Object.entries(actions).reduce((prev, action) => {
  if (functions) {
    const [, value] = action;
    if (typeof value === 'string') {
      const func = functions[value];
      if (func) {
        if (actions.ASYNC_ACTIONS.has(value)) {
          prev[value] = func
        } else {
          prev[value] = func;
        }
        
      }
    }
  }
  return prev;
}, {})

/**
 * 
 * @param {import('../App').KarachordsState} state 
 * @param {actions.Action} action 
 */
export const reducer = (state, action) => {
  const { type, payload } = action;
  const func = reducers[type];
  if (!func) {
    throw new Error('Unsupported action')
  }
  return func(payload, state);
}