import React, { useContext } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { DEFAULT_FONT_SIZE, fontSizes } from "./playConstants";
import { PlayContext } from './PlayContext';

/**
 * @param {(font:import('./PlayContext').Font) => void} setFont
 * @return {(e:import('react').ChangeEvent<{name?: string, value: string}>) => void}
 */
const handleChange = (setFont) => (e) => {
  e.preventDefault();
  const value = e.target.value;
    setFont({fontSize: value});
}
export const FontSizeSelector = () => {
  const { state, methods } = useContext(PlayContext);
  const { font } = state;
  const { fontSize = DEFAULT_FONT_SIZE } = font;
  const { setFont } = methods;
  return (
    <Select value={fontSize} onChange={handleChange(setFont)} disableUnderline={true}>
      {fontSizes.map(({ value, text }, index) => {
        return (
          <MenuItem value={value} key={`font-size-option-${index}`}>
            {text}
          </MenuItem>
        );
      })}
    </Select>
  );
};
