import { Button, CircularProgress, IconButton } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { callMultiple, handleSaveCurrentSongLayers, toggleState } from './utils';


const SaveCurrentSongToolbarButton = (props) => (
  <IconButton 
  disabled={props.isLoading} 
  className={props.controlsButton} 
  // @ts-ignore
  onClick={callMultiple([toggleState(props.isLoading, props.setIsLoading), handleSaveCurrentSongLayers(props.saveCurrentSongParams)])}
  color={"default"}
  >
    <Save />
    {props.isLoading && <CircularProgress color={"secondary"} />}
  </IconButton>
);


/**
 * @typedef {Object} SaveCurrentSongDialogButtonProps
 * @property {String} text
 * @property {(e:React.SyntheticEvent) => void} onClick
 * 
 * @param {SaveCurrentSongDialogButtonProps} props 
 */
export const SaveCurrentSongDialogButton = (props) => {
  const { text, onClick } = props;
  const playContext = useContext(PlayContext);
  const { state } = playContext;
  const { isLoading } = state;
  return (
    <Button color={"primary"}
      disabled={isLoading}
      onClick={onClick}>
      {text}
      {isLoading && <CircularProgress color={"primary"} />}
    </Button>
  )
};

export const SaveCurrentSongButton = () => {
  const { controlsButton } = usePlayStyles();
  const playContext = useContext(PlayContext);
  const { state: playState } = playContext;
  const karachordsContext = useContext(Context);
  const { methods, state } = playContext;
  const { setIsLoading } = methods;
  const { isLoading } = state;
  const { currentSong, currentFile, page, pages, layers } = playState;
  const saveCurrentSongParams = { currentSong, currentFile, page, layers, karachordsContext, pages, playContext };
  return (
    <SaveCurrentSongToolbarButton 
      controlsButton={controlsButton} 
      setIsLoading={setIsLoading} 
      isLoading={isLoading} 
      saveCurrentSongParams={saveCurrentSongParams} 
    />
  );
};
