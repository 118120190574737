import { Grid } from '@material-ui/core';
import React from 'react';
import { editModes } from '../../constants';
import { usePlayStyles } from "./PlayStyles";
import { SaveCurrentSongButton } from './SaveCurrentSongButton';
import { SelectEditModeButton } from './SelectEditModeButton';


export const EditSelectorPanel = () => {
  const { selectEditModeContainer } = usePlayStyles();
  return (
    <Grid container alignItems={"center"} justifyContent={"space-around"} direction={"row"} wrap={"nowrap"} className={selectEditModeContainer}>

      <Grid item container justifyContent={'center'}>
        <SelectEditModeButton editMode={editModes.freehand} />
      </Grid>

      <Grid item container justifyContent={'center'}>
        <SelectEditModeButton editMode={editModes.text} />
      </Grid>

      <Grid item container justifyContent={'center'}>
        <SelectEditModeButton editMode={editModes.symbol} />
      </Grid>
{/* 
      <Grid item container justifyContent={'center'}>
        <LayerSelector />
      </Grid> */}

      <Grid item container justifyContent={'center'}>
        <SaveCurrentSongButton />
      </Grid>

      <Grid item container justifyContent={'center'}>
        <SelectEditModeButton editMode={null} />
      </Grid>

    </Grid>
  );
};
