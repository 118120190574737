import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { handleShowConfirmActionDialog } from '../../dialogs/utils';
import { handleDeleteList } from './utils';
import { copy } from './ListForm';

export const DeleteListMenuItem = (props) => {
  const { context, hidden } = props;
  const { dispatch } = context;
  if (hidden) {
    return null;
  }
  return (
    <MenuItem onClick={handleShowConfirmActionDialog(
      copy.confirm.delete,
      handleDeleteList(context, props.editingList, props.setLoading),
      dispatch)}>
      <ListItemIcon>
        <DeleteForever color={"secondary"} />
      </ListItemIcon>

      <ListItemText>
        {"Delete setlist"}
      </ListItemText>
    </MenuItem>
  );
};
