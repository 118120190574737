import { editModes } from '../../constants';
import { DefaultEditComponent } from './DefaultEditComponent';
import { SketchOverlay } from './SketchOverlay';
import { EditSelectorPanel } from './EditSelectorPanel';
import { EditFreehandPanel } from './EditFreehandPanel';
import { EditTextPanel } from './EditTextPanel';
import { EditSymbolPanel } from './EditSymbolPanel';
import { DraggableTextInput } from './DraggableTextInput';
import { DraggableFreehand } from "./DraggableFreehand";
import { DraggableSymbol } from "./DraggableSymbol";
import { RenderFreehandDrawingDialogActions } from './PlayViewDialog';
export { list as symbols, map as symbolsByName } from './symbols.json';

export const fontSizes = [
  { value: '8px', text: 'S' },
  { value: '16px', text: 'M' },
  { value: '32px', text: 'L' },
  { value: '64px', text: 'XL' },
  { value: '128px', text: 'XXL' },
];

export const DEFAULT_CONTAINER_SIZE = {
  width: 150,
  height: 25,
};
export const STROKE_WIDTHS = [1, 2, 3, 4, 5];

export const STROKE_WIDTHS_OFFSETS = {
  1: 1.5,
  2: 1,
  3: 0.5,
  4: 0,
  5: -0.5,
}
export const editPanelComponents = {
  [editModes.selecting]: EditSelectorPanel,
  [editModes.freehand]: EditFreehandPanel,
  [editModes.text]: EditTextPanel,
  [editModes.symbol]: EditSymbolPanel,
  [editModes.layers]: () => null,
  DefaultEditComponent: () => null,
};
export const editComponents = {
  [editModes.freehand]: SketchOverlay,
  [editModes.text]: DefaultEditComponent,
  [editModes.symbol]: DefaultEditComponent,
  [editModes.selecting]: DefaultEditComponent,
  default: DefaultEditComponent,
};

export const noteType = {
  text: 'TEXT',
  symbol: 'SYMBOL',
  freehand: 'FREEHAND'
};

export const draggableItemsByNote = {
  [noteType.text]: DraggableTextInput,
  [noteType.symbol]: DraggableSymbol,
  [noteType.freehand]: DraggableFreehand
};

export const DEFAULT_FONT_SIZE = '16px';

export const colors = ["black", "red", "yellow", "orange"];

export const DEFAULT_COLOR = colors[0];

export const HTML = 'html';
export const PDF = 'application/pdf';
export const DEFALT_STROKE_COLOR = '#000000'
export const DEFAULT_STROKE_WIDTH = STROKE_WIDTHS[3];
export const DEFAULT_PAGE_HEIGHT = '100%'

export const DEFAULT_FONT = {
  displayName: 'Basic',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  color: DEFAULT_COLOR,
};

export const fonts = [
  DEFAULT_FONT,
  {
    displayName: 'Formal',
    fontFamily: 'TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif',
  },
];


export const fontsMapByFamily = new Map(fonts.map(font => [font.fontFamily, font]));

export const allowedDraggingModes = new Set([editModes.selecting, editModes.text, editModes.symbol, editModes.freehand]);


export const DEFAULT_BORDER_WIDTH = 2;

/**
 * @enum {String}
 */
export const DIALOG_MESSAGES = {
  EXIT: "Are you sure you want to exit? Your unsaved edits will be lost.",
  CHANGE_SONG: "Are you sure you want to change library items? Your unsaved edits will be lost.",
  NEXT_SONG: "Are you sure you want to go to the next item? Your unsaved edits will be lost.",
  PREV_SONG: "Are you sure you want go to the previous item? Your unsaved edits will be lost.",
  EXIT_FREEHAND: "Are you sure you want to stop annotating without saving? Your unsaved drawing will be lost.",
  NEXT_PAGE: "Are you sure you want to go to the next page? Your unsaved edits will be lost.",
  PREV_PAGE: "Are you sure you want to go to the previous page? Your unsaved edits will be lost.",
}
export const DIALOG_BUTTON_TEXT = {
  [DIALOG_MESSAGES.EXIT]: {
    skip: "Close without saving",
    save: "Save & close",
  },
  [DIALOG_MESSAGES.NEXT_SONG]: {
    skip: "Continue without saving",
    save: "Save & continue",
  },
  [DIALOG_MESSAGES.PREV_SONG]: {
    skip: "Continue without saving",
    save: "Save & continue",
  },
  [DIALOG_MESSAGES.EXIT_FREEHAND]: {
    skip: "Discard drawing",
    save: "Save drawing & continue"
  },
  [DIALOG_MESSAGES.NEXT_PAGE]: {
    skip: "Continue without saving",
    save: "Save & continue",
  },
  [DIALOG_MESSAGES.PREV_PAGE]: {
    skip: "Continue without saving",
    save: "Save & continue",
  },
}

/**
 * @enum {React.FunctionComponent}
 */
export const DIALOG_ACTIONS = {
  [DIALOG_MESSAGES.EXIT_FREEHAND]: RenderFreehandDrawingDialogActions
}