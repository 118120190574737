// padding: '5% 5% 0 5%'

import { makeStyles } from "@material-ui/core";
import { getBreakpoints } from "../../../utils/theme";

export const useListFormStyles = makeStyles((theme) => {
  const { mobilePortrait } = getBreakpoints(theme);
  
  return {
    listFormContainer: {
      padding: '5% 5% 0 5%'
    },
    listFormSongSelectorContainer: {
      padding: '0 5% 5% 5%'
    },
    listSongCloseButton: {
      [mobilePortrait]: {
        paddingLeft: 0
      }
    },
    listSongCopyButton: {
      [mobilePortrait]: {
        padding: 0
      }
    },
    hide: {
      display: 'none'
    }
  }
})