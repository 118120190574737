import { SELECT_ITEM, SELECT_VIEW, SET_DATA } from '../../../actions';
import { DATA_TYPES, VIEW_NAMES } from '../../../constants';
import { getPersistanceErrorMessage } from '../../../constants/errors';
import { updateHistoryAfterSave } from '../../../utils';
import { dispatchFetchErrorNotification, dispatchSuccessNotification } from '../../../utils/notfications';
import { deleteFilePagesLayers, deleteOne, getOne, saveSong } from '../../../utils/persistence';
import { SONGS_URL } from '../../../utils/persistence/constants';
import { deleteActiveStateItem } from '../../utils';

/**
 * 
 * @param {import('../../../utils/persistence').KarachordsContext} context
 * @returns {(values:import('./SongForm').SongFormValues, formikObject:Object) => void}
 */
export const handleSongFormSubmit = (context) => async (values, { setSubmitting }) => {
  const { dispatch, state } = context;
  const { name, artist, file, fileID, id } = values;
  const saveSongParams = { name, artist, file, fileID, id, context }
  const resp = await saveSong(saveSongParams);
  const { status } = resp;
  if (status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    return;
  }
  const { data } = resp;
  /** @type {import('../../../reducers/functions').SetDataPayload} */
  const payload = { data, dataType: DATA_TYPES.songs, shouldOverwrite: false, isSilent: true };
  dispatch({ type: SET_DATA, payload });
  setSubmitting(false);
  updateHistoryAfterSave(state);
  dispatch({ type: SELECT_ITEM, payload: { item: data[0] } })
  dispatchSuccessNotification("Library item saved successfully!", dispatch);

}
/**
 * 
 * @param {String} songId
 * @param {String} fileId
 * @param {import('../../../utils/persistence').KarachordsContext} context
 * @return {(e: React.SyntheticEvent) => Promise<void>}
 */
export const handleConfirmClearAnnotations = (songId, fileId, context) => async (e) => {
  e.preventDefault();
  /** @type {import('../../../utils/persistence').DeleteFilePagesLayerRequest} */
  const request = { songId, fileId, context };
  const deleteResp = await deleteFilePagesLayers(request);
  const { status: deleteRespStatus } = deleteResp;
  const { dispatch, firebase } = context;
  if (deleteRespStatus !== 200) {
    dispatchFetchErrorNotification(deleteResp, dispatch);
    return;
  }
  const userToken = await firebase.auth().currentUser.getIdToken();
  const resp = await getOne(SONGS_URL, userToken)(songId);
  if (resp.status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    return;
  }
  const { data: song } = resp;
  /** @type {import('../../../reducers/functions').SetDataPayload} */
  const payload = { data: [song], dataType: DATA_TYPES.songs, isSilent: true }
  dispatch({ type: SET_DATA, payload });
  dispatchSuccessNotification("Successfully cleared all annotations for this library item!", dispatch);
}

/**
 * 
 * @param {import('../../../Context').KarachordsContext} context
 * @param {import('./SongForm').Song} song
 * @param {(value:boolean) => void} setLoading
 * @returns {(event:React.SyntheticEvent) => Promise<void>}
 */
export const handleDeleteSong = (context, song, setLoading) => async (e) => {
  e.preventDefault();
  const { dispatch } = context;
  const { id } = song;
  setLoading(true);
  const user = context.firebase.auth().currentUser;
  let userToken;
  try {
    userToken = await user.getIdToken();
  } catch (err) {
    console.error(err);
    const resp = { status: 400, statusText: 'FORBIDDEN', error: getPersistanceErrorMessage('REAUTH_REQUIRED') };
    dispatchFetchErrorNotification(resp, dispatch);
    return
  }
  const resp = await deleteOne(SONGS_URL, userToken)(id);
  const { status } = resp;
  if (status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    return
  }
  deleteActiveStateItem(dispatch, resp.id, DATA_TYPES.songs, "Successfully deleted library item!");
}

/**
 * @param {import('react').Dispatch<import('../../../actions').Action>} dispatch
 * @return {(e:React.SyntheticEvent) => void}
 */
export const handleSelectLibraryView = (dispatch) => (e) => {
  e.preventDefault();
  /** @type {import('../../../reducers/functions').SelectViewPayload} */
  const payload = { name: VIEW_NAMES.LIBRARY, item: null };
  dispatch({ type: SELECT_VIEW, payload });
}



