import get from 'lodash.get';
import * as constants from './constants';
export * from './cache';
export * from './song';
/**
 * @typedef {Object} FetchResponse
 * @property {Number} status
 * @property {String} statusText
 * @property {String} [error] error enum from functions errors enum
 * @property {String} [message] if the error has an associated message
 * @property {Object} [data] an item or array of items
 * @property {String} [id] if a delete was performed, id of deleted object is returned
 * @property {Number} [max] the new maximum count of this data type that the user can have
 * @property {Number} [maxSize] The max size that this data type can have for this user (used for lists)
 * @param {String} url
 * @param {String} userToken
 * @param {RequestInit} fetchOptions
 * @return {Promise<FetchResponse>} a promise that resolves to a FetchResponse
 */
export const fetch = async (url, userToken, fetchOptions = {}) => {
  const logPrefix = 'fetch() -';
  // @ts-ignore
  const finalOptions = getFinalFetchOptions(fetchOptions, userToken);
  /** @type {Response} */
  // @ts-ignore
  let resp = {};
  try {
    resp = await window.fetch(url, finalOptions);
    const json = await resp.json();
    json.status = resp.status;
    json.statusText = resp.statusText;
    return json;
  } catch (err) {
    console.error(`${logPrefix} error caught during fetch`, err, 'message', err.message);
    const status = resp.status ? resp.status : 500;
    const statusText = resp.statusText ? resp.statusText : 'Internal error';
    const out = { status, statusText, message: err.message };
    return out
  }
}

/**
 * 
 * @param {RequestInit & {headers:Headers|null}} fetchOptions 
 * @param {String} userToken 
 * 
 * @return {RequestInit}
 */
export const getFinalFetchOptions = (fetchOptions, userToken) => {
  const { headers = new Headers() } = fetchOptions
  if (!headers.has(constants.AUTHORIZATION)) {
    headers.append(constants.AUTHORIZATION, `${constants.BEARER} ${userToken}`);
  }
  const finalOptions = Object.assign(fetchOptions, {
    credentials: 'include',
    headers
  });
  return finalOptions;
}

/**
 * 
 * @param {String} baseUrl
 * @param {String} [baseId]
 * @param {PathIds} [pathIds] path -> id
 * @returns {String} 
 */
export const getUrl = (baseUrl, baseId, pathIds) => {
  const pathArr = [baseUrl];
  if (baseId) {
    pathArr.push(baseId);
  }
  if (pathIds) {
    pathIds.forEach((id, name) => {
      pathArr.push(name);
      if (id) {
        pathArr.push(id);
      }
    })
  }
  const url = pathArr.join(constants.URL_PATH_SEPERATOR);
  return url;
}

/**
 * 
 * @param {String} id
 * @param {Array<KarachordsUploadFile>|undefined} [files]
 * @return {String} HTTP requst method, either 'PUT' or 'POST' depending on if ID is sent or not
 */
export const getMethod = (id, files) => {
  // if files, post
  // if id & files, post
  // if id and no files, put
  if (id && !files) {
    return 'PUT'
  }
  return 'POST';
}
/**
 * @param {SaveOneRequest} request
 * @param {Object} data
 * @return {Headers}
 */
export const getHeaders = (request, data = {}) => {
  /** @type {Headers} */
  const headers = get(request, 'options.headers', new Headers());
  const { userToken } = request;
  headers.append(constants.AUTHORIZATION, `${constants.BEARER} ${userToken}`);
  addContentType(data, headers);
  return headers;
}
/**
 * 
 * @param {Object} data 
 * @param {Headers} headers 
 */
function addContentType(data, headers) {
  let contentType = getContentType(data);
  if (contentType === constants.contentTypes.json) {
    headers.append(constants.CONTENT_TYPE, contentType);
  }
}

/**
 * 
 * @param {Object} data 
 */
function getContentType(data = {}) {
  if (data.files) {
    // currently, files is the only time to do FormData and the only way to tell if we should
    return constants.contentTypes.formData;
  }
  return constants.contentTypes.json;
}

/**
 * @param {Object} data 
 * @return {FormData|String}
 */
export function getRequestBody(data) {
  if (data.files) {
    // must send multipart/form-data
    return getFormData(data);
  }
  return JSON.stringify(data)

}

/**
 * @typedef {Object} FirebaseRequest
 * @prop {String} url
 * @prop {RequestInit} options
 * 
 * @param {SaveOneRequest} request 
 * @param {Object} data
 * 
 * @returns {FirebaseRequest}
 */
export const constructFirebaseRequest = (request, data) => {
  const { options: saveOneRequestOptions, url } = request;
  const headers = getHeaders(request, data);
  /** @type {RequestInit} */
  const reqOptions = Object.assign({}, saveOneRequestOptions, { headers, body: getRequestBody(data) });
  const req = { url, options: reqOptions };
  return req;
}
/**
 * @exports KarachordsUploadFile
 * @typedef {Object} KarachordsUploadFile
 * @property {File} File
 * @property {String} [id]
 * @property {String} type
 */
/**
 * @typedef {Object} Payload
 * @property {Array<KarachordsUploadFile>} [files]
 * @property {String} [name]
 * @property {String} [artist]
 * @property {String} [tonality]
 * @property {String} [id]
 * @property {String} [url]
 * 
 * @param {Payload} payload 
 * @returns {FormData}
 */
const getFormData = (payload) => {
  const finalPayload = new FormData();
  const { files } = payload;

  Object.entries(payload).forEach(([key, value]) => {
    if (key !== constants.FILES_KEY) {
      //@ts-ignore
      finalPayload.append(key, value);
    }
  })
  if (files) {
    files.forEach((file, index) => {
      const { File, id, type } = file;
      if (id) {
        finalPayload.append(`${constants.FILE_ID_PREFIX}-${index}`, id);
      }
      finalPayload.append(`${constants.FILE_TYPE_PREFIX}-${index}`, type);
      finalPayload.append(`${constants.FILE_PREFIX}-${index}`, File);
    })
  }
  return finalPayload;
}
/**
 * @typedef SendPayloadOptions
 * @property {Headers} [headers]
 * 
 * @param {FirebaseRequest} request 
 */
export const sendPayload = async (request) => {
  const { url, options } = request;
  const resp = await fetch(url, null, options)
  return resp;
}

/**
 * @typedef {Object} SaveOneRequest
 * @property {String} url
 * @property {String} userToken
 * @property {SaveOneRequestOptions} options
 * 
 * @typedef {Object} SaveOneRequestOptions
 * @property {String} method GET, POST, PUT, or DELETE
 * @property {Headers} [headers]
 * 
 * @typedef {Map<String, String>} PathIds
 * 
 * @param {SaveOneRequest} request
 * @param {(resp:FetchResponse) => void} [handle]
 * @returns {(data?:Object) => Promise<FetchResponse>}
 */
export const saveOne = (request, handle) => async (data) => {
  const req = constructFirebaseRequest(request, data);
  const resp = await sendPayload(req);
  if (handle) {
    handle(resp)
  }
  return resp;
}

/**
 * 
 * @param {string} baseURL 
 * @param {string} userToken 
 * @param {(resp:FetchResponse) => void} [handle]
 * @returns {(id:string) => Promise<FetchResponse>}
 */
export const getOne = (baseURL, userToken, handle) => async (id) => {
  const url = `${baseURL}/${id}`;
  const resp = await fetch(url, userToken);
  if (handle) {
    handle(resp);
  }
  return resp;
}
export const getRandomSong = async () => {
  // const URL = '/api/songs/'
  // const resp = await this.fetchData(URL);
  // return resp;
}

/**
 * 
 * @param {String} url 
 * @param {String} userToken 
 * @param {(resp:FetchResponse) => void} [handle]
 */
export const getAll = async (url, userToken, handle) => {
  const resp = await fetch(url, userToken);
  if (handle) {
    handle(resp);
  }
  return resp;
}

/**
 * 
 * @param {String} baseURL 
 * @param {String} userToken 
 * @param {(resp:FetchResponse) => void} [handle]
 * @returns {(id:String) => Promise<FetchResponse>}
 */
export const deleteOne = (baseURL, userToken, handle) => async (id) => {
  const url = `${baseURL}/${id}`;
  const options = {
    method: 'DELETE'
  }
  const resp = await fetch(url, userToken, options);
  if (handle) {
    handle(resp);
  }
  return resp;
}

/**
 * @typedef {SharedDetails & SharedAssignableRoles & SharedSongs} GetShareData
 * @typedef {{roles: Array<import('../../reducers/functions').AssignableRole>}} SharedAssignableRoles
 * @typedef {{songs?: Array<{id: String}>}} SharedSongs
 * 
 * @typedef {FetchResponse & {data?: GetShareData}} GetShareResponse
 * @param {String} userToken
 * @param {String} type 
 * @param {String} itemId 
 * @param {String} [roleId]
 * @return {Promise<GetShareResponse>}
 */
export const createShare = async (userToken, type, itemId, roleId) => {
  let url = `${constants.SHARE_URL}`;
  const data = { type, id: itemId };
  if (roleId) {
    Object.assign(data, { roleId });
  }
  /** @type {SaveOneRequestOptions} */
  const options = { method: 'POST' }
  /** @type {SaveOneRequest} */
  const request = { url, userToken, options }
  const resp = await saveOne(request)(data);
  return resp;
}

/**
 * 
 * @param {String} userToken
 * @param {String} id id to revoke - this is a share Id except when type param is remove, then it is an item id
 * @param {URLSearchParams} [params] 
 * @returns {Promise<FetchResponse>}
 */
 export const revokeShare = async (userToken, id, params = new URLSearchParams()) => {
  const url = `${constants.SHARE_URL}/${id}?${params.toString()}`;
  const options = {
    method: 'DELETE'
  }
  const resp = await fetch(url, userToken, options);
  return resp;
}