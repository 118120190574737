import { Container, Grid, MenuItem, Select } from '@material-ui/core';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { selectors } from './EditFreehandPanel';
import { STROKE_WIDTHS } from './playConstants';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";

const renderSelectItems = () => {
  return STROKE_WIDTHS.map((value) => {
    return (
      <MenuItem key={`select-width-${value}`} value={value}>
        <span>
          {value}
        </span>
      </MenuItem>
    )
  })

}
/**
 * 
 * @param {(width:Number) => void} setStrokeWidth
 * @param {() => void} afterPick
 */
const setWidth = (setStrokeWidth, afterPick) => (e) => {
  const width = get(e, 'target.value', null);
  if (width) {
    setStrokeWidth(width)
  }
  afterPick();
}

export const PenPicker = (props) => {
  const { openSelector, afterPick } = props;
  const { editPanelPickerContainer, editPanelPickerGrid, editPanelPickerSelect } = usePlayStyles();
  const context = useContext(PlayContext);
  const { state, methods } = context;
  const { strokeWidth } = state;
  const { setStrokeWidth } = methods;
  if (openSelector !== selectors.width) {
    return null;
  }
  return (
    <Container className={editPanelPickerContainer} disableGutters={true}>
      <Grid className={editPanelPickerGrid} container direction={"column"} justifyContent={"center"} alignItems={"center"}>
        <Select value={strokeWidth} className={editPanelPickerSelect} onChange={setWidth(setStrokeWidth, afterPick)} color="secondary" disableUnderline={true}>
          {renderSelectItems()}
        </Select>
      </Grid>
    </Container>
  );
};
