import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { PlayContext } from './PlayContext';
import { handleInterruptableStateChange } from './utils';
import { editModes } from '../../constants';
import { DIALOG_MESSAGES } from './playConstants';




export const LeaveFreehandEditButton = () => {
  const { state, methods } = useContext(PlayContext);
  const { wasEdited } = state;
  const { setDialogMessage, setEditMode } = methods;
  const changeState = () => {
    setEditMode(editModes.selecting);
  };
  return (
    <IconButton
      onClick={handleInterruptableStateChange(changeState, DIALOG_MESSAGES.EXIT_FREEHAND, setDialogMessage, wasEdited)(false)}
    >
      <ArrowBack fontSize={"large"} />
    </IconButton>
  );
};
