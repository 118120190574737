import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from 'react';
import { useAppStyles } from "../../appStyles";
import { ITEMS_ON_LIST_REVOKE_COPY, ROLE_COPY, SHARE_TYPES } from "../../constants";
import { RevokeShareButton } from "../buttons/RevokeShare";
import { getRoleCopy, getYouSharedAtCopy } from "../utils";
import { useCardStyles } from "./styles";


/**
 * @typedef {Object} SharedWithOthersDetailsProps
 * @property {SharedDetails} share
 * @property {import("../../constants").Item} item
 * @property {() => void} [afterClose]
 *
 * @param {SharedWithOthersDetailsProps} props
 */
export const SharedWithOthersDetails = (props) => {
  const { gutters } = useCardStyles();
  const { textCenter, hide: hidden, spacingBottom, fullWidth } = useAppStyles();
  const { share, item, afterClose } = props;
  const { role: roleName } = share;
  const copy = getRoleCopy(roleName, ROLE_COPY);
  const isList = item.songs && item.songs.length > 0 ? true : false;
  const disableRevoke = !isList && share.type === SHARE_TYPES.list;
  return (
    <Paper elevation={3} className={clsx(gutters, spacingBottom, fullWidth)}>
      <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
        <Grid item>
          <Typography variant={"h6"}>
            {`Shared Role: ${copy.display}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"caption"}>
            {getYouSharedAtCopy(share)}
          </Typography>
        </Grid>
        <Grid item className={textCenter}>
          <Typography variant={"body2"}>
            {copy.description}
          </Typography>
        </Grid>
        <Grid item>
          <RevokeShareButton
            {...props}
            disabled={disableRevoke}
            afterClose={afterClose}
          />
        </Grid>
        <Grid className={clsx(textCenter, { [hidden]: !disableRevoke })} item>
          <Typography variant={"caption"}>
            {ITEMS_ON_LIST_REVOKE_COPY}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};



