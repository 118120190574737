import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext, useEffect, useRef } from 'react';
import { ReactSketchCanvas } from "react-sketch-canvas";
import { editModes } from '../../constants';
import { debounce } from '../../utils';
import { DEFAULT_BORDER_WIDTH, noteType } from './playConstants';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { getPathString } from './svgUtils';
import {
  addNote,

  getDeltaFromSortedPaths, getFinalStrokeColor,
  getFinalStrokeWidth,
  isEditComponentActive,


  scaleToPage, unscaleFromPage
} from './utils';

/**
 * @param {SketchOverlayProps} prevProps
 * @param {SketchOverlayProps} nextProps
 * 
*/
const propsAreEqual = (prevProps, nextProps) => {
  const propsToCheck = new Set(["font", "strokeWidth"]);
  Object.entries(prevProps).forEach(([key, value]) => {
    if (propsToCheck.has(key)) {
      if (value !== nextProps[key]) {

        return false
      }
    }
  });
  return true;
}

/**
 * 
 * @param {String} editMode
 * @return {'all' | 'none'}
 */
export const getAllowedPointerType = (editMode) => {
  if (editMode === editModes.freehand) {
    return "all";
  }
  return "none"
}

const getExtraOffset = (strokeWidth, pageScale) => {
  if (pageScale >= 2) {
    return 0
  }
  if (pageScale >= 1) {
    return strokeWidth / 4;
  }
  return strokeWidth
}
/**
 * 
 * @param {Number} strokeWidth 
 * @param {Number} pageScale
 */
const getPositionOffset = (strokeWidth, pageScale) => {
  const base = strokeWidth - 4;
  const halved = base / 2;
  const inverted = -(halved) - (getExtraOffset(strokeWidth, pageScale));
  return inverted;
}
/**
 * @param {Array<import('react-sketch-canvas').CanvasPath>} canvasPaths
 * @param {Number} pageScale
 * @param {Number} strokeWidth
 * @return {import('./utils').Note}
 */
const getPartialNoteFromCanvasPaths = (canvasPaths, pageScale, strokeWidth) => {
  /** @type {Array<import('react-sketch-canvas').Point>} */
  const combinedPaths = canvasPaths.reduce((acc, { paths }) => {
    if (paths.length < 2) {
      return acc
    }
    return acc.concat(paths);
  }, []);
  if (combinedPaths.length < 2) {
    return null
  }
  // TODO: Naive solution here - improve big O time here later
  const byXDescending = combinedPaths.slice().sort(({ x: prevX }, { x: nextX }) => nextX - prevX);
  const byYDescending = combinedPaths.slice().sort(({ y: prevY }, { y: nextY }) => nextY - prevY);
  const smallestY = byYDescending[byYDescending.length - 1].y;
  const smallestX = byXDescending[byXDescending.length - 1].x;
  const height = getDeltaFromSortedPaths(byYDescending, 'y', pageScale, strokeWidth);
  const width = getDeltaFromSortedPaths(byXDescending, 'x', pageScale, strokeWidth);
  const size = { height, width };
  const offset = getPositionOffset(strokeWidth, pageScale)

  const top = unscaleFromPage(smallestY, pageScale) - DEFAULT_BORDER_WIDTH + offset;
  const left = unscaleFromPage(smallestX, pageScale) - DEFAULT_BORDER_WIDTH + offset;
  const paths = canvasPaths.map((path) => {
    const { paths } = path;
    return paths.map(pointArr => {
      /** @type {import('react-sketch-canvas').Point}*/
      const sizedDown = { x: pointArr.x - smallestX + strokeWidth, y: pointArr.y - smallestY + strokeWidth }
      return sizedDown
    })
  });
  const pathsArr = paths.map(path => {
    const d = getPathString(path);
    return { d }
  })
  const viewBox = getViewBoxFromSize(size, pageScale);
  const symbol = { paths: pathsArr, viewBox, strokeWidth }
  const note = { x: left, y: top, size, symbol };
  return note;
}

/**
 * @typedef {Object} HandleDrawCompletionParams
 * @property {React.RefObject<ReactSketchCanvas>} ref 
 * @property {Number} pageScale
 * @property {import('./utils').Font} font
 * @property {number} strokeWidth
 * @property {Array<import('./utils').Note>} notes
 * @property {(notes: Array<import('./utils').Note>) => void} setNotes
 * @property {(note:import('./utils').Note) => void} setActiveNote
 * @property {(editMode:String) => void} setEditMode
 * 
 * @param {HandleDrawCompletionParams} params
 * @returns {(e?: React.SyntheticEvent) => Promise<void>}
 */
export const handleDrawCompletion = (params) => async () => {
  const { ref, pageScale, font, strokeWidth, notes, setNotes, setActiveNote, setEditMode } = params;
  const paths = await ref.current.exportPaths();
  const isDrawing = get(ref, 'current.state.isDrawing');
  if (!paths[0] || isDrawing) {
    return;
  }
  const partialNote = getPartialNoteFromCanvasPaths(paths, pageScale, strokeWidth);
  if (!partialNote) {
    return
  }

  const { symbol } = partialNote;

  const note = Object.assign({}, partialNote, { font, symbol, type: noteType.freehand })
  const addedNote = addNote(note, notes, setNotes);
  setEditMode(editModes.selecting);
  setActiveNote(addedNote);
  ref.current.clearCanvas();
}

/**
 * @typedef {Object} SketchOverlayProps
 * @property {String} strokeColor
 * @property {number} strokeWidth
 * @property {(ref:React.RefObject) => void} setFreehandEditRef
 */
export const SketchOverlayComponent = () => {
  const context = useContext(PlayContext);
  const { state, methods } = context;
  const { strokeWidth, isErasing, editMode, font, pageHeight } = state;
  const { setFreehandEditRef, setWasEdited } = methods;
  const { editComponent, activeEditingComponent } = usePlayStyles();
  const ref = useRef();
  useEffect(() => {
    setFreehandEditRef(ref);
  }, [])
  const { color } = font;
  const finalStrokeWidth = getFinalStrokeWidth(strokeWidth, isErasing);
  const finalStrokeColor = getFinalStrokeColor(color, isErasing);
  const allowedPointerType = getAllowedPointerType(editMode);
  const isActive = isEditComponentActive(editMode, editModes.freehand)
  const style = {};
  if (pageHeight) {
    Object.assign(style, {height: pageHeight})
  }
  return (
    <ReactSketchCanvas
      key={'sketch-overlay'}
      ref={ref}
      strokeWidth={finalStrokeWidth}
      strokeColor={finalStrokeColor}
      style={style}
      className={clsx(editComponent, { [activeEditingComponent]: isActive })}
      canvasColor={"transparent"}
      allowOnlyPointerType={allowedPointerType}
      onUpdate={debounce(() => {
        if (isActive) {
          setWasEdited(true);
        }
      }, 250)}
    />
  );
};

export const SketchOverlay = React.memo(SketchOverlayComponent, propsAreEqual)

/**
 * 
 * @param {{width:Number, height: Number}} size 
 * @param {Number} pageScale 
 * @return {String}
 */
function getViewBoxFromSize(size, pageScale) {
  const { width: sizeWidth, height: sizeHeight } = size;
  const scaledWidth = scaleToPage(sizeWidth, pageScale);
  const scaledHeight = scaleToPage(sizeHeight, pageScale)
  return `0 0 ${scaledWidth} ${scaledHeight}`;
}

