import { DialogContent, Grid, Typography } from "@material-ui/core";
import get from "lodash.get";
import React, { useContext } from 'react';
import { useAppStyles } from "../../appStyles";
import { DATA_TYPES, STATE_DATA_PATHS } from "../../constants";
import { Context } from "../../Context";
import { SharedWithMeDetails } from "../cards/SharedWithMeDetails";
import { handleOpenShareDetailsDialog } from "../lists/utils";
import { SharedWithOthersDetails } from "./../cards/SharedWithOthersDetails";
import { DialogHeader } from "./DialogHeader";
import { SharesSummary } from "./SharesSummary";

/**
 * 
 * @param {import("../../constants").Item} item 
 * @param {SharedDetails} share 
 * @param {import("../../Context").KarachordsContext} context 
 */
const handleAfterClose = (item, share, context) => () => {
  const { state, dispatch } = context;
  const { id, songs } = item;
  const isList = Boolean(songs && songs.length);
  const dataType = isList ? DATA_TYPES.lists : DATA_TYPES.songs;
  const mapPath = STATE_DATA_PATHS.get(dataType).map;
  const map = get(state, mapPath);
  const updatedItem = map.get(id);
  handleOpenShareDetailsDialog(updatedItem, dispatch)();
}

export const ShareDetailsDialog = () => {
  const context = useContext(Context);
  const { state, dispatch } = context;
  const { italic } = useAppStyles();
  /** @type {import("../../appState").DialogProps} */
  const props = get(state, 'dialog.props', {});
  const { item } = props
  const { share, shares = [], name } = item;
  const isList = item.songs && item.songs.length ? true : false;
  return (
    <Grid item container direction={"column"}>
      <DialogHeader title={"Share Details"} dispatch={dispatch} />
      <DialogContent>
        <Grid spacing={2} container alignItems={"center"} direction={"column"} justifyContent={"space-between"}>
          <Grid item className={italic}>
            <Typography variant={"h5"}>
              {`${name}`}
            </Typography>
          </Grid>
          <SharedWithMeDetails share={share} isList={isList} />
          <SharesSummary shares={shares} />
          {shares.map((share) => {
            const { role } = share;
            return (
              <React.Fragment key={`${item.id}-share-${share.type}-${role}`}>
                <SharedWithOthersDetails item={item} share={share} afterClose={handleAfterClose(item, share, context)} />
              </React.Fragment>
            )
          })}
        </Grid>
      </DialogContent>
    </Grid>
  )
}