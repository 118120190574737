import React from 'react';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { usePlayStyles } from './PlayStyles';

/**
 *
 * @param {import('notistack').VariantType} variant
 * @return {import('@material-ui/lab').Color}
 */
const getSeverity = (variant) => {
  if (!variant || variant === 'default') {
    return 'info';
  }
  return variant;
};

/**
 * @param {React.Dispatch<import('../../reducers/functions').Notification>} setNotification
 * @return {(e:React.SyntheticEvent, reason:import('@material-ui/core').SnackbarCloseReason) => void}
 */
const handleClose = (setNotification) => (e, reason) => {
  if (reason === 'clickaway') {
    return null
  }
  setNotification(null);
}

const PlaySnackbarAction = (props) => {
  const { onClose } = props;
  return (
    <Button onClick={onClose}>
      {"Dismiss"}
    </Button>
  )
}

/**
 * @typedef {Object} PlayNotificationProps
 * @property {import('./../../reducers/functions').Notification} notification
 * @property {React.Dispatch<import('./../../reducers/functions').Notification>} setNotification
 *
 * @param {PlayNotificationProps} props
 */
export const PlayNotification = (props) => {
  const { playNotification, playNotificationAlert } = usePlayStyles();
  const { setNotification } = props;
  let { notification } = props;
  if (!notification) {
    notification = { message: null, options: {} };
  }
  const { message, options = {} } = notification;
  const { variant } = options;
  return (
    <Snackbar
      open={Boolean(message)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      onClose={handleClose(setNotification)}
      className={playNotification}
      autoHideDuration={5000}
    >
      <React.Fragment>
        <Alert severity={getSeverity(variant)} className={playNotificationAlert}>
          <Grid item container direction={"row"} alignItems={"center"} justifyContent={"center"} >
            {message}
            <PlaySnackbarAction onClose={handleClose(setNotification)} />
          </Grid>
        </Alert>

      </React.Fragment>

    </Snackbar>
  );
};
