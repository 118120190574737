export const ID = 'id';

export const AUTHORIZATION = 'Authorization';

export const BEARER = 'Bearer';

export const FILES_KEY = 'files';

export const FILE_ID_PREFIX = 'file-id';

export const FILE_TYPE_PREFIX = 'file-type';

export const FILE_PREFIX = 'file';
/**
 * @returns {String} prefix for url based on environment
 */
const getURLPrefix = () => {
  return process.env.REACT_APP_FUNCTIONS_URL
}

const urlPrefix = getURLPrefix();

export const SONGS_URL = `${urlPrefix}/api/songs`;

export const LISTS_URL = `${urlPrefix}/api/lists`;

export const SHARE_URL = `${urlPrefix}/api/share`;

export const SHARED_URL = `${urlPrefix}/api/shared`;

export const SHARE_REDEEM_URL = `${SHARED_URL}/redeem`;

export const URL_PATH_SEPERATOR = '/';

export const METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE'
}

export const CONTENT_TYPE = 'Content-Type';

export const contentTypes = {
  formData: 'multipart/form-data',
  json: 'application/json'
}