import React, { useContext } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { PlayContext } from './PlayContext';
import { fontsMapByFamily, fonts } from "./playConstants";
/**
 * @param {(font:import('./utils').Font) => void} setFont
 * @return {(e: import('react').ChangeEvent<{value:string, name?:string}>) => void}
 */
const handleChange = (setFont) => (e) => {
  e.preventDefault();
  const fontFamily = e.target.value;
  const { displayName } = fontsMapByFamily.get(fontFamily);
  setFont({ displayName, fontFamily });
};

export const FontSelector = () => {
  const { methods, state } = useContext(PlayContext);
  const { font } = state;
  const { setFont } = methods;

  const { fontFamily } = font;
  return (
    <Select value={fontFamily} onChange={handleChange(setFont)} style={{fontFamily}} disableUnderline={true}>
      {fonts.map((font, index) => {
        const { displayName, fontFamily } = font;
        return (
          <MenuItem value={fontFamily} key={`font-option-${index}`} style={{fontFamily}}>
            {displayName}
          </MenuItem>
        );
      })}
    </Select>
  );
};
