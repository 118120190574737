import { SELECT_ITEM, SET_FILTERS } from '.';


/**
 *
 * @param {import('../Context').KarachordsContext} context
 */
export const newItem = (context) => () => {
  const { dispatch } = context;
  const payload = { item: Object.assign({}, {}) };
  dispatch({ type: SELECT_ITEM, payload });
};

/**
 * 
 * @param {React.Dispatch<import('.').Action>} dispatch 
 * @param {String} filtersPath
 * @return {(filters:Array<import('../components/lists/utils').Filter>) => void} 
 */
export const getSetFilters = (dispatch, filtersPath) => (filters) => {
  /** @type {import('../reducers/functions').SetFiltersPayload} */
  const payload = { filters, path: filtersPath }
  dispatch({ type: SET_FILTERS, payload })
}
