import { Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext, useState } from 'react';
import { getSetFilters } from '../../../actions/utils';
import { useAppStyles } from '../../../appStyles';
import * as constants from '../../../constants';
import { Context } from '../../../Context';
import { DonateAd } from '../../ads';
import { HeadedItemsList } from '../../lists';
import { ListForm } from './ListForm';
import { useListFormStyles } from './styles';
import {
  getHandleAddSongtoList, getHandleRemoveSongFromlist, handleDeleteList,
  handleListFormSubmit, handleOnDragEnd
} from './utils';
/**
* @typedef {Object} List
* @property {String} [name]
* @property {String} [id]
* @property {Array<import('../song/SongForm').Song>} [songs]
*/
export const MUIListForm = (props) => {
  /** @type {import('../../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { state, dispatch } = context;
  /** @type {List & import('../../../reducers/functions').ItemShares} */
  const list = get(state, 'main.item', null);
  const songsMap = get(state, 'songs.map', new Map());
  /** @type {Array<import('../../drawers/utils').Song>} */
  const editingListSongs = get(list, 'songs', []);
  const initialSongs = editingListSongs.map(song => {
    const { id } = song;
    const fullSong = songsMap.get(id);
    if (!fullSong) {
      return null;
    }
    const { name, artist } = fullSong;
    return { id, name, artist };
  });
  const filteredInitialSongs = initialSongs.filter((song) => song)
  /** @type {import('../../../reducers/functions').Item} */
  const initialEditingList = Object.assign({}, list, {songs: filteredInitialSongs});
  const [editingList, setEditingList] = useState(initialEditingList);
  const { songs } = editingList;
  const [isLoading, setLoading] = useState(false);
  const { listFormContainer, listFormSongSelectorContainer } = useListFormStyles();
  const { hide } = useAppStyles();
  // useEffect(() => {
  //   setEditingList(initialEditingList);
  // }, [editingList])
  if (!list || !editingList) {
    return null;
  }
  
  const handleDragEnd = handleOnDragEnd(editingList, setEditingList);
  const handleRemoveSongFromList = getHandleRemoveSongFromlist(editingList, setEditingList);
  const handleAddSongToList = getHandleAddSongtoList(editingList, setEditingList)
  const subMenu = constants.SUB_MENUS.map.get(constants.SUB_MENU_NAMES.SONGS);
  const filtersPath = constants.filtersPaths.MAIN;
  const maxSize = state?.lists?.maxSize
  /** @type {import('../../lists/HeadedItemsList').HeadedItemsListProps} */
  const headedItemsListProps = {
    filterDialog: constants.DIALOGS.map.get(constants.DIALOG_NAMES.SONG_FILTERS),
    secondaryTextProp: subMenu.secondaryTextProp,
    searchOptions: subMenu.searchOptions,
    header: subMenu.header,
    items: context.state.songs.list,
    secondaryAction: {
      handleSecondaryAction: handleAddSongToList,
      Icon: AddCircle,
      text: "Add"
    },
    primaryAction: {
      handlePrimaryAction: handleAddSongToList,
    },
    setFilters: getSetFilters(dispatch, filtersPath),
    filtersPath,
    placeholder: "You don't have any items in your library. Try adding one.",
    disabled: editingList?.songs?.length >= maxSize,
  }

  const role = get(editingList, 'share.role', constants.ROLES.owner);
  const canEdit = constants.EDIT_ROLES.has(role);
  return (
    <React.Fragment>
      <Grid container item className={listFormContainer} direction={'column'} justifyContent={'flex-start'} alignItems={'center'}>
        <ListForm
          {...props}
          key={`list-name-form-${editingList.id}`}
          isLoading={isLoading}
          editingList={editingList}
          onSubmit={handleListFormSubmit(editingList, context, setLoading)}
          deleteList={handleDeleteList(context, editingList, setLoading)}
          handleDragEnd={handleDragEnd}
          songs={songs}
          handleRemove={handleRemoveSongFromList}
          handleAdd={handleAddSongToList}
          setLoading={setLoading}
          setEditingList={setEditingList}
        />
      </Grid>
      <Grid item container className={clsx(listFormSongSelectorContainer, { [hide]: !canEdit })}>
        <HeadedItemsList {...headedItemsListProps} />
      </Grid>
    </React.Fragment>
  );
}

export const ListFormView = (props) => {
  const { state } = useContext(Context);
  const mainItem = state.main.item;
  const { hide } = useListFormStyles();
  return (
    <Grid container direction={'column'} alignItems={'center'} className={clsx({
      [hide]: !mainItem
    })}>
      <DonateAd id={constants.adIDs.listFormDonate} />
      <MUIListForm {...props} />
    </Grid>
  )
}
