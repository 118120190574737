import clsx from 'clsx';
import React from 'react';
// @ts-ignore
import { Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { songPageStyles } from './PDFSong';
import { handlePageLoad } from './SongDoc';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

/**
 * @typedef {Object} PagesProps
 * @property {Number} page currently selected page
 * @property {Number} pages count of pages on the file
 * @property {React.RefObject} pageRef
 * @property {Number} width width of file pages
 * 
 * @property {React.RefObject} canvasRef
 * @property {(pageScale: Number) => void} setPageScale
 * @property {(height: String) => void} setPageHeight
 * @property {(pageProxy:import('react-pdf').pdfjs.PDFPageProxy) => void} setPageProxy
 * @property {String} fileId
 * @property {import('react-pdf').pdfjs.PDFDocumentProxy} pdfProxy
 * 
 * @param {PagesProps} props
 */

export const renderPages = (props, classes) => {
  const { pages, width, pageRef, page: currentPage, canvasRef, setPageScale, setPageHeight, setPageProxy, fileId, pdfProxy } = props;
  const { cachedPage } = classes;
  const renderedPages = [];
  for (let i = 1; i < pages + 1; i++) {
    const isCurrentPage = i === currentPage;
    const page = (
      <Page
        key={`file-${fileId}-page-${i}`}
        pageNumber={i}
        width={width}
        ref={isCurrentPage ? pageRef : null}
        // @ts-ignore because it's in the code and not in the types lol
        canvasRef={isCurrentPage ? canvasRef : null}
        // inputRef={canvasRef}
        onLoadSuccess={isCurrentPage ? handlePageLoad(setPageScale, setPageHeight, setPageProxy) : () => null}
        renderAnnotationLayer={false}
        renderMode={'canvas'}
        pdf={pdfProxy}
        className={clsx({
          [cachedPage]: !isCurrentPage
        })}
        // TODO: FIX ME - want to render hidden text as not a thing, but should still render text layers
        // however, current default behavior of react-pdf would be to render a transparent text layer
        // so individual text items in customTextRender would need to be displayed - not a great API
        renderTextLayer={false}
        />
    );
    renderedPages.push(page);
  }
  return renderedPages;
};


/** @param {PagesProps} props */
export const Pages = (props) => {
  const classes = songPageStyles();
  return (
    <React.Fragment>
      {renderPages(props, classes)}
    </React.Fragment>
  );
};
