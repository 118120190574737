import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack, Group, MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { useAppStyles } from '../../../appStyles';
import { UpgradeAccountCounter } from '../../counters';
import { handleOpenShareDetailsDialog } from '../../lists/utils';
import { ListFormHeaderMenu } from './ListFormHeaderMenu';
import { handleOpenMenu, handleSelectSetlistView } from './utils';

/**
 * @typedef {Object} ListFormHeaderProps
 * @property {Boolean} areButtonsDisabled
 * @property {*} menuAnchorEl
 * @property {*} setMenuAnchorEl
 * @property {*} editingList
 * @property {*} setEditingList
 * @property {*} setLoading
 * @property {*} context
 * @property {*} dispatch
 * @property {String} title
 * @property {Number} maxSize
 * 
 * @param {ListFormHeaderProps} props 
 */
export const ListFormHeader = (props) => {
  const {
    menuAnchorEl,
    setMenuAnchorEl,
    editingList,
    setEditingList,
    setLoading,
    context,
    dispatch,
    title,
    maxSize
  } = props;
  const { autoWidth, noWrap } = useAppStyles();
  const { share, shares, id, songs = [] } = editingList;
  const hasShares = share || (shares && shares.length > 0);
  return (
    <Grid container item justifyContent={"space-between"} alignItems={"center"}>
      <Grid item>
        <IconButton onClick={handleSelectSetlistView(dispatch)}>
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant={'h5'}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <UpgradeAccountCounter count={songs.length} maxItems={maxSize} />
      </Grid>
      <Grid item container direction={"row"} alignItems={"center"} justifyContent={"flex-end"} className={clsx(autoWidth, noWrap)}>
        {hasShares && (
          <Grid item>
            <IconButton onClick={handleOpenShareDetailsDialog(editingList, dispatch)}>
              <Group />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <IconButton disabled={!id} onClick={handleOpenMenu(setMenuAnchorEl)}>
            <MoreVert />
          </IconButton>
        </Grid>
        <ListFormHeaderMenu
          editingList={editingList}
          setLoading={setLoading}
          setEditingList={setEditingList}
          context={context}
          dispatch={dispatch}
          menuAnchorEl={menuAnchorEl}
          setMenuAnchorEl={setMenuAnchorEl}
        />
      </Grid>
    </Grid>
  )
};
