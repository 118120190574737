import { exists, hasLength, hasSize, isLessThanMegabytes, isSupportedFile, lengthLessThanOrEqualTo } from "./validatorTests";
export const stringValidators = [
  {
    test: exists,
    message: "Please provide a name"
  },
  {
    test: hasLength,
    message: 'How did you manage this?'
  },
  {
    test: lengthLessThanOrEqualTo(250),
    message: "Must be less than 250 characters"
  },
];

export const fileValidators = [
  {
    test: exists,
    message: "Please provide a file"
  },
  {
    test: hasSize,
    message: "Unable to interpret file. Please provide a new file"
  },
  {
    test: isSupportedFile,
    message: "Please provide a supported file type. Supported types are: pdf"
  },
  {
    test: isLessThanMegabytes(10),
    message: "The selected file is too large. Files must be less than 10 megabytes"
  },
];
