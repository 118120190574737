import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useAppStyles } from '../../appStyles';
import { Context } from '../../Context';
import { handleOpenUpgradeAccountDialog } from '../../utils';

/**
 * @typedef {Object} UpgradeAccountCounterProps
 * @property {Number} count
 * @property {Number} maxItems
 * @property {Boolean} [hidden]
 * 
 * @param {UpgradeAccountCounterProps} props 
 */
export const UpgradeAccountCounter = (props) => {
  const { count, maxItems, hidden } = props;
  const { hide } = useAppStyles();
  /** @type {import('../../utils/persistence').KarachordsContext} */
  const context = useContext(Context)
  return (
    <Box border={1} padding={1} className={clsx({[hide]: hidden})}>
      <Grid container item direction={"row"} justifyContent={"space-around"} alignItems={"center"} style={{
        width: 'initial'
      }}>
        <Typography>
          {`${count} / ${maxItems}`}
        </Typography>
        <IconButton style={{ paddingRight: 0 }} onClick={
          handleOpenUpgradeAccountDialog(context, `SizeInfoUpgradeIconButton_${context.state?.main?.view?.name}`)}
          color={count >= maxItems ? "secondary" : "default"}
        >
          <Info />
        </IconButton>
      </Grid>
    </Box>
  )
};
