import { Button, Card, CircularProgress, DialogContent, Grid, Icon, Typography } from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useAppStyles } from '../../appStyles';
import { Context } from '../../Context';
import { logAnalyticsEvent } from '../../utils';
import { DEFAULT_UPGRADE_DIALOG_CONFIG } from './../../constants/config';
import { DialogHeader } from './DialogHeader';
import { useDialogStyles } from './styles';
import { getFormattedPrice, handleOpenSubscribeDialog } from './utils';

/**
 * 
 * @param {import('firebase/app').default.remoteConfig.RemoteConfig} remoteConfig
 * @param {import('firebase/app').default.analytics.Analytics} analytics
 * 
 * @return {import('../../constants/config').UpgradeDialogConfig}
 */
const getUpgradeDialogConfig = (remoteConfig, analytics) => {
  const requiredKeys = Object.keys(DEFAULT_UPGRADE_DIALOG_CONFIG);
  const requiredUpgradeKeys = Object.keys(DEFAULT_UPGRADE_DIALOG_CONFIG.upgradeOptions[0]);
  /** @type {import('./../../constants/config').UpgradeDialogConfig} */
  // @ts-ignore
  let remoteUpgradeDialogConfig = {};
  try {
    remoteUpgradeDialogConfig = JSON.parse(remoteConfig.getString("upgradeDialogConfig"));
  } catch (e) {
    console.error('getUpgradeDialogConfig() - caught error during JSON.parse()', e);
    logAnalyticsEvent("getUpgradeDialogConfig()_ParseFailure", "GetUpgradeDialogConfig", analytics);
  }
  let remoteConfigIsValid = false;
  try {
    remoteConfigIsValid = requiredKeys.reduce((acc, key) => {
      if (!acc) {
        return false;
      }

      const remoteConfigVal = remoteUpgradeDialogConfig[key];
      let isValid = remoteConfigVal !== undefined;
      if (isValid && typeof remoteConfigVal[0] !== "string") {
        // must be an array or we're about to toss an error
        remoteConfigVal.forEach((upgradeOption) => {
          isValid = requiredUpgradeKeys.reduce((acc, key) => {
            if (!acc) {
              return false
            }
            const remoteConfigUpgradeOptionVal = upgradeOption[key];
            return remoteConfigUpgradeOptionVal !== undefined
          }, true)
        })
      }
      return isValid;
    }, true);
  } catch (e) {
    console.error('getUpgradeDialogConfig() - caught error while validating remote config', e);
    logAnalyticsEvent("getUpgradeDialogConfig()_ValidationError", "GetUpgradeDialogConfig", analytics);
  }
  if (remoteConfigIsValid) {
    logAnalyticsEvent("getUpgradeDialogConfig()_ValidRemoteConfig", "GetUpgradeDialogConfig", analytics);
    return remoteUpgradeDialogConfig
  }
  console.warn('getUpgradeDialogConfig() - remoteUpgradeDialogConfig is invalid', remoteUpgradeDialogConfig)
  logAnalyticsEvent("getUpgradeDialogConfig()_InvalidRemoteConfig", "GetUpgradeDialogConfig", analytics);
  return DEFAULT_UPGRADE_DIALOG_CONFIG;
}

//  TODO NEXT: Fix max setlist styling in bullets
export const UpgradeAccountDialog = () => {
  const { dispatch, state, remoteConfig, analytics } = useContext(Context);
  const { spacingBottom, textCenter, noWrap, spacingRight, italic, doubleSpacingTop } = useAppStyles();
  const { subscribeCardContainer, upgradeOptionsContainer, subscribeCard, subscribeCardContentContainer, subscribeCardBestForCTA, upgradeOptionHighlightText, upgradeOptionContainerHighlight, upgradeOptionContainer } = useDialogStyles();
  const songsCount = state.songs?.list?.length;
  const listsCount = state.lists?.list?.length;
  const maxSongs = state?.songs?.max;
  const maxLists = state?.lists?.max;
  const maxListSize = state?.lists?.maxSize;
  const [isLoading, setIsLoading] = useState(false);
  const upgradeDialogConfig = getUpgradeDialogConfig(remoteConfig, analytics);
  const { upgradeOptions, title, yourAccountHeadline, upgradeCTA, subscribeCTAButtonText } = upgradeDialogConfig;
  return (
    <React.Fragment>
      <DialogHeader title={title} dispatch={dispatch} isLoading={isLoading} />
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"} className={clsx(spacingBottom)}>
            <Grid item>
              <Typography variant={"h6"}>
                {yourAccountHeadline}
              </Typography>
            </Grid>
            <Grid item container direction={"row"} justifyContent={"space-around"} alignItems={"center"} className={clsx(noWrap)}>
              <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <Typography>
                    {`Library`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {`${songsCount} / ${maxSongs} slots used`}
                  </Typography>
                </Grid>
              </Grid>
              {isLoading && (
                <Grid item>
                  <CircularProgress />
                </Grid>
              )}
              <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <Typography>
                    {"Maximum setlist size"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {maxListSize}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <Typography>
                    {`Setlists`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {`${listsCount} / ${maxLists} slots used`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={clsx(textCenter)}>
              {upgradeCTA}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction={"row"} justifyContent={"space-around"} alignItems={"center"} className={clsx(spacingBottom, upgradeOptionsContainer, doubleSpacingTop)}>
          {upgradeOptions.map((upgradeOption) => {
            const { name, price, duration, marketingBullets = [], bestForCTA, highlight, id } = upgradeOption;
            const formattedPrice = getFormattedPrice(price);
            const key = `upgardeOption-${name}`;
            return (
              <div key={key} className={clsx(upgradeOptionContainer, {
                [upgradeOptionContainerHighlight]: highlight,
              })}>
                {highlight && (
                  <div className={clsx(upgradeOptionHighlightText, textCenter)}>
                    <Typography>
                      {highlight}
                    </Typography>
                  </div>
                )}
                <Grid item className={clsx(subscribeCardContainer)}>
                  <Card elevation={3} className={clsx(subscribeCard)}>
                    <Grid container item direction={"column"} alignItems={"center"} justifyContent={"space-between"} className={clsx(subscribeCardContentContainer)}>
                      <Grid container item direction={"column"} alignItems={"center"} justifyContent={"flex-start"}>
                        <Grid item>
                          <Typography variant={"h6"} className={clsx(textCenter)}>
                            {name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={clsx(italic, textCenter, subscribeCardBestForCTA)}>
                            {bestForCTA}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item direction={"column"} alignItems={"center"} justifyContent={"flex-start"}>
                        {marketingBullets.map((string, index) => {
                          const bulletKey = `${key}-bullet-${index}`;
                          return (
                            <Grid key={bulletKey} item container className={clsx(noWrap)} justifyContent={"flex-start"} alignItems={"center"}>
                              <Grid item className={clsx(spacingRight)}>
                                <Icon color={"primary"}>
                                  <CheckCircleOutlineOutlined />
                                </Icon>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {string}
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                      <Grid container item direction={"column"} alignItems={"center"} justifyContent={"flex-start"}>
                        <Grid item>
                          <Typography>
                            {`${formattedPrice} / ${duration}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            disabled={isLoading}
                            variant={"contained"}
                            color={highlight ? "primary" : "default"}
                            onClick={handleOpenSubscribeDialog(id, analytics, dispatch, setIsLoading, 'upgradeAccountDialog')}>
                            {subscribeCTAButtonText}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </div>
            )
          })}
        </Grid>
      </DialogContent>
    </React.Fragment >
  )
}

