import get from 'lodash.get';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { REMOVE_NOTIFICATION } from '../../actions';
import { Context } from '../../Context';

/**
 * 
 * @param {String} key 
 * @param {Set<String>} displayedNotifications 
 * @param {(action:import('../../actions').Action) => void} dispatch 
 * @param {(event: React.SyntheticEvent, reason: import('notistack').CloseReason, key: String) => void} [onClose]
 * @return {(event: React.SyntheticEvent, reason: import('notistack').CloseReason, key: String) => void}
 */
const handleClose = (key, displayedNotifications, dispatch, onClose = () => {}) => (event, reason, key) => {
  /** @type {import('notistack').CloseReason} */
  const IGNORE_REASON = 'clickaway';
  if (reason === IGNORE_REASON) {
    // ignore clickaway close reasons
    // because for some reason the library calls the onClose before checking
    // and THEN doesn't do anything - dumb behavior
    return
  }
  displayedNotifications.delete(key);
  dispatch({ type: REMOVE_NOTIFICATION, payload: { key } })
  onClose(event, reason, key)
}
const displayedNotifications = new Set();
/**
 * @typedef {import('../../reducers/functions').Notification} Notification
 */
export const Notifications = () => {
  /** @type {import('../../Context').KarachordsContext} */
  const { dispatch, state } = useContext(Context);
  /** @type {import('../../appState').NotificationsMap}*/
  const map = get(state, 'notifications.map', new Map());
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    map.forEach((notif, key) => {
      if (displayedNotifications.has(key)) {
        return;
      }
      const { message, options = {} } = notif;
      const { onClose: optionsOnClose } = options;
      /** @type {import('notistack').OptionsObject} */
      const extraOptions = { onClose: handleClose(key, displayedNotifications, dispatch, optionsOnClose) }
      const finalOptions = Object.assign({}, options, extraOptions)
      enqueueSnackbar(message, finalOptions);
      displayedNotifications.add(key);
    })
  }, [map])

  return (null);
}
