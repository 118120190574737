import { Button, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { CLOSE_DIALOG, SELECT_VIEW } from '../../actions';
import { CONTACT_LINK_MAILING_LIST, VIEW_NAMES } from '../../constants';
import { Context } from '../../Context';
import { logAnalyticsEvent } from '../../utils';
import { DialogHeader } from './DialogHeader';
import { useDialogStyles } from './styles';
export const SubscribeDialog = () => {
  const { dispatch, analytics } = useContext(Context);
  const { selectViewLink } = useDialogStyles();
  return (
    <React.Fragment>
      <DialogHeader title={"Upgrade Your Account"} isLoading={false} dispatch={dispatch} />
      <DialogContent>
        <DialogContentText>
          {"We're sorry! Subscription plans aren't quite ready yet, but they will be soon. Sign up below and we'll notify you when they are."}
        </DialogContentText>
        <span>
          {`Need more space right now? Contact us directly from the`}
          <a className={clsx(selectViewLink)} onClick={(e) => {
            e.preventDefault();
            dispatch({ type: CLOSE_DIALOG });
            /** @type {import('../../reducers/functions').SelectViewPayload} */
            const payload = { name: VIEW_NAMES.SETTINGS }
            dispatch({ type: SELECT_VIEW, payload })
            logAnalyticsEvent("SubscribeDialog_Stub_NavigateToSettings", 'SubscribeDialog', analytics);
          }}>
            {`${VIEW_NAMES.SETTINGS}`}
          </a>
          {`menu.`}
        </span>
      </DialogContent>
      <DialogActions>
        <Button color={"primary"} onClick={(e) => {
          e.preventDefault();
          window.open(CONTACT_LINK_MAILING_LIST, '_blank')
          logAnalyticsEvent("SubscribeDialog_Stub_SignUp", 'SubscribeDialog', analytics);
        }}>
          {"Sign up"}
        </Button>
        <Button color={"default"} onClick={() => {
          dispatch({ type: CLOSE_DIALOG });
        }}>
          {"Close"}
        </Button>
      </DialogActions>
    </React.Fragment>

  )
}