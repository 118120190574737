import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import { mdiFormTextbox } from '@mdi/js';
import React, { useContext } from 'react';
import { editModes } from '../../constants';
import { FontColorSelector } from './FontColorSelector';
import { FontSelector } from './FontSelector';
import { FontSizeSelector } from './FontSizeSelector';
import { DEFAULT_CONTAINER_SIZE, noteType } from './playConstants';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from './PlayStyles';
import { SaveCurrentSongButton } from './SaveCurrentSongButton';
import { SelectEditModeButton } from './SelectEditModeButton';
import { addNote, getUnscaledDefaultNoteOrigin } from './utils';

/**
 * 
 * @param {import('./PlayContext').Font} font 
 * @param {Array<import('./PlayContext').Note>} notes
 * @param {Number} pageScale
 * @param {(notes:Array<import('./PlayContext').Note>) => void} setNotes 
 * @param {(note:import('./PlayContext').Note) => void} setActiveNote 
 */
const handleAddTextNote = (font, notes, pageScale, setNotes, setActiveNote) => () => {
  const { x, y } = getUnscaledDefaultNoteOrigin(window, DEFAULT_CONTAINER_SIZE, pageScale)
  const note = addNote({ type: noteType.text, font, x, y }, notes, setNotes);
  setActiveNote(note);
};

export const EditTextPanel = () => {
  const { editPanel } = usePlayStyles();
  const { state: playState, methods } = useContext(PlayContext);
  const { setActiveNote } = methods;
  const { notes, font, pageScale } = playState;
  const { setNotes } = methods;

  return (
    <Grid
      className={editPanel}
      container
      alignItems={"center"}
      justifyContent={"space-around"}
      direction={"row"}
      wrap={"nowrap"}
    >
      <Grid container item alignItems={"center"} justifyContent={"center"}>
        <IconButton onClick={handleAddTextNote(font, notes, pageScale, setNotes, setActiveNote)}>
          <SvgIcon>
            <path d={mdiFormTextbox} />
          </SvgIcon>
        </IconButton>
      </Grid>
      <Grid container item alignItems={"center"} justifyContent={"center"}>
        <FontSelector />
      </Grid>

      <Grid container item alignItems={"center"} justifyContent={"center"}>
        <FontSizeSelector />
      </Grid>

      <Grid container item alignItems={"center"} justifyContent={"center"}>
        <FontColorSelector />
      </Grid>
{/* 
      <Grid container item alignItems={"center"} justifyContent={"center"} direction={"row"}>
        <LayerSelector />
      </Grid> */}
      <Grid container item alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <SaveCurrentSongButton />
      </Grid>

      <Grid container item alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <SelectEditModeButton editMode={editModes.selecting} />
      </Grid>
    </Grid>
  )
}




