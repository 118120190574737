import { Dialog, Grid } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useAppStyles } from "../appStyles";
import { getDialogComponent, getMainComponent } from "../appUtils";
import MenuDrawer, { SubDrawer } from '../components/drawers';
import { MobileNav } from '../components/nav/MobileNav';
import { Navbar } from '../components/navbar';
import { Context } from '../Context';

export const PrimaryView = () => {
  /** @type {import('../Context').KarachordsContext} */
  const { state } = useContext(Context);
  const { mainContainer, mainContainerMenuOpen, root } = useAppStyles();

  const MainComponent = getMainComponent(state);
  const DialogComponent = getDialogComponent(state);
  const menuIsOpen = state.nav.menu.isOpen;
  return (
    <React.Fragment>
      <Grid container className={root} id="rootContainer">
        <Navbar menuIsOpen={menuIsOpen} />
        <MenuDrawer />
        <Grid id="mainContainer" spacing={2} container item className={clsx(mainContainer, {
          [mainContainerMenuOpen]: menuIsOpen,
        })}>
          <SubDrawer />
          <MainComponent />
        </Grid>
        <MobileNav />
      </Grid>
      <Dialog open={state.dialog.isOpen} fullWidth>
        <DialogComponent />
      </Dialog>
    </React.Fragment>
  );
};
