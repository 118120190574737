/** 
 * @typedef {Object} UpgradeOption 
 * @property {String} name
 * @property {Number} price price in pennies
 * @property {String} duration duration of subscription, typically "month" or "year" 
 * @property {String} bestForCTA description of who this is best for
 * @property {Array<String>} marketingBullets
 * @property {String|null} highlight string for highlighted option
 * @property {String} id
 * */
/** @type {Array<UpgradeOption>} */
const upgradeOptions = [
  {
    name: "Student",
    price: 499,
    duration: "month",
    highlight: null,
    bestForCTA: "Best for getting started",
    marketingBullets: [
      "50 library slots",
      "20 setlist slots",
      "15 max setlist size"
    ],
    id: 'student-default',
  },
  {
    name: "Professional",
    price: 999,
    duration: "month",
    bestForCTA: "Best for most musicians",
    highlight: "Best Value",
    marketingBullets: [
      "200 library slots",
      "50 setlist slots",
      "30 max setlist size"
    ],
    id: 'professional-default',
  },
  {
    name: "Conductor",
    price: 1799,
    duration: "month",
    bestForCTA: "Best for musicians with large libraries",
    highlight: null,
    marketingBullets: [
      "500 library slots",
      "100 setlist slots",
      "70 max setlist size"
    ],
    id: 'conductor-default',
  }
];

/** 
 * @typedef {Object} UpgradeDialogConfig
 * @property {Array<UpgradeOption>} upgradeOptions
 * @property {String} title
 * @property {String} yourAccountHeadline
 * @property {String} upgradeCTA
 * @property {String} subscribeCTAButtonText
 * @property {String} id
 */

 /** @type {UpgradeDialogConfig} */
const upgradeDialogConfig = {
  upgradeOptions,
  title: "Upgrade Account",
  yourAccountHeadline: "Your Account",
  upgradeCTA: "Need more space? Upgrade your account to one of the levels below!",
  subscribeCTAButtonText: "Subscribe",
  id: 'default'
}

export const DEFAULT_UPGRADE_DIALOG_CONFIG = upgradeDialogConfig;

const DEFAULT_REMOTE_CONFIG_BASE = {
  upgradeDialogConfig: DEFAULT_UPGRADE_DIALOG_CONFIG,
}


export const DEFAULT_REMOTE_CONFIG = Object.entries(DEFAULT_REMOTE_CONFIG_BASE).reduce((acc, [key, value]) => {
  return Object.assign(acc, {[key]: JSON.stringify(value)});
}, {})
