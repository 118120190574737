import React, { useContext } from 'react';
import { usePlayStyles } from "./PlayStyles";
import { PlayContext } from './PlayContext';
import clsx from 'clsx';
import { getScaledInputStyle, updateNote } from './utils';


/**
 * @typedef {import('./utils').Note} Note
 * @param {Note} note
 * @param {Array<Note>} notes
 * @param {(notes:Array<Note>) => void} setNotes
 * @param {(note:Note) => void} setActiveNote
 * @returns {(e:React.ChangeEvent<{value: String}>) => void}
 */
export const handleChange = (note, notes, setNotes, setActiveNote) => (e) => {
  const nextNote = Object.assign({}, note, {text: e.currentTarget.value});
  updateNote(nextNote, notes, setNotes);
  setActiveNote(nextNote);
}
/**
 * 
 * @param {import('./DraggableResizeableContainer').DraggableItemProps} props 
 */
export const DraggableTextInput = (props) => {
  const { state, methods } = useContext(PlayContext);
  const { notes } = state;
  const { setNotes, setActiveNote } = methods;
  const { draggableTextInput } = usePlayStyles();
  const { note, pageScale, isActive } = props;
  const inputStyle = getScaledInputStyle(note, pageScale);
  const { text } = note;
  return (
    <input
      type={"text"}
      className={clsx(draggableTextInput)}
      style={inputStyle}
      value={text || ''}
      onChange={handleChange(note, notes, setNotes, setActiveNote)}
      disabled={!isActive}
      />
  );
};
