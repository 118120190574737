import { Button, CssBaseline } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/remote-config";
import { Action } from 'history';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useReducer, useRef } from 'react';
import 'typeface-roboto';
import { SELECT_VIEW } from './actions';
import './App.css';
import { initialState } from './appState';
import { useAppStyles } from './appStyles';
import { getView, initApp } from './appUtils';
import { Notifications } from './components/notifications';
import firebaseConfig from './config/app.json';
import * as constants from './constants';
import { DEFAULT_REMOTE_CONFIG } from './constants/config';
import { Context } from './Context';
import { reducer } from './reducers';
import defaultTheme from './themes/defaultTheme';

/** @typedef {import('./appState').KarachordsState} KarachordsState */
const configKey = process.env.REACT_APP_CONFIG_KEY;
firebase.initializeApp(firebaseConfig[configKey]);

// const environment = getEnvironment(window.location.hostname);
// if (environment === 'dev') {
//   firebase.auth().useEmulator('http://localhost:9099');
// }

const analytics = firebase.analytics();
const remoteConfig = firebase.remoteConfig();
if (process.env.REACT_APP_REMOTE_CONFIG_MINIMUM_REFRESH_MILLIS) {
  try {
    const minimumFetchIntervalMillis = parseInt(process.env.REACT_APP_REMOTE_CONFIG_MINIMUM_REFRESH_MILLIS);
    remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis;
  } catch (e) {
    console.error(e);
    console.error('App.js - unable to retrieve remote config minimum millis from config file');
  }

}

remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG;
export const App = () => {
  const [state, originalDispatch] = useReducer(reducer, initialState);
  const { notificationsContainer } = useAppStyles();
  /**
    @param {import('./actions/index').Action} action 
   */
  const dispatch = (action) => {
    originalDispatch(action)
  }
  /** @type {import('./Context').KarachordsContext} */
  const context = Object.assign({ state }, { dispatch, views: constants.VIEWS, analytics, remoteConfig, firebase });
  /** @type {KarachordsState} */
  const { history } = state;
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shareId = urlParams.get("share");
    const unsubscribeFromAuthState = initApp(firebase, analytics, context, shareId);
    context.unsubscribeFromAuthState = unsubscribeFromAuthState;
    window.history.replaceState(null, "Home", `/`);
    // clear url params
    history.replace(`/${constants.VIEW_NAMES.HOME.toLocaleLowerCase()}`, null);
    // reset state and url
    try {
      remoteConfig.fetchAndActivate();
    } catch (e) {
      console.error('Failed to fetch and activate remote config', e);
    }

    const unlisten = history.listen(({ action, location }) => {
      if (action === Action.Pop) {
        /** @type {import('./reducers/functions').SelectViewPayload} */
        if (location.state) {
          if (location.state.type) {
            if (location.state.payload) {
              Object.assign(location.state.payload, { skipHistory: true })
            }
            // @ts-ignore
            const action = Object.assign({}, location.state);
            dispatch(action);
            // whatever action we were doing for the pop, extract the location.state.payload and add a skip history flag so we ignore history after we dispatch
          }

        } else {
          // default - if we've done a pop but don't have a payload, go home
          dispatch({ type: SELECT_VIEW, payload: { name: constants.VIEW_NAMES.HOME } })
        }
      }
    });
    return () => {
      dispatch({ type: SELECT_VIEW, payload: { name: constants.VIEW_NAMES.HOME } });
      unlisten();
      unsubscribeFromAuthState();
    }
  }, []);

  /** @type {React.FunctionComponent} */
  const View = getView(state, dispatch);
  const notistackRef = useRef();
  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Context.Provider value={context}>
        <SnackbarProvider
          classes={{ containerRoot: clsx(notificationsContainer) }}
          maxSnack={3}
          ref={notistackRef}
          action={(key) => {
            const handleClick = (key, ref) => () => {
              /** @type {import('./reducers/functions').RemoveNotificationPayload} */
              if (ref && ref.current) {
                ref.current.closeSnackbar(key);
              }

            }
            return (
              <Button onClick={handleClick(key, notistackRef)}>
                Dismiss
              </Button>
            )
          }}
        >
          <View />
          <Notifications />
        </SnackbarProvider>
      </Context.Provider>
    </MuiThemeProvider>
  )
}

export default App;



