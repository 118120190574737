import { CLOSE_DIALOG, OPEN_DIALOG } from '../../actions';
import { DIALOG_NAMES } from '../../constants';
import { logAnalyticsEvent } from '../../utils';
/**
 * 
 * @param {String} displayText 
 * @param {(e:React.SyntheticEvent) => Promise<void>} onConfirm 
 * @param {React.Dispatch<import('../../actions').Action>} dispatch
 * @param {() => void} [afterClose]
 */
export const handleShowConfirmActionDialog = (displayText, onConfirm, dispatch, afterClose) => () => {
  /** @type {import('../../appState').DialogProps} */
  const props = { displayText };
  /** @type {import('../../appState').DialogMethods} */
  const methods = { onConfirm };
  if (afterClose) {
    Object.assign(methods, { afterClose });
  }
  /** @type {import('../../reducers/functions').OpenDialogPayload} */
  const payload = { dialog: DIALOG_NAMES.CONFIRM_ACTION, props, methods };
  dispatch({ type: OPEN_DIALOG, payload });
}

/**
 * @param {import('react').Dispatch<import('../../actions').Action>} dispatch
 */
export const handleCloseDialog = (dispatch) => () => {
  /** @type {import('../../actions').Action} */
  const action = { type: CLOSE_DIALOG };
  dispatch(action);
}

/**
 * 
 * @param {React.Dispatch<import('../../actions').Action>} dispatch 
 */
export const handleOpenAuthDialog = (dispatch) => {
  return async () => {
    /** @type {import('../../reducers/functions').OpenDialogPayload} */
    const payload = { dialog: DIALOG_NAMES.AUTH };
    dispatch({ type: OPEN_DIALOG, payload });
  };
}

/**
 * 
 * @param {import("../../constants").Item} item 
 */
export function getCanRevoke(item) {
  const allowedRevokeRoles = new Set(["admin", "owner"]);
  const role = item.share ? item.share.role : null;
  let canRevoke = false;
  if (!role || allowedRevokeRoles.has(role)) {
    canRevoke = true;
  }
  return canRevoke;
}


/**
 * 
 * @param {Number} pennies 
 */
export function getFormattedPrice(pennies) {
  return new Intl.NumberFormat("en-US",
    { style: "currency", currency: "USD", currencyDisplay: "narrowSymbol" })
    .format(pennies / 100);
}

/**
 * @param {String} upgradeOptionId
 * @param {import('firebase/app').default.analytics.Analytics} analytics
 * @param {import('react').Dispatch<import('../../actions').Action>} dispatch
 * @param {(isLoading: boolean) => void} setIsLoading
 * @param {String} componentName
 */
export const handleOpenSubscribeDialog = (upgradeOptionId, analytics, dispatch, setIsLoading, componentName) => () => {
  setIsLoading(true);
  setTimeout(() => {
    logAnalyticsEvent(`UpgradeAccount_upgradeOption_${upgradeOptionId}`, componentName, analytics);
    /** @type {import('../../reducers/functions').OpenDialogPayload} */
    const payload = { dialog: DIALOG_NAMES.SUBSCRIBE_STUB }
    dispatch({type: OPEN_DIALOG, payload});
  }, 1500);
}