import React, { useContext } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { colors, DEFAULT_COLOR } from "./playConstants";
import { PlayContext } from './PlayContext';

/**
 * @param {(font:import('./PlayContext').Font) => void} setFont
 * @return {(e:import('react').ChangeEvent<{name?: string, value: string}>) => void}
 */
export const handleChange = (setFont) => (e) => {
  e.preventDefault();
  const value = e.target.value;
    setFont({ color: value });
};
export const FontColorSelector = () => {
  const { state, methods } = useContext(PlayContext);
  const { font } = state;
  const { color = DEFAULT_COLOR } = font;
  const { setFont } = methods;
  return (
    <Select value={color} onChange={handleChange(setFont)} disableUnderline={true}>
      {colors.map((color, index) => {
        return (
          <MenuItem value={color} key={`font-color-option-${index}`}>
            <div style={{height: '1rem', width: '1rem', backgroundColor: color}} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
