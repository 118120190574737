import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';
import { handleShowConfirmActionDialog } from '../dialogs/utils';
import { handleRemoveSharedItem } from './utils';

const copy = {
  remove: "will be removed from your library. To get it back, someone will need to share it with you again."
}

const getCopy = (name) => {
  return `${name} ${copy.remove}`
}
export const RemoveItemMenuItem = (props) => {
  const { context, hidden, item } = props;
  const { name } = item;
  const { dispatch } = context;
  if (hidden) {
    return null;
  }
  return (
    <MenuItem onClick={handleShowConfirmActionDialog(
      getCopy(name),
      handleRemoveSharedItem(context, item),
      dispatch
    )}>
      <ListItemIcon>
        <Delete color={"secondary"} />
      </ListItemIcon>
      <ListItemText>
        {"Remove"}
      </ListItemText>
    </MenuItem>
  );
};
