import { Button, CircularProgress, Grid } from '@material-ui/core';
import { PlayArrow, Save, Share } from '@material-ui/icons';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { useAppStyles } from '../../../appStyles';
import { DELETE_ROLES, EDIT_ROLES, ROLES, SHARE_TYPES } from '../../../constants';
import { Context } from '../../../Context';
import { getHandlePlaySetlist } from '../../drawers/utils';
import { handleOpenShareDialog } from '../utils';

/**
 * 
 * @typedef {Object} ListFormActionsProps
 * @property {boolean} areButtonsDisabled
 * @property {import('.').List} editingList
 * @property {(isLoading: boolean) => void} setLoading
 * @property {Boolean} isLoading
 * 
 * @param {ListFormActionsProps} props 
 */
export const ListFormActions = (props) => {
  const { editingList, areButtonsDisabled, setLoading, isLoading } = props
  const { id, songs = [] } = editingList;
  const playAndShareDisabled = areButtonsDisabled || !id || songs.length < 1;
  const saveDisabled = areButtonsDisabled || songs.length < 1;
  const context = useContext(Context);
  const { hide } = useAppStyles();
  const role = get(editingList, 'share.role', ROLES.owner);
  const canEdit = EDIT_ROLES.has(role);
  const canDelete = DELETE_ROLES.has(role);
  return (
    <React.Fragment>
      {isLoading && <CircularProgress />}
      <Grid item>
        <Button
          variant={"contained"}
          startIcon={<PlayArrow />}
          disabled={playAndShareDisabled}
          color={"default"}
          onClick={getHandlePlaySetlist(context)(editingList)}
          size={"large"}
        >
          {"Play"}
        </Button>
      </Grid>
      <Grid item className={clsx({[hide]: !canDelete})}>
        <Button
          startIcon={<Share />}
          size={"large"}
          color={"default"}
          disabled={playAndShareDisabled}
          variant={"contained"}
          onClick={handleOpenShareDialog(context, editingList, SHARE_TYPES.list, setLoading)}
        >
          {"Share"}
        </Button>
      </Grid>
      <Grid item className={clsx({[hide]: !canEdit})}>
        <Button
          variant={"contained"}
          startIcon={<Save />}
          type="submit"
          disabled={saveDisabled}
          color={'primary'}
          size={"large"}
        >
          {"Save"}
        </Button>

      </Grid>
    </React.Fragment>
  );
}
