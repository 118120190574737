import React, { useContext } from 'react';
import { Grid, Typography, TextField, DialogTitle, IconButton, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Context } from '../../Context';
import { handleCloseDialog } from './utils';
import { Close } from '@material-ui/icons';
import get from 'lodash.get';

const ARTIST = 'artist';
/**
 * 
 * @param {Array<import('../lists/utils').Filter>} filters
 * @returns {import('../lists/utils').Filter|Object}
 */
const getArtistFilter = (filters) => {
  if (!filters) {
    return {};
  }
  return filters.reduce((prev, curr) => {
    if (curr.property === ARTIST) {
      return curr;
    }
    return prev;
  }, {})
}

/**
 * @param {import('react').Dispatch<Array<import('../lists/utils').Filter>>} setFilters
 * @param {() => void} close
 * @return {(e:React.ChangeEvent) => void}
 */
const handleSelect = (setFilters, close) => (e) => {
  const newFilters = [];
  let selected = e.currentTarget.innerHTML;
  if (selected && !selected.includes('<span')) {
    // clearing selected results in an onChange that returns a span
    newFilters.push({ property: ARTIST, value: e.currentTarget.innerHTML });
  }
  setFilters(newFilters);
  close();
}

/**
 * @param {import('react').Dispatch<Array<import('../lists/utils').Filter>>} setFilters
 * @param {() => void} close
 * @return {() => void}
 */
const handleClear = (setFilters, close) => () => {
  setFilters([]);
  close();
}
export const SongFiltersDialog = () => {
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { state, dispatch } = context;
  const { artists } = state;
  const dialog = state.dialog;
  const header = dialog.dialog.header
  const { methods, props } = dialog
  const { setFilters } = methods;
  const { filtersPath } = props;
  const filters = get(state, filtersPath, []);

  const artistFilter = getArtistFilter(filters);
  const close = handleCloseDialog(dispatch);
  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h6"}>
            {header || "Select filters"}
          </Typography>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={artists.list}
          getOptionLabel={(option) => option}
          onChange={handleSelect(setFilters, close)}
          renderInput={(params) => <TextField {...params} label="Artists" variant={"filled"} />}
          value={artistFilter.value}
          defaultValue={null}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} color={"default"} onClick={handleClear(setFilters, close)}>
          {"Clear"}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}