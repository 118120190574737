import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DraggableListSongContainer } from './DraggableListSongContainer';
import { ListSongPlaceholder } from './ListSongPlaceholder';

export const DroppableListSongContainer = (props) => {
  const { onDragEnd, songs } = props;
  const shouldRenderDroppable = songs && songs.length && songs.length > 0;
  if (!shouldRenderDroppable) {
    return <ListSongPlaceholder />
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="listFormDroppable">
        {(provided) => {
          return (
            <DraggableListSongContainer provided={provided} {...props} />
          );
        }}
      </Droppable>
    </DragDropContext>
  )
}