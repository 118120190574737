import { Container } from '@material-ui/core';
import { AddCircle, PlayArrow } from '@material-ui/icons';
import get from 'lodash.get';
import React, { useContext } from 'react';
import * as constants from '../../constants';
import { Context } from '../../Context';
import { HeadedItemsList } from '../lists';
import { useDrawerStyles } from "./styles";
import { getHandlePlaySetlist, getHandlePlaySong } from './utils';
import { getSetFilters, newItem } from "../../actions/utils";
import { SELECT_ITEM } from '../../actions';
import clsx from 'clsx';
import { getMaxItemsFromState } from '../../utils';
/**
  * @param {String} viewName one of constants.VIEW_NAMES 
  * @param {import('../../Context').KarachordsContext} context
  * @returns {import('../lists/HeadedItemsList').HeadedItemListItemSecondaryAction}
  */
const getSecondaryAction = (viewName, context) => {
  const methods = {
    [constants.VIEW_NAMES.LIBRARY]: getHandlePlaySong(context),
    [constants.VIEW_NAMES.SETLISTS]: getHandlePlaySetlist(context),
  }
  const method = methods[viewName] || (() => () => { console.warn('no method defined for', viewName) })
  /** @type {import('../lists/HeadedItemsList').HeadedItemListItemSecondaryAction} */
  const action = {
    handleSecondaryAction: method,
    Icon: PlayArrow
  }
  return action;
}

/**
 * @param {React.Dispatch<import('../../actions').Action>} dispatch
 * @return {(item:import('../../constants').Item) => (e:React.SyntheticEvent) => void}
 */
const getHandlePrimaryAction = (dispatch) => (item) => (e) => {
  e.preventDefault();
  /** @type {import('../../reducers/functions').SelectItemPayload} */
  const payload = { item };
  dispatch({ type: SELECT_ITEM, payload });
}
/**
 * @typedef {Object} HeadedItemListAddAction
 * @property {(e: React.SyntheticEvent) => void} method
 * @property {React.FunctionComponent} Icon
 * @param {string} viewName
 * @param {import('../forms/song/SongForm').KarachordsContext} context
 */
const getAddAction = (viewName, context) => {
  const methods = {
    [constants.VIEW_NAMES.LIBRARY]: newItem(context),
    [constants.VIEW_NAMES.SETLISTS]: newItem(context),
  }
  const method = methods[viewName] || (() => () => { console.warn('no add action method defined for', viewName) })
  const action = {
    method,
    Icon: AddCircle,
  }
  return action;
}

const copy = {
  placeholder: {
    [constants.VIEW_NAMES.LIBRARY]: "You don't have any items in your library. Try adding one.",
    [constants.VIEW_NAMES.SETLISTS]: "You don't have any setlists. Try adding one.",
  }
}

export const SubDrawer = () => {
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { dispatch, state } = context;
  const items = state.nav.subMenu.items;
  const mainView = state.main.view;
  const { name } = mainView;
  const mainViewProps = getMainViewProps(mainView);
  const { hide, subMenu } = useDrawerStyles();
  const addAction = getAddAction(name, context);
  const secondaryAction = getSecondaryAction(name, context);
  /** @type {import('../lists/HeadedItemsList').HeadedItemListItemPrimaryAction} */
  const primaryAction = { handlePrimaryAction: getHandlePrimaryAction(dispatch) }
  return (
    <Container className={clsx(subMenu, {
      [hide]: state.main.item || !items,
    })}>
      <HeadedItemsList
        items={items}
        maxItems={getMaxItemsFromState(state, 2000)}
        secondaryAction={secondaryAction}
        addAction={addAction}
        primaryAction={primaryAction}
        filtersPath={constants.filtersPaths.SUB_DRAWER}
        setFilters={getSetFilters(dispatch, constants.filtersPaths.SUB_DRAWER)}
        placeholder={copy.placeholder[mainView.name]}
        {...mainViewProps}
      />
    </Container>
  );
};
/**
 * 
 * @param {import('../../constants').KarachordsView} mainView;
 * @return {{header: string, secondaryTextProp:string, searchOptions:import('../../constants').SearchOptions, filterDialog:import('./../../constants').KarachordsDialog}}
 */
const getMainViewProps = (mainView) => {
  const paths = ["header", "subMenu.secondaryTextProp", "subMenu.searchOptions", "subMenu.filterDialog"];
  //@ts-ignore
  return paths.reduce((obj, path) => {
    const split = path.split('.');
    const name = split[split.length - 1];
    const prop = get(mainView, path, null)
    return Object.assign(obj, { [name]: prop })
  }, {})
}



