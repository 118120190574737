import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { useNavStyles } from './styles';
import { handleMobileNavChange } from './utils';


/**
 * 
 * @param {import('../../constants').KarachordsView} view 
 * @param {Number} index
 * @param {String} selectedView
 * @param {*} selectedClass
 */
const renderMobileNavAction = (view, index, selectedView, selectedClass) => {
  const { name, text, Icon } = view;
  const isSelected = selectedView === name;
  return (
    <BottomNavigationAction
      key={`${name}-mobile-nav-${index}`}
      label={text}
      icon={<Icon />}
      value={name}
      className={clsx({
        [selectedClass]: isSelected
      })}
    />
  );
};
const getRenderMobileNavAction = (selectedView, selectedClass) => (view, index) => {
  return renderMobileNavAction(view, index, selectedView, selectedClass);
}
export const MobileNav = () => {
  const { mobileNav } = useNavStyles();
  /** @type {import('../forms/song/SongForm').KarachordsContext} */
  const { state, dispatch, views } = useContext(Context);
  const selectedView = get(state, 'main.view.name', null);
  const { selectedNavAction } = useNavStyles();
  return (
    <BottomNavigation
      showLabels={true}
      className={mobileNav}
      onChange={handleMobileNavChange(dispatch)}
    >
      {views.list.map(getRenderMobileNavAction(selectedView, selectedNavAction))}
    </BottomNavigation>
  );
};
