import React from 'react';
import { Button, IconButton } from '@material-ui/core';

/**
 *
 * @typedef {Object} HeadedItemsListItemSecondaryActionProps
 * @property {import('./HeadedItemsList').HeadedItemListItemSecondaryAction} action
 * @property {import('../../constants').Item} item
 * @property {Boolean} [disabled]
 */
/**
 * @param {HeadedItemsListItemSecondaryActionProps} props
 */
export const HeadedItemsListItemSecondaryActionButton = (props) => {
  const { action, item, disabled } = props;
  if (!action) {
    return null;
  }
  const { Icon, handleSecondaryAction, text } = action;
  if (text) {
    return (
      <Button onClick={handleSecondaryAction(item)} startIcon={<Icon />} disabled={disabled}>
        {text}
      </Button>
    )
  }
  return (
    <IconButton onClick={handleSecondaryAction(item)} disabled={disabled}>
      <Icon />
    </IconButton>
  );
};
