import get from "lodash.get";
import { CLOSE_DIALOG, OPEN_DIALOG, SELECT_VIEW, SET_DATA } from "../actions";
import { DATA_TYPES, DIALOG_NAMES, STATE_DATA_PATHS } from "../constants";
import firebase from 'firebase/app';

/**
 * 
 * @param {Function} func 
 * @param {Number} delay 
 */
export const debounce = (func, delay) => {
  let timeout;
  return (...params) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...params);
    }, delay)
  }
}

/**
 * @param {String} hostname
 * @return {'dev'|'prod'}
 */
export const getEnvironment = (hostname) => {
  if (hostname === 'localhost') {
    return 'dev';
  }
  return 'prod'
}

/**
 * 
 * @param {import("../App").KarachordsState} state 
 */
export function updateHistoryAfterSave(state) {
  const { history } = state;
  const viewName = state.main.view.name;
  history.replace(`/${viewName.toLocaleLowerCase()}/item`, { type: SELECT_VIEW, payload: { name: viewName } });
}

/**
 * 
 * @param {import("../App").KarachordsState} state 
 * @param {Number} [fallback]
 * @return {Number}
 */
export const getMaxItemsFromState = (state, fallback) => {
  const { parent } = STATE_DATA_PATHS.get(state.main.view.dataType) || {};
  /** @type {import("../appState").DataState} */
  const dataState = get(state, parent, {});
  const { max = fallback } = dataState;
  return max;
}

/**
 * @param {String} event
 * @param {String} element 
 * @param {firebase.analytics.Analytics} analytics 
 */
export const logAnalyticsEvent = (event, element, analytics) => {
  const environment = getEnvironment(window.location.hostname);
  analytics.logEvent(event, { element, environment })
}

/**
 * 
 * @param {String} impressionEvent 
 * @param {String} componentName 
 * @param {firebase.analytics.Analytics} analytics 
 */
export const handleLogImpression = (impressionEvent, componentName, analytics) => () => {
  logAnalyticsEvent(impressionEvent, componentName, analytics);
}

/**
 * 
 * @param {import("../components/dialogs/Info").InfoDialogProps} props 
 * @param {import("../components/dialogs/Info").InfoDialogMethods} methods
 * @param {import("react").Dispatch<import("../actions").Action>} dispatch 
 * @param {() => void} logImpression 
 */
export const handleDisplayLegalDialog = (props, methods, dispatch, logImpression) => () => {
  logImpression();
  /** @type {import("../reducers/functions").OpenDialogPayload} */
  const payload = {
    dialog: DIALOG_NAMES.INFO,
    props,
    methods,
  };
  dispatch({ type: OPEN_DIALOG, payload });
};

/**
 * 
 * @param {import("../reducers/functions").List} list 
 * @param {import("../Context").KarachordsContext} context 
 * @param {(song: import("../reducers/utils").Song) => import("../reducers/utils").Song} callback callback that returns an updated song to be set with a SET_DATA action 
 */
export function updateSongsOnList(list, context, callback) {
  const { dispatch } = context
  const songs = getFullSongsForList(list, context);
  const updatedSongs = songs.map(callback);
  /** @type {import('../reducers/functions').SetDataPayload} */
  const payload = { data: updatedSongs, dataType: DATA_TYPES.songs, shouldOverwrite: false, isSilent: true };
  dispatch({ type: SET_DATA, payload });
}

/**
 * 
 * @param {import("../reducers/functions").List} list 
 * @param {import("../Context").KarachordsContext} context 
 */
export function getFullSongsForList(list, context) {
  const { songs: songIds = [] } = list;
  const songs = songIds.map(({ id }) => context.state.songs.map.get(id));
  return songs;
}

/**
 * 
 * @param {SharedDetails} share Share object from item - if not defined, user is assumed to be owner
 * @param {Set<String>} requiredRolesSet Set of roles allowed to perform action
 * @returns 
 */
export function canUserPeformAction(share, requiredRolesSet) {
  if (!share) {
    return true
  }
  const { role } = share;
  return requiredRolesSet.has(role);
}

/**
 * @param {import("../Context").KarachordsContext} context
 * @param {String} source component name where the dialog was opened from
 * @return {(e:React.SyntheticEvent) => void}
 */
 export const handleOpenUpgradeAccountDialog = (context, source) => () => {
  const { dispatch, analytics } = context;
  const dialog = DIALOG_NAMES.UPGRADE_ACCOUNT;
  /** @type {import("../reducers/functions").OpenDialogPayload} */
  const payload = {
    dialog,
    methods: { 
      onClose: async () => { dispatch({ type: CLOSE_DIALOG }) },
    },
  }
  dispatch({ type: OPEN_DIALOG, payload });
  logAnalyticsEvent(`${dialog}Dialog_impressionFrom_${source}`, `${dialog}`, analytics);
}