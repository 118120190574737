import get from 'lodash.get';
import * as actions from '../../actions';
import { getOne } from '../../utils/persistence';
import * as persistenceConstants from '../../utils/persistence/constants';
import * as constants from '../../constants';
import { getPersistanceErrorMessage } from '../../constants/errors';
import { didPassTests, isSongAndHasFiles, getItemFromContextMap, songsHaveFiles } from './../../utils/persistence/cache';
import { dispatchFetchErrorNotification } from '../../utils/notfications';

/**
 * 
 * @param {any} data 
 */
export const getSuccessResponse = (data) => {
  return { status: 200, statusText: 'OK', data };
}
/**
 * Gets a song. First checks memory, then the cache, then the network
 * @param {import('../forms/song/SongForm').KarachordsContext} context 
 * @param {String} id songId 
 * @param {String} userToken
 * @param {Array<(song:Song) => boolean>} [tests]
 * @return {Promise<import('../../utils/persistence').FetchResponse>}
 */
export const getSongFromNetworkCacheOrMemory = async (context, id, userToken, tests = []) => {
  const data = getItemFromContextMap(context, id, 'state.songs.map');
  const shouldSkipNetwork = didPassTests(data, tests);
  let resp;
  if (shouldSkipNetwork) {
    resp = getSuccessResponse(data);
  } else {
    resp = await getOne(persistenceConstants.SONGS_URL, userToken)(id);
  }
  return resp;
}
/**
 * 
 * @typedef {import('../../reducers/functions').Song} Song 
 */

/**
 * 
 * @param {import('../../Context').KarachordsContext} context
 * @returns {(song:Song) => () => Promise<Void>}
 */
export const getHandlePlaySong = (context) => (song) => async () => {
  const user = context.firebase.auth().currentUser;
  const { dispatch } = context;
  dispatch({ type: actions.SET_LOADING, payload: { value: true } })
  if (!user) {
    console.error('tried to play song but had no user, cancelling load')
    dispatch({ type: actions.SET_LOADING, payload: { value: false } });
    dispatch({ type: actions.ADD_NOTIFICATION, payload: { message: getPersistanceErrorMessage('REAUTH_REQUIRED') } })
    return;
  }
  const userToken = await user.getIdToken();
  const { id } = song;
  const tests = [isSongAndHasFiles]
  let resp = await getSongFromNetworkCacheOrMemory(context, id, userToken, tests);
  const { status } = resp;
  if (status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    dispatch({ type: actions.SET_LOADING, payload: { value: false } })
    return
  }
  const { data } = resp;
  /** @type {import('../../reducers/functions').PlaySongPayload} */
  const payload = { song: data };
  dispatch({ type: actions.PLAY_SONG, payload })
}

// /**
//  * 
//  * @param {Uint8Array} buffer
//  * @return {Promise<Uint8Array>} modifed arrayBuffer
//  */
// const modifyPDF = async (buffer) => {
//   const pdfDoc = await PDFDocument.load(buffer);
//   const pages = pdfDoc.getPages();
//   const firstPage = pages[0];
//   const { width, height } = firstPage.getSize()

//   // Draw a string of text diagonally across the first page
//   firstPage.drawText('This text was added with JavaScript!', {
//     x: 5,
//     y: height / 2 + 300,
//     size: 50,
//     color: rgb(0.95, 0.1, 0.1),
//     rotate: degrees(-45),
//   })
//   const pdfBytes = await pdfDoc.save()
//   return pdfBytes;
// }

// /**
//  * @param {import('../forms/list').List} list
//  * @return {boolean}
//  */
// const listHasFiles = (list) => {
//   return false;
// }
/**
 * @param {string} dataType one of constants.DATA_TYPES
 */
const getUrlFromDataType = (dataType) => {
  if (dataType === constants.DATA_TYPES.lists) {
    return persistenceConstants.LISTS_URL;
  }
  if (dataType === constants.DATA_TYPES.songs) {
    return persistenceConstants.SONGS_URL;
  }
  return null;
}
/**
 * 
 * @param {import('../../constants').Item} tgt 
 * @param {import('../../App').KarachordsState} state 
 * @param {firebase.User} user
 * @param {string} dataType one of constants.DATA_TYPES
 * @returns {Promise<import('./../forms/song/SongForm').Song>}
 */
export const getItemFromNetworkOrCache = async (tgt, state, user, dataType) => {
  const { id } = tgt;
  /** @type {Map<String, import('../../constants').Item>} */
  const stateMap = get(state, constants.STATE_DATA_PATHS.get(constants.DATA_TYPES.songs).map)
  const stateItem = stateMap.get(id);
  if (isSongAndHasFiles(stateItem)) {
    return stateItem;
  }
  if (dataType === constants.DATA_TYPES.lists) {
    const songsPromises = tgt.songs.map(song => getItemFromNetworkOrCache(song, state, user, constants.DATA_TYPES.songs));
    const songs = await Promise.all(songsPromises);
    return Object.assign(tgt, { songs });
  }
  const userID = await user.getIdToken();
  const url = getUrlFromDataType(dataType);
  const resp = await getOne(url, userID)(tgt.id);
  const { data } = resp;
  return data;
}

/**
 * Gets a list. First checks memory, then the cache, then the network
 * @param {import('../forms/song/SongForm').KarachordsContext} context 
 * @param {String} id listId
 * @param {String} userToken
 * @typedef {(list: import('../forms/list/ListSongPlaceholder').List) => boolean} ListTest
 * @param {Array<ListTest>} [tests]
 * @return {Promise<import('../../utils/persistence').FetchResponse>}
 */
export const getListFromNetworkCacheOrMemory = async (context, id, userToken, tests = []) => {
  const data = getItemFromContextMap(context, id, 'state.lists.map');
  const shouldSkipNetwork = didPassTests(data, tests);
  // TODO: instead of didPassTests, get array of songs that failed tests and only retrieve those
  // TODO: Improve this - only get somngs that NEED to be gotten.
  // const shouldSkipNetwork = false;
  // 2. If no state map or not in state map, check cache
  // TODO: Check cache - if it passed tests, get that one
  // 3. If not in cache, go to network
  let resp;
  if (shouldSkipNetwork) {
    resp = getSuccessResponse(data);
  } else {
    const getSong = getOne(persistenceConstants.SONGS_URL, userToken);
    /** @type {Array<import('../../utils/persistence').FetchResponse>} */
    const songsPromises = data.songs.map(({ id }) =>  getSong(id));
    const responses = await Promise.all(songsPromises);

    const errorResp = responses.reduce((acc, resp) => {
      const { error, status, statusText } = resp;
      if (acc) {
        return acc;
      }
      if (error) {
        return { status, error, statusText }
      }
      return acc
    }, null)

    if (errorResp) {
      resp = errorResp;
    } else {
      const songs = responses.map(({data}) => data);
      resp = getSuccessResponse(Object.assign({}, data, {songs}));
    }
    
  }
  return resp
}
/**
 * 
 * @param {import('../../Context').KarachordsContext} context
 * @returns {(list:import('./../../constants').Item) => () => Promise<Void>}
 */
export const getHandlePlaySetlist = (context) => (list) => async () => {
  const user = context.firebase.auth().currentUser;
  const { dispatch } = context;
  dispatch({ type: actions.SET_LOADING, payload: { value: true } })
  const { id } = list;
  const userToken = await user.getIdToken();
  if (!user) {
    console.error('tried to play song but had no user, cancelling load')
    dispatch({ type: actions.SET_LOADING, payload: { value: false } })
    dispatch({ type: actions.ADD_NOTIFICATION, payload: { message: getPersistanceErrorMessage('REAUTH_REQUIRED') } })
    return;
  }
  const tests = [songsHaveFiles];
  const resp = await getListFromNetworkCacheOrMemory(context, id, userToken, tests);
  const { status, data } = resp;
  if ( status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    dispatch({ type: actions.SET_LOADING, payload: { value: false } })
    return
  }
  /** @type {import('./../../reducers/functions').PlaySetlistPayload} */
  const payload = { list: data };
  dispatch({ type: actions.PLAY_SETLIST, payload });
}

