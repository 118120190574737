import { getPersistanceErrorMessage } from '../../constants/errors';
import { ADD_NOTIFICATION } from '../../actions';


/**
 * 
 * @param {import('../../utils/persistence').FetchResponse} resp 
 * @param {(action:import('../../actions').Action) => void} dispatch 
 */
export const dispatchFetchErrorNotification = (resp, dispatch) => {
  const { error } = resp;
  const message = getPersistanceErrorMessage(error);
  /** @type {import('notistack').OptionsObject} */
  const options = { variant: 'error' }
  /** @type {import('../../reducers/functions').AddNotificationPayload} */
  const payload = { message, options };
  dispatch({ type: ADD_NOTIFICATION, payload });
};

/**
 * 
 * @param {String} message 
 * @param {(action:import('../../actions').Action) => void} dispatch 
 */
export const dispatchSuccessNotification = (message, dispatch) => {
  /** @type {import('notistack').OptionsObject} */
  const options = { variant: 'success' }
  /** @type {import('../../reducers/functions').AddNotificationPayload} */
  const payload = { message, options };
  dispatch({ type: ADD_NOTIFICATION, payload });
}