import { makeStyles } from '@material-ui/core'

export const useCardStyles = makeStyles((theme) => {
  const spacing = theme.spacing();
  return {
    textCenter: {
      textAlign: 'center'
    },
    gutters: {
      paddingLeft: `${spacing*2}px`,
      paddingRight: `${spacing*2}px`,
      paddingBottom: `${spacing}px`
    }
  }
})