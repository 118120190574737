import { Button, CircularProgress, DialogActions, DialogContent, Grid, Input, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAppStyles } from '../../appStyles';
import { ROLES, ROLE_COPY, SHARE_TYPES, SHARE_TYPE_TO_DATA_TYPE, STATE_DATA_PATHS } from '../../constants';
import { Context } from '../../Context';
import { dispatchSuccessNotification } from '../../utils/notfications';
import { RevokeAllSharesButton } from '../buttons/RevokeAll';
import { RevokeShareButton } from '../buttons/RevokeShare';
import { getShareForItem, getUpdatedItemFromGetShareData, handleOpenShareDialog } from '../forms/utils';
import { getInitialShareForSong, getRoleCopy, hideRevokeAll, isSongWithListShares } from '../utils';
import { DialogHeader } from './DialogHeader';
import { handleCloseDialog } from './utils';

/**
 * @param {import('../../constants').Item} item
 * @param {String} shareType
 * @param {Map<String, import('../../reducers/functions').AssignableRole>} rolesMap
 * @param {(shareId:String) => void} setShareId
 * @param {(role:String) => void} setRoleId
 * @param {(item: import('../../reducers/functions').Item) => void} setItem
 * @param {(isLoading:Boolean) => void} setIsLoading
 * @param {import('../../Context').KarachordsContext} context
 * @return {(e: React.ChangeEvent<{name?: string, value: string}>) => void}
 */
const handleChangeRole = (item, shareType, rolesMap, setShareId, setRoleId, setItem, setIsLoading, context) => async (e) => {
  const roleId = e.target.value;
  await changeRole(setIsLoading, rolesMap, roleId, item, shareType, context, setRoleId, setShareId, setItem);
};


const copy = {
  howSharingWorks: [
    "A user clicking this link gains access to this item with the selected permission",
    "You can revoke access for links you've shared at any time",
    "Users can not re-share an item shared with them"
  ],
  aboutSharingLists: [
    "Sharing a list will also share all the items on it",
  ]
}
let tooltipTimeout;
const LINK_BASE = window.location.origin;
export const ShareDialog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const { spacingTop, doubleSpacingTop, italic, textCenter, spacingBottom, flex } = useAppStyles();
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { dispatch, state } = context;
  /** @type {import('../../appState').DialogProps} */
  const props = get(state, 'dialog.props', {});
  const { itemType: shareType, item: initialItem, share: initialShare } = props;
  
  const { id: initialShareId, roles } = initialShare;
  const rolesMap = new Map(roles.map(role => [role.id, role]));
  const [selectedShareId, setSelectedShareId] = useState(initialShareId);
  const [item, setItem] = useState(initialItem);
  const { shares = [], name} = item;
  const role = get(item, 'share.role', ROLES.owner)
  const sharesMap = new Map(shares.map(share => [share.id, share]));
  if (!sharesMap.has(initialShareId)) {
    sharesMap.set(initialShareId, initialShare);
  }
  const initialRole = roles.find((role) => {
    const { name } = role;
    return name === initialShare.role;
  })
  const [roleId, setRoleId] = useState(initialRole.id);
  const selectedRole = rolesMap.get(roleId);
  const linkInputRef = useRef({ select: () => { } });
  const link = `${LINK_BASE}?share=${selectedShareId}`;
  const itemIsList = shareType === SHARE_TYPES.list;

  useEffect(() => { 
    return () => {cleanupTimeout(tooltipTimeout)}
  }, []);
  return (
    <React.Fragment>
      <DialogHeader title={"Share"} isLoading={isLoading} dispatch={dispatch} />

      <DialogContent>
        <Grid container item justifyContent={"center"} alignItems={"center"}>
          <Typography className={italic} variant={"h6"}>
            {name}
          </Typography>
        </Grid>
        <Grid direction={"column"} container item>

          <InputLabel>
            {"Role"}
          </InputLabel>

          <Select
            name={"role"}
            value={selectedRole.id}
            onChange={
              handleChangeRole(
                item,
                shareType,
                rolesMap,
                setSelectedShareId,
                setRoleId,
                setItem,
                setIsLoading,
                context
              )
            }
            disabled={isLoading}
          >
            {roles.map(({ name, id }) => {
              const copy = getRoleCopy(name, ROLE_COPY);
              return (
                <MenuItem key={id} value={id}>
                  {copy.display}
                </MenuItem>
              )
            })}
          </Select>
          <Typography className={spacingTop}>
            {getRoleCopy(selectedRole.name, ROLE_COPY).description}
          </Typography>
          <InputLabel className={doubleSpacingTop}>
            {"Link"}
          </InputLabel>
          <Tooltip arrow placement={"top"} title={"Copied!"} open={tooltipIsOpen} onOpen={() => {
            cleanupTimeout(tooltipTimeout);
            tooltipTimeout = setTimeout(() => {
              setTooltipIsOpen(false)
            }, 2000);
          }}>
            <Input type={'text'}
              disabled={isLoading}
              disableUnderline={true}
              readOnly={true}
              value={link}
              inputRef={linkInputRef}
            />
          </Tooltip>
          <Button
            className={spacingTop}
            variant={"contained"}
            color={"primary"}
            startIcon={<Link />}
            disabled={isLoading}
            onClick={async () => {
              if (tooltipIsOpen) {
                setTooltipIsOpen(false)
              }
              if (linkInputRef && linkInputRef.current) {
                linkInputRef.current.select();
              }
              await window.navigator.clipboard.writeText(link);
              setTooltipIsOpen(true);
            }}>
            {"Copy"}
          </Button>
          <Alert severity={"info"} className={clsx(spacingTop)}>
            <AlertTitle>
              {"How sharing works"}
            </AlertTitle>
            <Grid container item justifyContent={"center"} alignItems={"center"} className={textCenter}>
              {copy.howSharingWorks.map(line => (<Typography variant={"caption"} className={clsx(spacingBottom, flex)} key={line}> {line} </Typography>))}
            </Grid>
          </Alert>
          {
            itemIsList &&
            (
              <Alert className={spacingTop} severity={"warning"}>
                {copy.aboutSharingLists}
              </Alert>
            )
          }
          <List className={spacingTop}>
            {shares.map(share => {
              const { type } = share;
              const shareIsList = type === SHARE_TYPES.list;
              if (shareIsList) {
                if (!itemIsList) {
                  return null;
                }
              }
              return (
                <ListItem key={share.id}>
                  <ListItemText
                    primary={`Shared Role: ${getRoleCopy(share.role, ROLE_COPY).display}`}
                  />
                  <ListItemSecondaryAction>
                    <RevokeShareButton share={share} item={item} afterClose={(e) => {
                      const { id } = item;
                      const dataType = SHARE_TYPE_TO_DATA_TYPE[shareType];
                      const mapPath = STATE_DATA_PATHS.get(dataType).map;
                      const map = get(state, mapPath);
                      /** @type {import('../../reducers/functions').Item} */
                      const updatedItem = map.get(id);
                      const { shares: updatedShares = [] } = updatedItem;
                      let initialShare;
                      if (shareType === SHARE_TYPES.song) {
                        initialShare = getInitialShareForSong(updatedShares, roles)
                      } else {
                        initialShare = updatedShares[0] ? Object.assign(updatedShares[0], { roles }) : null;
                      }
                      if (initialShare) {
                        handleOpenShareDialog(
                          context,
                          updatedItem,
                          shareType,
                          undefined,
                          initialShare
                        )(e);
                        return
                      }
                      dispatchSuccessNotification(`Revoked last of your shares for ${item.name}`, dispatch);
                    }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
            {(!hideRevokeAll(shares, role) && !isSongWithListShares(item)) && (
              <ListItem>
                <ListItemText primary={"All shared roles"} />
                <ListItemSecondaryAction>
                  <RevokeAllSharesButton item={item} />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button variant={"text"} color={"default"} onClick={handleCloseDialog(dispatch)} disabled={isLoading}>
          {"Close"}
        </Button>
        {isLoading && <CircularProgress />}
      </DialogActions>
    </React.Fragment >
  );
}


function cleanupTimeout(timeoutId) {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
}

async function changeRole(setIsLoading, rolesMap, roleId, item, itemType, context, setRoleId, setShareId, setItem) {
  setIsLoading(true);
  const role = rolesMap.get(roleId);
  const share = await getShareForItem(item, itemType, context, role);
  const updatedItem = getUpdatedItemFromGetShareData(share, item);
  const { id } = share;
  setRoleId(roleId);
  setShareId(id);
  setItem(updatedItem);
  setIsLoading(false);
}

