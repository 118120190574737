import { SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { usePlayStyles } from "./PlayStyles";
import { getLookedUpSymbol, getPathProps, getScaledInputStyle } from './utils';

/**
 * @typedef {Object} DraggableItemProps
 * @property {import('./utils').Note} note
 * @property {Number} pageScale
 * @param {DraggableItemProps} props
 */

export const DraggableSymbol = (props) => {
  const { note, pageScale } = props;
  const { id, type } = note;
  const { draggableItem } = usePlayStyles();
  const inputStyle = getScaledInputStyle(note, pageScale);
  const { symbol } = note;
  const { name } = symbol;
  const lookedUpSymbol = getLookedUpSymbol(type, name, symbol);
  if (!lookedUpSymbol) {
    console.error('unable to get symbol information for symbol', name, 'type', type, 'id', id, 'returning nothing');
    return null;
  }
  const { paths, viewBox, transform } = lookedUpSymbol;
  const gProps = transform ? {transform} : {};
  return (
    <SvgIcon
      className={clsx(draggableItem)}
      style={inputStyle}
      viewBox={viewBox}
    >
      <g {...gProps}>
      {paths.map((path, index) => {
        const pathProps = getPathProps(path);
        return (<path key={`note-${id}-symbol-path-${index}`} {...pathProps}/>)
      })}
      </g>
    </SvgIcon>

  );
};
