import React, { useContext } from 'react';
import { usePlayStyles } from "./PlayStyles";
import { PlayContext } from './PlayContext';
import { removeNote } from './utils';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const handleRemove = (activeNote, notes, setNotes, setActiveNote) => () => {
  removeNote(activeNote, notes, setNotes);
  setActiveNote(null);
};
export const RemoveNoteButton = (props) => {
  const { note, isActive } = props;
  const { removeNoteButton } = usePlayStyles();
  const { methods, state } = useContext(PlayContext);
  const { notes } = state;
  const { setNotes, setActiveNote } = methods;
  if (!isActive) {
    return null;
  }
  return (
    <IconButton
      className={removeNoteButton}
      onClick={handleRemove(note, notes, setNotes, setActiveNote)}
    >
      <Close />
    </IconButton>
  );
};
