import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from 'react';
import { useDialogStyles } from "./styles";

/**
 * @typedef {Object} SharesSummaryProps
 * @property {Array<SharedDetails>} shares
 *
 * @param {SharesSummaryProps} props
 * @returns
 */
export const SharesSummary = (props) => {
  const { shares = [] } = props;
  const { hidden } = useDialogStyles();
  const sharesArePlural = shares.length > 1;
  return (
    <React.Fragment>
      <Grid item className={clsx({ [hidden]: shares.length < 1 })}>
        <Typography>
          {`You've shared ${shares.length} ${sharesArePlural ? 'roles' : 'role'} on this item`}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};
