import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack, Group, MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useAppStyles } from '../../../appStyles';
import { Context } from '../../../Context';
import { handleOpenShareDetailsDialog } from '../../lists/utils';
import { SongFormHeaderMenu } from './SongFormHeaderMenu';
import { handleSelectLibraryView } from './utils';



export const SongFormHeader = (props) => {
  const { title, setMenuAnchorEl, menuAnchorEl, initialValues, isLoading, setIsLoading } = props;
  const { id, share, shares } = initialValues;
  const hasShares = share || shares && shares.length > 0;
  const { textCenter, noWrap, autoWidth } = useAppStyles();
  /** @type {import('./SongForm').KarachordsContext} */
  const { dispatch } = useContext(Context);
  return (
    <Grid container item direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
      <Grid item xs={4}>
        <IconButton onClick={handleSelectLibraryView(dispatch)}>
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid className={textCenter} item xs={4}>
        <Typography variant={'h5'} gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid xs={4} item container direction={"row"} alignItems={"center"} justifyContent={"flex-end"} className={clsx(autoWidth, noWrap)}>
        {hasShares && (
          <Grid item>
            <IconButton onClick={handleOpenShareDetailsDialog(initialValues, dispatch)}>
              <Group />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <IconButton
            disabled={!id || isLoading}
            onClick={e => {
              setMenuAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
          <SongFormHeaderMenu
            initialValues={initialValues}
            menuAnchorEl={menuAnchorEl}
            setMenuAnchorEl={setMenuAnchorEl}
            setIsLoading={setIsLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  )
};
