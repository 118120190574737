import { makeStyles } from '@material-ui/core/styles';
import { getBreakpoints } from './utils/theme';
import { getDrawerClosedSpacing } from './components/drawers/styles';

/**
 *
 * @param {import('@material-ui/core/styles').Theme} theme
 */
const getAppStyles = (theme) => {
  const { mobilePortrait, laptop } = getBreakpoints(theme);
  const drawerSpacing = getDrawerClosedSpacing(theme);
  const spacing = theme.spacing();
  return {
    root: {
      flexDirection: 'row',
      overflow: 'auto',
      height: '100vh',
      width: '100vw',
    },
    subMenuMobileItemSelected: {
    },
    subMenuDrawerClosed: {
    },
    subMenuContainer: {
      width: '100%'
    },
    subMenuDrawerOpen: {
      display: 'inherit'
    },
    subMenuNoItems: {
      display: 'none'
    },
    mainContainer: {
      height: '100vh',
      width: `100%`,
      overflow: 'hidden auto',
      paddingTop: theme.spacing(8),
      paddingLeft: `${drawerSpacing}px`,
      [mobilePortrait]: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(7),
        paddingLeft: 0,
      },
    },
    mainContainerNoSubMenu: {
    },
    mainContainerMenuOpenNoSubMenu: {
    },
    mainContainerMenuOpen: {
      paddingLeft: `12.25%`,
      [laptop]: {
        paddingLeft: `25%`,
      },
      [mobilePortrait]: {
        maxWidth: '100%',
        flexBasis: '100%',
        paddingLeft: '0px'
      }
    },
    mainContainerSubMenuNoItemSelected: {
    },
    menuButton: {},
    hide: {
      display: 'none !important'
    },
    notificationsContainer: {
      [mobilePortrait]: {
        bottom: '56px'
      }
    },
    copyright: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
      display: 'flex',
    },
    textCenter: {
      textAlign: 'center',
    },
    flex: {
      display: 'flex'
    },
    spacingTop: {
      marginTop: `${spacing}px`
    },
    spacingBottom: {
      marginBottom: `${spacing}px`
    },
    doubleSpacingBottom: {
      marginBottom: `${spacing*2}px`
    },
    doubleSpacingTop: {
      marginTop: `${spacing*2}px`
    },
    spacingRight: {
      marginRight: `${spacing}px`
    },
    italic: {
      fontStyle: 'italic',
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    autoWidth: {
      width: 'auto',
    },
    fullWidth: {
      width: '100%'
    }
  };
};
// @ts-ignore
export const useAppStyles = makeStyles(getAppStyles);
