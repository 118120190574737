import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { ClearAll } from '@material-ui/icons';
import React from 'react';
import { handleShowConfirmActionDialog } from '../../dialogs/utils';
import { handleConfirmClearAnnotations } from './utils';
import { copy } from './SongFormHeaderMenu';

export const ClearAnnotationsMenuItem = (props) => {
  const { hidden, context } = props;
  const { dispatch } = context;
  if (hidden) {
    return null;
  }
  return (
    <MenuItem onClick={handleShowConfirmActionDialog(
      copy.clearAll,
      handleConfirmClearAnnotations(props.songId, props.fileId, context),
      dispatch
    )}>
      <ListItemIcon>
        <ClearAll />
      </ListItemIcon>
      <ListItemText>
        {"Delete annotations"}
      </ListItemText>
    </MenuItem>
  );
};
