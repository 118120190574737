import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { DroppableListSongContainer } from './DroppableListSongContainer';
import { Context } from '../../../Context';
import { ListFormHeader } from './ListFormHeader';
import { ListFormActions } from './ListFormActions';
import { EDIT_ROLES, FORM_STRING_INPUT_MAX_LENGTH, ROLES } from '../../../constants';
import get from 'lodash.get';
import clsx from 'clsx';
import { useAppStyles } from '../../../appStyles';

export const copy = {
  title: {
    edit: 'Edit setlist',
    new: 'Add setlist'
  },
  confirm: {
    clear: 'This will remove all library items from this setlist. This action is irreversible.',
    delete: 'This will delete this setlist. Libray items on the setlist will not be deleted. This action is irreversible.'
  }
}

export const ListForm = (props) => {
  const { editingList, onSubmit, isLoading, handleDragEnd, songs, handleRemove, handleAdd, setLoading, setEditingList } = props;
  /** @type {import('../song/SongForm').KarachordsContext} */
  const context = useContext(Context);
  const maxSize = context.state?.lists?.maxSize;
  const { doubleSpacingTop, spacingTop } = useAppStyles();
  const { dispatch } = context;
  let title = copy.title.new;
  const { id } = editingList
  if (editingList && id) {
    title = copy.title.edit;
  }
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const role = get(editingList, 'share.role', ROLES.owner);
  const canEdit = EDIT_ROLES.has(role);
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={editingList}
    >
      {(props) => {
        const { isSubmitting } = props;
        const areButtonsDisabled = isLoading || isSubmitting;
        return (
          <React.Fragment>
            <ListFormHeader
              areButtonsDisabled={areButtonsDisabled}
              editingList={editingList}
              setLoading={setLoading}
              setEditingList={setEditingList}
              context={context}
              dispatch={dispatch}
              menuAnchorEl={menuAnchorEl}
              setMenuAnchorEl={setMenuAnchorEl}
              title={title}
              maxSize={maxSize}
            />
            <Grid container item>
              <Form style={{ display: 'contents', padding: '8px' }}>
                <Grid container item>
                  <Field
                    style={{ width: '100%' }}
                    type="text"
                    name="name"
                    label="Setlist Name"
                    component={TextField}
                    inputProps={{ maxLength: FORM_STRING_INPUT_MAX_LENGTH, disabled: !canEdit }}
                  />
                </Grid>

                <Grid
                  item
                  id={"list-form-droppable-container"}
                  container direction={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  className={clsx(doubleSpacingTop)}
                >
                  <DroppableListSongContainer
                    onDragEnd={handleDragEnd}
                    songs={songs}
                    remove={handleRemove}
                    add={handleAdd}
                    canEdit={canEdit}
                  />
                </Grid>
                <Grid container item justifyContent={"flex-end"} alignItems={"center"} spacing={2} className={clsx(spacingTop)}>
                  <ListFormActions
                    areButtonsDisabled={areButtonsDisabled}
                    editingList={editingList}
                    setLoading={setLoading}
                    isLoading={isLoading}
                  />
                </Grid>
              </Form>
            </Grid>
          </React.Fragment>
        )
      }}
    </Formik>
  )
}