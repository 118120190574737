import { DELETE_DATA } from "../../actions";
import { DATA_TYPES, DELETE_SHARE_TYPES, SHARE_TYPES, SHARE_TYPE_TO_DATA_TYPE } from "../../constants";
import { getFullSongsForList } from "../../utils";
import { dispatchFetchErrorNotification } from "../../utils/notfications";
import { revokeShare } from "../../utils/persistence";
import { deleteActiveStateItem } from "../utils";

/**
 * 
 * @param {import("../../Context").KarachordsContext} context 
 * @param {import("../../constants").Item} item 
 * @return {(e?: React.SyntheticEvent) => Promise<void>}
 */
export const handleRemoveSharedItem = (context, item) => async () => {
  const { dispatch, firebase } = context;
  const { id, share } = item;
  const params = new URLSearchParams();
  params.append('type', DELETE_SHARE_TYPES.remove);
  const userToken = await firebase.auth().currentUser.getIdToken();
  const resp = await revokeShare(userToken, id, params)
  if (resp.status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    return
  }
  const { type } = share;
  const dataType = SHARE_TYPE_TO_DATA_TYPE[type]
  if (type === SHARE_TYPES.list) {
    const songs = getFullSongsForList(item, context);
    const removedSongs = songs.slice().filter((song) => {
      return song.share;
    })
    removedSongs.forEach((song) => {
      const { id } = song;
      /** @type {import("../../reducers/functions").DeleteDataPayload} */
      const payload = {id, dataType: DATA_TYPES.songs}
      dispatch({type: DELETE_DATA, payload});
    })
  }
  deleteActiveStateItem(dispatch, id, dataType, "Successfully removed shared item!");
}