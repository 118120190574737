import React, { useContext } from 'react';
import { ListItem } from '@material-ui/core';
import { ListItemIcon } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { useDrawerStyles } from "./styles";
import { Context } from '../../Context';
import { SELECT_VIEW } from '../../actions';

/**
 * @typedef {React.Props & import('../../constants').KarachordsView} MenuDrawerOptionProps
 * @property 
 * @param {MenuDrawerOptionProps} props 
 */
export const MenuDrawerOption = (props) => {
  const { name, text, Icon } = props;
  const classes = useDrawerStyles();
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { dispatch } = context;
  const currentView = context.state.main.view;
  const isSelectedView = (currentView && currentView.name === name);
  const selectView = () => dispatch({ type: SELECT_VIEW, payload: { name } })
  return (
      <ListItem selected={isSelectedView} button className={classes.menuButton} onClick={selectView}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} className={classes.menuItem} />
      </ListItem>
  );
};
