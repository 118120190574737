import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { Context } from '../../Context';
import { TOGGLE_DRAWER } from '../../actions';

import clsx from 'clsx';
import { useDrawerStyles } from './styles';

export const DrawerMenuButton = () => {
  /** @type { import('../../Context').KarachordsContext } */
  const context = useContext(Context);
  const { dispatch, state } = context;
  const { menuItem, menuToggleOpenButton } = useDrawerStyles();
  const toggleDrawer = () => dispatch({ type: TOGGLE_DRAWER })
  const isOpen = state.nav.menu.isOpen;
  return (
    <Grid
      container
      item
      className={clsx(menuItem, {
        [menuToggleOpenButton]: !isOpen
      })}
      justifyContent={isOpen ? 'flex-end' : 'center'}
    >
      <IconButton onClick={toggleDrawer}>
        {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>
    </Grid>
  );
}