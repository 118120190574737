import { ADD_NOTIFICATION, SELECT_ITEM, SELECT_VIEW, SET_DATA } from '../../../actions';
import { ADD_TO_SHARED_LIST_COPY, DATA_TYPES, SHARE_TYPES, VIEW_NAMES } from '../../../constants';
import { getPersistanceErrorMessage } from '../../../constants/errors';
import { updateHistoryAfterSave } from '../../../utils';
import { dispatchFetchErrorNotification, dispatchSuccessNotification } from '../../../utils/notfications';
import { deleteOne, getMethod, getUrl, saveOne } from '../../../utils/persistence';
import { LISTS_URL } from '../../../utils/persistence/constants';
import { handleShowConfirmActionDialog } from '../../dialogs/utils';
import { deleteActiveStateItem } from '../../utils';


/**
 * @param {React.Dispatch<Element>} setMenuAnchorEl
 * @return {(e:React.SyntheticEvent) => void} e 
 */
export const handleOpenMenu = (setMenuAnchorEl) => (e) => {
  setMenuAnchorEl(e.currentTarget)
};

/**
 * @param {React.Dispatch<Element>} setMenuAnchorEl
 * @return {(e:React.SyntheticEvent) => void} e 
 */
export const handleCloseMenu = (setMenuAnchorEl) => () => {
  setMenuAnchorEl(null);
}
/**
 * 
 * @param {import('.').List} editingList 
 * @param {import('react').Dispatch<import('.').List>} setEditingList
 * @return {(song:import('../../drawers/utils').Song) => (e:React.SyntheticEvent) => void}
 */
export function getHandleAddSongtoList(editingList, setEditingList) {
  return (song) => () => {
    const { songs = [] } = editingList;
    const updatedSongs = songs.slice();
    const { id, name, artist } = song;
    updatedSongs.push({ id, name, artist });
    const updatedList = Object.assign({}, editingList, {songs: updatedSongs});
    setEditingList(updatedList);
  };
}
/**
 * 
 * @param {import('.').List} editingList 
 * @param {import('react').Dispatch<import('.').List>} setEditingList
 * @return {(targetIndex: Number) => (e:React.SyntheticEvent) => void}
 */
export function getHandleRemoveSongFromlist(editingList, setEditingList) {
  return (targetIndex) => () => {
    const { songs: oldSongs } = editingList;
    const newSongs = oldSongs.filter((song, index) => {
      if (index !== targetIndex) {
        return true;
      }
      return false;
    });
    const newList = Object.assign({}, editingList, { songs: newSongs });
    setEditingList(newList);
  };
}

/**
 * 
 * @param {import('.').List} editingList 
 * @param {import('react').Dispatch<import('.').List>} setEditingList
 * @param {import('../song/SongForm').KarachordsContext} context
 * @return {(e:React.SyntheticEvent) => Promise<void>}
 */
export const handleClearList = (editingList, setEditingList, context) => async () => {
  const { dispatch } = context
  const list = Object.assign({}, editingList, { songs: [] });
  setEditingList(list);
  /** @type {import('../../../reducers/functions').SelectItemPayload} */
  const selectItemPayload = { item: list };
  /** @type {import('../../../reducers/functions').SetDataPayload} */
  const setDataPayload = { data: [list], dataType: DATA_TYPES.lists };
  dispatch({ type: SELECT_ITEM, payload: selectItemPayload });
  dispatch({ type: SET_DATA, payload: setDataPayload });
  dispatchSuccessNotification('Successfully cleared list!', dispatch);
  // dispatch select item
  // dispatch setData
  // dispatch add notification
}
/**
 * 
 * @param {import('.').List} editingList 
 * @param {import('react').Dispatch<import('.').List>} setEditingList
 * @return {(result:import('react-beautiful-dnd').DropResult) => void}
 */
export function handleOnDragEnd(editingList, setEditingList) {

  return (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const oldIndex = source.index;
    const newIndex = destination.index;
    if (oldIndex === newIndex) {
      return;
    }

    const { songs } = editingList;
    const newSongs = songs.slice(0);
    const [movedSong] = newSongs.splice(oldIndex, 1);
    newSongs.splice(newIndex, 0, movedSong);
    const newList = Object.assign({}, editingList, { songs: newSongs });
    setEditingList(newList);
  };
}

/**
 * 
 * @param {import('../../../Context').KarachordsContext} context
 * @param {import('.').List} list
 * @param {(value:boolean) => void} setLoading
 * @returns {(event:import('react').SyntheticEvent) => Promise<void>}
 */
export const handleDeleteList = (context, list, setLoading) => async (e) => {
  e.preventDefault();
  const { dispatch } = context;
  const { id } = list;
  setLoading(true);
  const user = context.firebase.auth().currentUser;
  const userToken = await user.getIdToken();
  const resp = await deleteOne(LISTS_URL, userToken)(id);
  const { status } = resp;
  if (status !== 200) {
    dispatchFetchErrorNotification(resp, dispatch);
    return;
  }
  deleteActiveStateItem(dispatch, resp.id, DATA_TYPES.lists, "List deleted successfully!");
}

/**
 * 
 * @param {String} name 
 * @param {Array<import('../song/SongForm').Song>} songs 
 * @param {String} id 
 * @param {(Object) => void} setErrors 
 * @param {import('../../../Context').KarachordsContext} context 
 */
const handleSaveList = (name, songs, id, setErrors, context) => async () => {
  const { dispatch, state } = context
  if (!name) {
    console.error('handleListFormSubmit() - attempted to save a list without a name');
    setErrors({ name: "Please provide a name" });
    return;
  }
  if (!songs) {
    console.error('handleListFormSubmit() - attempted to save a list without songs');
    const message = "Unable to save an empty setlist. Please add one or more items";
    /** @type {import('../../../reducers/functions').AddNotificationPayload} */
    const payload = { message, options: { variant: "error" } };
    dispatch({ type: ADD_NOTIFICATION, payload });
    return;
  }

  const user = context.firebase.auth().currentUser;
  let userToken;
  try {
    userToken = await user.getIdToken();
  } catch (e) {
    return handleGetIdTokenError(e, dispatch);
  }
  const filteredSongs = songs.map((song) => {
    const { id } = song;
    return { id };
  });
  const reqData = { name, songs: filteredSongs, id };
  const request = getSaveListRequest(id, userToken);
  const resp = await saveOne(request)(reqData);
  const { data, status, error } = resp;
  if (status !== 200) {
    console.error(error);
    dispatchFetchErrorNotification(resp, dispatch);
    return;
  }
  updateAfterSuccessfulListSave(data, dispatch, state);
};

/**
 * 
 * @param {import('../../../reducers/functions').List & import('../../../reducers/functions').ItemShares} list 
 * @param {import('../../../Context').KarachordsContext} context
 * @param {(isLoading: Boolean) => void} setLoading
 * @return {(values: import('formik').FormikValues, helpers:import('formik').FormikHelpers) => void}
 */
export const handleListFormSubmit = (list, context, setLoading) => async (values, { setErrors, setSubmitting }) => {
  setSubmitting(true);
  setLoading(true);
  const { name, id } = values;
  const { songs = [], share } = list;
  const { dispatch } = context;
  const saveList = handleSaveList(name, songs, id, setErrors, context);
  if (share) {
    // if the list was shared with this user, make sure they confirm this save before we give out access
    handleShowConfirmActionDialog(ADD_TO_SHARED_LIST_COPY, saveList, dispatch)();
    // setSubmitting(false);
    return
  }
  await saveList();
}

/**
 * 
 * @param {import('.').List} data 
 * @param {React.Dispatch<import('../../../actions').Action>} dispatch 
 * @param {import('../../../appState').KarachordsState} state 
 */
const updateSongsInState = (data, state, dispatch) => {
  const { songs: newListSongs, id: listId } = data;
  // get list of any removed songs by comparing old list and new list
  /** @type {Array<import('../../play').Song & {share: SharedDetails}>} */
  const currentSongs = state.songs.list;
  const newListSongsMap = getMapById(newListSongs);
  const sharedSongsToRemove = getSharedSongsToRemove(currentSongs, newListSongsMap, listId);
  const removeSongsMap = getMapById(sharedSongsToRemove);
  const updatedSongs = [];
  currentSongs.forEach((song) => {
    const { id } = song;
    if (!removeSongsMap.has(id)) {
      updatedSongs.push(song);
    }
  });
  /** @type {import('../../../reducers/functions').SetDataPayload} */
  const setDataPayload = {data: updatedSongs, dataType: DATA_TYPES.songs, shouldOverwrite: true, isSilent: false};
  dispatch({type: SET_DATA, payload: setDataPayload});
  // remove them, and set_data with the new songs
}

function getSharedSongsToRemove(currentSongs, newListSongsMap, listId) {
  return currentSongs.filter((currentSong) => {
    const { id } = currentSong;
    if (!newListSongsMap.has(id)) {
      const { share } = currentSong;
      if (share) {
        const { type, source } = share;
        const { id } = source;
        return type === SHARE_TYPES.list && id === listId;
      }
    }
    return false;
  });
}

function getMapById(items = []) {
  return items.reduce((map, song) => {
    map.set(song.id, song);
    return map;
  }, new Map());
}

/**
 * 
 * @param {import('.').List} data 
 * @param {React.Dispatch<import('../../../actions').Action>} dispatch 
 * @param {import('../../../appState').KarachordsState} state 
 */
function updateAfterSuccessfulListSave(data, dispatch, state) {
  const updatedList = getUpdatedList(data, state);
  updateListInState(updatedList, dispatch);
  updateSongsInState(data, state, dispatch);
  updatedSelectedItem(updatedList, dispatch);
  updateHistoryAfterSave(state);
  /** @type {import('../../../reducers/functions').AddNotificationPayload} */
  displaySuccessNotification(dispatch);
}

/**
 * 
 * @param {React.Dispatch<import('../../../actions').Action>} dispatch
 */
function displaySuccessNotification(dispatch) {
  const addSuccessNotificationPayload = { message: 'List saved successfully!', options: { variant: "success" } };
  dispatch({ type: ADD_NOTIFICATION, payload: addSuccessNotificationPayload });
}

/**
 * 
 * @param {import('.').List} updatedList
 * @param {React.Dispatch<import('../../../actions').Action>} dispatch 
 */
function updatedSelectedItem(updatedList, dispatch) {
  /** @type {import('./../../../reducers/functions').SelectItemPayload} */
  const selectItemPayload = { item: updatedList };
  dispatch({ type: SELECT_ITEM, payload: selectItemPayload });
}

/**
 * 
 * @param {import('.').List} updatedList
 * @param {React.Dispatch<import('../../../actions').Action>} dispatch 
 */
function updateListInState(updatedList, dispatch) {
  /** @type {import('../../../reducers/functions').SetDataPayload} */
  const setDataPayload = { data: [updatedList], dataType: DATA_TYPES.lists, shouldOverwrite: false, isSilent: true };
  dispatch({ type: SET_DATA, payload: setDataPayload });
}

function getUpdatedList(data, state) {
  const { id: listId } = data;
  const currentList = state.lists.map.get(listId);
  const updatedList = Object.assign({}, currentList, data);
  return updatedList;
}

/**
 * 
 * @param {String} id 
 * @param {String} userToken 
 * @returns 
 */
function getSaveListRequest(id, userToken) {
  const url = getUrl(LISTS_URL, id);
  const method = getMethod(id);
  /** @type {import('../../../utils/persistence').SaveOneRequestOptions} */
  const options = { method };
  /** @type {import('../../../utils/persistence').SaveOneRequest} */
  const request = { url, userToken, options };
  return request;
}

/**
 * 
 * @param {React.SyntheticEvent} e 
 * @param {React.Dispatch<import('../../../actions').Action>} dispatch 
 */
function handleGetIdTokenError(e, dispatch) {
  console.error(e);
  /** @type {import('../../../reducers/functions').AddNotificationPayload} */
  const payload = { message: getPersistanceErrorMessage('REAUTH_REQUIRED'), options: { variant: "error" } };
  dispatch({ type: ADD_NOTIFICATION, payload });
  return;
}

/**
 * 
 * @param {React.Dispatch<import('../../../actions').Action>} dispatch
 * @return {(e:React.SyntheticEvent) => void}
 */
export const handleSelectSetlistView = (dispatch) => (e) => {
  e.preventDefault();
  /** @type {import('../../../reducers/functions').SelectViewPayload} */
  const payload = { name: VIEW_NAMES.SETLISTS, item: null };
  dispatch({ type: SELECT_VIEW, payload })
}