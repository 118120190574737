import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import * as constants from '../../constants';
import { Context } from '../../Context';
import { getEnvironment } from '../../utils';
import { getBreakpoints } from '../../utils/theme';


const useStyles = makeStyles((theme) => {
  const { mobilePortrait, } = getBreakpoints(theme);
  return {
    topBannerAd: {
      minWidth: '62.5vw',
      marginTop: `${theme.spacing(1)}px`,
      [mobilePortrait]: {
        width: '100vw',
        minWidth: 'inherit'
      }
    },
    bottomBannerAd: {
      minWidth: '62.5vw',
      marginTop: `${theme.spacing(1)}px`,
      marginBottom: `${theme.spacing(1)}px`,
      [mobilePortrait]: {
        marginBottom: '1px',
        width: '100%',
        minWidth: 'inherit',
      }
    },
  }
})

/**
 * 
 * @typedef {Object} AdProps
 * @property {String} id DOM id of the ad placement
 */
/**
 * 
 * @param {String} adName 
 * @param {import('firebase/app').default.analytics.Analytics} analytics 
 */
export const logAdImpression = (adName, analytics) => {
  const environment = getEnvironment(window.location.hostname);
  analytics.logEvent('ad_impression', { adName, environment });
}
/**
 * 
 * @param {String} adName 
 * @param {import('firebase/app').default.analytics.Analytics} analytics 
 */
export const logAdClick = (adName, analytics) => () => {
  const environment = getEnvironment(window.location.hostname);
  analytics.logEvent('ad_click', { adName, environment })
}
/**
 * 
 * @param {AdProps} props 
 */
export const ContactDeveloperAd = (props) => {
  const { id } = props;
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { analytics } = context;
  const [ impressionWasLogged, setImpressionWasLogged ] = useState(false);
  useEffect(() => {
    if (!impressionWasLogged) {
      logAdImpression(id, analytics);
      setImpressionWasLogged(true);
    }
  }, []);
  const classes = useStyles();
  return (
    <Box border={1} padding={2} borderColor={"#787d7b"} className={classes.bottomBannerAd} {...props}>
      <Grid container item direction={"column"}>
        <Typography variant={"body2"}>
          {"Want to reach musicians with your message?"}
        </Typography>
        <Button href={constants.CONTACT_LINK_BUSINESS_INQUIRY} target={"_blank"} variant={"outlined"} onClick={logAdClick(id, analytics)}>
          Contact the developer
        </Button>
      </Grid>
    </Box>
  )
};

const copy = {
  donate: {
    cta: "Do you find Karachords useful? If so, would you consider a donation to help keep the servers running?",
    button: "Donate now"
  }
}
/**
 * @param {AdProps} props
 */
export const DonateAd = (props) => {
  const { id } = props;
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const { analytics } = context;
  const [ impressionWasLogged, setImpressionWasLogged ] = useState(false);
  useEffect(() => {
    if (!impressionWasLogged) {
      logAdImpression(id, analytics);
      setImpressionWasLogged(true);
    }
  }, []);
  const classes = useStyles();
  return (
    <Box border={1} padding={2} borderColor={"#787d7b"} className={classes.topBannerAd} {...props}>
      <Grid container item direction={"column"} alignItems={"center"} justifyContent={"center"}>
        <Grid item>
          <Typography variant={"body2"} align={"center"}>
            {copy.donate.cta}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant={"outlined"} href={constants.GUMROAD_DONATION_LINK} target={"_blank"} onClick={logAdClick(id, analytics)}>
            {copy.donate.button}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}