import { makeStyles } from "@material-ui/core";

export const useFieldStyles = makeStyles(() => {
    return {
         titleMinHeight: {
            minHeight: '48px'
        },
        titlePadding: {
            paddingLeft: '6px',
            paddingRight: '6px',
        }
    }
})