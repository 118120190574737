import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from 'react';
import { useAppStyles } from "../../appStyles";
import { ROLE_COPY } from "../../constants";
import { getRoleCopy, getSharedWithYouAtCopy } from "../utils";
import { useCardStyles } from './styles';

/**
 * @typedef {Object} SharedWithMeDetailsProps
 * @property {RedeemedShareDetails} share
 * @property {Boolean} isList
 *
 * @param {SharedWithMeDetailsProps} props
 * @returns
 */
export function SharedWithMeDetails(props) {
  const { gutters } = useCardStyles();
  const { italic, spacingBottom, textCenter } = useAppStyles();
  const { share, isList } = props;
  if (!share) {
    return null;
  }
  const { role, source } = share;
  const { display: roleDisplayName, description } = getRoleCopy(role, ROLE_COPY);
  const shouldDisplaySource = source && !isList;
  return (
    <Paper elevation={3} className={clsx(spacingBottom, gutters)}>
      <Grid container item direction={"column"} justifyContent={"flex-start"} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h6"}>
            {`Shared with you`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"body2"}>
            {`${getSharedWithYouAtCopy(share)}`}
          </Typography>
        </Grid>
        <Grid item>
          {shouldDisplaySource && (
            <span className={textCenter}>
              <Typography variant={"subtitle2"}>
                {`As a part of list`}
              </Typography>
              <Typography className={italic} variant={"subtitle2"}>
                {`${source.name}`}
              </Typography>
            </span>
          )}

        </Grid>
        <Grid item>
          <Typography variant={"h6"}>
            {`Your Role: ${roleDisplayName}`}
          </Typography>
        </Grid>
        <Grid item className={textCenter}>
          <Typography variant={"caption"}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
