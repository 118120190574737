import { Divider, Grid, List, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext, useState } from 'react';
import { Context } from '../../Context';
import { debounce } from '../../utils';
import { HeadedItemsListAddButton } from './HeadedItemsListAddButton';
import { HeadedItemsListFilterButton } from './HeadedItemsListFilterButton';
import { HeadedItemsListItem } from './HeadedItemsListItem';
import { UpgradeAccountCounter } from '../counters/UpgradeAccountCounter';
import { HeadedItemsListSearch } from './HeadedItemsListSearch';
import { filterItems, searchItems, sortItems } from './utils';
export const useStyles = makeStyles(() => {
  return {
    headedItemsList: {
      overflow: 'auto',
      width: '100%',
    },
    hide: {
      display: 'none'
    },
    placeholderText: {
      display: 'flex'
    },
  }
})
/**
 * @typedef {Object} HeadedItemListItemSecondaryAction
 * @property {(target: *) => (e: React.SyntheticEvent) => void} handleSecondaryAction
 * @property {React.FunctionComponent} [Icon]
 * @property {String} [text]
 * 
 * @typedef {Object} HeadedItemListItemPrimaryAction
 * @property {(target: *) => (e: React.SyntheticEvent) => void} handlePrimaryAction
 * 
 * @typedef {Object} HeadedItemsListProps
 * @property {String} header
 * @property {Array<import('../../constants').Item>} items
 * @property {Number} [maxItems]
 * @property {Object} searchOptions
 * @property {String} secondaryTextProp
 * @property {import('../../constants').KarachordsDialog} filterDialog
 * @property {Boolean} [isSubDrawer]
 * @property {HeadedItemListItemSecondaryAction} [secondaryAction]
 * @property {import('./../drawers/SubDrawer').HeadedItemListAddAction} [addAction]
 * @property {HeadedItemListItemPrimaryAction} [primaryAction]
 * @property {String} filtersPath
 * @property {(filters:Array<import('./utils').Filter>) => void} setFilters
 * @property {String} placeholder
 * @property {Boolean} [disabled]
 * 
 * @param {HeadedItemsListProps} props
 */
export const HeadedItemsList = (props) => {
  let { items } = props;
  items = items ? items : [];
  const {
    header,
    maxItems,
    searchOptions,
    secondaryTextProp,
    filterDialog,
    secondaryAction,
    addAction,
    primaryAction,
    filtersPath,
    placeholder,
    setFilters,
    disabled
  } = props;
  const [search, setSearch] = useState(null);
  const { headedItemsList, hide, placeholderText } = useStyles();
  const { state } = useContext(Context);
  const filters = get(state, filtersPath, []);
  const filteredItems = filterItems(items, filters);
  const sortedItems = sortItems(filteredItems);
  const searchedItems = searchItems(sortedItems, search, searchOptions);
  const shouldHideCounter = items.length && maxItems ? false : true;
  return (
    <React.Fragment>
      <Grid container item justifyContent={'center'} alignItems={'center'}>
        <Typography variant={"h5"} style={{
          paddingTop: '12px'
        }}>
          {header}
        </Typography>
      </Grid>
      <Grid container item spacing={1} alignItems={"center"} justifyContent={"space-evenly"} style={{ paddingBottom: '0.5em' }}>
        <HeadedItemsListSearch setSearch={debounce(setSearch, 250)} />
        <HeadedItemsListFilterButton
          setFilters={setFilters}
          filters={filters}
          filterDialog={filterDialog}
          filtersPath={filtersPath}
        />
        <HeadedItemsListAddButton action={addAction} disabled={items.length >= maxItems} />
        <UpgradeAccountCounter count={items.length} maxItems={maxItems} hidden={shouldHideCounter} />
      </Grid>
      <Divider />
      <Grid item container justifyContent={'center'} className={clsx(hide, {
        [placeholderText]: items.length < 1,

      })}>
        <Typography variant={"h6"}>
          {placeholder}
        </Typography>
      </Grid>

      <List className={headedItemsList}>
        {searchedItems.map((item, index) => {
          const { id } = item;
          return (
            <HeadedItemsListItem
              item={item}
              secondaryTextProp={secondaryTextProp}
              key={`${id}-${index}`}
              primaryAction={primaryAction}
              secondaryAction={secondaryAction}
              disabled={disabled}
            />
          );
        })}
      </List>
    </React.Fragment>
  );
};
