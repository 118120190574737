import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ClearAll } from '@material-ui/icons';
import { handleShowConfirmActionDialog } from '../../dialogs/utils';
import { handleClearList } from './utils';
import { copy } from './ListForm';

export const ClearSetlistMenuItem = (props) => {
  const { context, hidden } = props;
  const { dispatch } = context;
  if (hidden) {
    return null;
  }
  return (
    <MenuItem onClick={handleShowConfirmActionDialog(
      copy.confirm.clear,
      handleClearList(props.editingList, props.setEditingList, context),
      dispatch
    )}>
      <ListItemIcon>
        <ClearAll />
      </ListItemIcon>

      <ListItemText>
        {"Clear setlist"}
      </ListItemText>
    </MenuItem>
  );
};
