import { AppBar, Button, Grid, makeStyles, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { handleOpenUpgradeAccountDialog } from '../../utils';
import { getBreakpoints } from '../../utils/theme';

const useNavbarStyles = makeStyles((theme) => {
  const { laptop, mobilePortrait } = getBreakpoints(theme)
  return {
    appBar: {
      [mobilePortrait]: {
        width: '100%'
      }
    },
    appBarDrawerIsOpen: {
      width: '87.75vw',
      [laptop]: {
        width: '75%'
      },
      [mobilePortrait]: {
        width: '100%'
      }
    },
    appBarDrawerIsClosed: {
      width: `calc(100% - ${theme.spacing(8)}px)`,
      [mobilePortrait]: {
        width: '100%'
      }
    },
  }
})

export const Navbar = (props) => {
  const { menuIsOpen } = props;
  const { appBar, appBarDrawerIsClosed, appBarDrawerIsOpen } = useNavbarStyles();
  const context = useContext(Context);
  return (
    <AppBar
      position={"absolute"}
      color={"primary"}
      className={clsx(appBar, {
        [appBarDrawerIsOpen]: menuIsOpen,
        [appBarDrawerIsClosed]: !menuIsOpen
      })}
    >
      <Toolbar>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid container item xs={6} alignItems={"center"} justifyContent={"flex-start"}>
            <Typography variant={"h6"}>
              {"Karachords"}
            </Typography>
            <Typography variant={"caption"} style={{ paddingLeft: '1em' }}>
              {"BETA"}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleOpenUpgradeAccountDialog(context, `HeaderUpgradeButton_${context.state?.main?.view?.name}`)} variant={"outlined"}>
              {"Upgrade"}
            </Button>
            </Grid>
        </Grid>
      </Toolbar>

    </AppBar>
  );
};
