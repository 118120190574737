import { Button, CircularProgress, Grid } from '@material-ui/core';
import { PlayArrow, Save, Share } from '@material-ui/icons';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { useSongFormStyles } from '.';
import { useAppStyles } from '../../../appStyles';
import { DELETE_ROLES, EDIT_ROLES, ROLES, SHARE_TYPES } from '../../../constants';
import { Context } from '../../../Context';
import { getHandlePlaySong } from '../../drawers/utils';
import { getInitialShareForSong } from '../../utils';
import { handleOpenShareDialog } from '../utils';


export const SongFormActions = (props) => {
  const { buttonProgress } = useSongFormStyles();
  const { hide } = useAppStyles();
  const { isSubmitting, isLoading, setIsLoading, initialValues, areButtonsDisabled } = props;
  const context = useContext(Context);
  /** @type {import('../../../reducers/functions').Item} */
  const { id, shares = [], roles } = initialValues;
  const isPersisted = Boolean(id);
  const initialShare = getInitialShareForSong(shares, roles);
  const role = get(initialValues, 'share.role', ROLES.owner);
  const hideShare = !DELETE_ROLES.has(role);
  const hideSave = !EDIT_ROLES.has(role);
  return (
    <Grid container item justifyContent={"flex-end"} spacing={2}>
      {(isSubmitting || isLoading) && <CircularProgress className={buttonProgress} />}
      
      <Grid item>
        <Button
          startIcon={<PlayArrow />}
          size={"large"}
          disabled={!isPersisted || areButtonsDisabled}
          variant={"contained"}
          onClick={getHandlePlaySong(context)(initialValues)}
        >
          {"Play"}
        </Button>
      </Grid>
      <Grid item className={clsx({[hide]: hideShare})}>
        <Button
          startIcon={<Share />}
          size={"large"}
          color={"default"}
          disabled={!isPersisted || areButtonsDisabled}
          variant={"contained"}
          onClick={handleOpenShareDialog(context, initialValues, SHARE_TYPES.song, setIsLoading, initialShare)}
        >
          {"Share"}
        </Button>
      </Grid>
      <Grid item className={clsx({[hide]: hideSave})}>
        <Button
          startIcon={<Save />}
          size={"large"}
          color={"primary"}
          type="submit"
          disabled={areButtonsDisabled}
          variant={"contained"}
        >
          {"Save"}
        </Button>
      </Grid>

    </Grid>
  )
};


