import { AppBar, Grid, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import { Close, Edit, NavigateBefore, NavigateNext } from '@material-ui/icons';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { useAppStyles } from '../../appStyles';
import { MODIFY_NOTES_ROLES, ROLES } from '../../constants';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";

/**
 * @typedef {Object} PlayControlsProps
 * @property {() => void} nextSong
 * @property {() => void} prevSong
 * @property {Boolean} noNextSong
 * @property {Boolean} noPrevSong
 * @property {(force:Boolean) => () => void} handleInterruptableClose
 * @property {() => void} nextPage
 * @property {() => void} prevPage
 * @property {() => void} toggleEditMode
 * @property {Boolean} noNextPage
 * @property {Boolean} noPrevPage
 * @property {String} editMode
 *
 * @param {PlayControlsProps} props
 */

export const PlayControls = (props) => {
  const { noNextSong, noPrevSong, handleInterruptableClose, nextPage, prevPage, noNextPage, noPrevPage, toggleEditMode, editMode } = props;
  const classes = usePlayStyles();
  const { hide } = useAppStyles();
  const { state } = useContext(PlayContext);
  const { currentSong } = state;
  const role = get(currentSong, 'share.role', ROLES.owner);
  const canModify = MODIFY_NOTES_ROLES.has(role);
  return (
    <AppBar color="primary" position={"fixed"} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems={'center'} justifyContent={"space-between"} wrap={"nowrap"}>

          <Grid item container justifyContent={'center'}>
            <React.Fragment>
              <Tooltip title="Close">
                <IconButton onClick={handleInterruptableClose(false)} className={classes.controlsButton}>
                  <Close fontSize={"large"} />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          </Grid>

          <Grid item container justifyContent={'center'}>
            <Tooltip title="Last Page">
              <React.Fragment>
                <IconButton onClick={prevPage} disabled={noPrevPage && noPrevSong} className={classes.controlsButton}>
                  <NavigateBefore fontSize={"large"} />
                </IconButton>
              </React.Fragment>
            </Tooltip>
          </Grid>
          <Grid item container justifyContent={'center'}>

            <Tooltip title="Next Page" onClick={nextPage}>
              <React.Fragment>
                <IconButton onClick={nextPage} disabled={noNextPage && noNextSong} className={classes.controlsButton}>
                  <NavigateNext fontSize={"large"} />
                </IconButton>
              </React.Fragment>
            </Tooltip>
          </Grid>

          <Grid item container justifyContent={'center'} className={clsx({[hide]: !canModify})}>
            <Tooltip title="Edit">
              <React.Fragment>
                <IconButton onClick={toggleEditMode} className={classes.controlsButton} color={editMode ? 'secondary' : 'default'}>
                  <Edit fontSize={"large"} />
                </IconButton>
              </React.Fragment>
            </Tooltip>
          </Grid>

        </Grid>
      </Toolbar>
    </AppBar>
  );
};
