import { Grid, IconButton, Typography } from '@material-ui/core';
import { Close, DragHandle, FileCopy } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useAppStyles } from '../../../appStyles';
import { useListFormStyles } from './styles';

export const DraggableListSong = (props) => {
  const { song, index, remove, draggableId, cellProps, rowProps, add, canEdit } = props;
  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={!canEdit}>
      {(provided) => {
        const listSongProps = { provided, song, index, remove, add, cellProps, rowProps, canEdit };
        return (
          <ListSong {...listSongProps} />
        )
      }}
    </Draggable>
  )
}

const ListSong = (props) => {
  const { song, provided, remove, index, cellProps, rowProps, add, canEdit } = props;
  const { name, artist } = song;
  const { listSongCloseButton, listSongCopyButton } = useListFormStyles();
  const { hide } = useAppStyles();
  return (
    <Grid
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...rowProps}
      item
    >
      <Grid item {...cellProps.icon} className={clsx({[hide]: !canEdit})}>
        <IconButton onClick={remove(index)} className={listSongCloseButton}>
          <Close />
        </IconButton>
      </Grid>
      <Grid container item {...cellProps.text} direction={"column"}>
        <Grid item>
          <Typography> {name} </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"body2"} color={"textSecondary"}> {artist} </Typography>
        </Grid>
      </Grid>
      <Grid item {...cellProps.icon} className={clsx({[hide]: !canEdit})}>
        <IconButton onClick={add(song)} className={listSongCopyButton}>
          <FileCopy />
        </IconButton>
      </Grid>
      <Grid item {...cellProps.icon} className={clsx({[hide]: !canEdit})}>
        <DragHandle />
      </Grid>
    </Grid>
  )
}