
 /**
 * @typedef {Object} Action
 * @property {String} type
 * @property {Object} [payload]
 */

export const OPEN_MENU = 'openMenu';
export const TOGGLE_DRAWER = 'toggleDrawer';
export const SELECT_VIEW = 'selectView';
export const SET_LOADING = 'setLoading';
export const SET_SONGS = 'setSongs';
export const SELECT_ITEM = 'selectItem';
export const CLEAR_CURRENT_ITEM = 'clearCurrentItem';
export const SET_DATA = 'setData';
export const OPEN_DIALOG = 'openDialog';
export const CLOSE_DIALOG = 'closeDialog';
export const PLAY_SONG = 'playSong';
export const PLAY_SETLIST = 'playSetlist';
export const END_PLAY = 'endPlay';
export const DELETE_DATA = 'deleteData';
export const ADD_NOTIFICATION = 'addNotification';
export const REMOVE_NOTIFICATION = 'removeNotification';
export const SET_FILTERS = 'setFilters';
export const RESET_STATE = 'resetState';
export const UPDATE_LOCAL_SONG = 'updateLocalSong';
export const UPDATE_MAX = 'updateMax';
export const SET_PENDING_SHARE = 'setPendingShare';

export const ASYNC_ACTIONS = new Set([
  PLAY_SONG,
  PLAY_SETLIST,
])