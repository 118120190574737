import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  getStartingFile,
  getEditPanelComponent,
  getLayersForFilePage,
  getCurrentLayer,
  handleSetNotes,
  handleSetCurrentFile,
  handleSetLayers,
  getDialogButtonText,
  handleInterruptableStateChange,
  handleAfterResolveNextSong,
  handleAfterResolvePrevSong,
  handleAfterResolveExitFreehand,
  getChangePageMethod,
  getPlayRootHeight,
  handleSetCurrentSong,
  handleToLayer,
  handleUpdateFont,
  handleUpdateEditMode,
  handleUpdateActiveNote,
  handleGoToNextPage,
  handleGoToPrevPage,
} from './utils';
import { DEFAULT_FONT, DEFALT_STROKE_COLOR, DEFAULT_STROKE_WIDTH, DEFAULT_PAGE_HEIGHT, DIALOG_MESSAGES } from "./playConstants";
import { PlayControls } from './PlayControls';
import { EditPanel } from './EditPanel';
import { editModes } from '../../constants';
import { SongComponent } from './SongComponent';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from './PlayStyles';
import { PlayNotification } from './PlayNotification';
import { PlayViewDialog } from './PlayViewDialog';
import { Context } from '../../Context';
import { Action } from 'history';

/**
 * @typedef {import('../forms/song/SongForm').Song} Song
 * @typedef {import('./utils').Layer} Layer
 * @typedef {import('./utils').PageLayers} PageLayers
 * @typedef {import('./utils').PagesLayers} PagesLayers
 * 
 * @typedef {Object} JSONParsedBuffer
 * @property {String} type
 * @property {Array<Number> | Uint8Array} data
 * 
 * @typedef {import('./../forms/song/SongForm').SongFile} SongFile
 */

export const DEFAULT_LAYER_INDEX = 1;


/**
 * 
 * @param {import('./utils').Note} note 
 * @param {PageLayers} layers 
 * @param {Layer} currentLayer 
 * @param {Number} nextLayerIndex 
 * @param {(layers:PageLayers) => void} setLayers 
 */
const moveNoteToLayer = (note, layers, currentLayer, nextLayerIndex, setLayers) => {
  if (currentLayer.idx === nextLayerIndex) {
    return;
  }
  const { notes: currentNotes, idx: currentLayerIdx } = currentLayer;
  const filteredCurrentNotes = currentNotes.filter(({ id }) => note.id !== id);
  const updatedCurrentLayer = Object.assign({}, currentLayer, { notes: filteredCurrentNotes });


  const nextLayer = layers[nextLayerIndex];
  const { notes: nextLayerNotes, idx: nextLayerIdx } = nextLayer;
  const updatedNextLayerNotes = nextLayerNotes.slice();
  updatedNextLayerNotes.push(note);
  const updatedNextLayer = Object.assign({}, nextLayer, { notes: updatedNextLayerNotes });

  /** @type {PageLayers} */
  const nextLayers = Object.assign({}, layers, { [nextLayerIdx]: updatedNextLayer, [currentLayerIdx]: updatedCurrentLayer });
  setLayers(nextLayers);
};
/**
 * @typedef {Object} PlayViewProps
 * @property {Array<import('./../forms/song/SongForm').Song>} songs
 * @property {() => void} onClose
 * @property {Number} [startIndex]
 * @param {PlayViewProps} props 
 */
export const PlayView = (props) => {
  const { onClose, startIndex } = props;
  let { songs } = props;
  // setup atomic states
  const [index, setIndex] = useState(startIndex || 0);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const EditPanelComponent = getEditPanelComponent(editMode);
  const [activeNote, setActiveNote] = useState(null);
  const [pageScale, setPageScale] = useState(null);
  /** @type {[React.RefObject<import('react-sketch-canvas').ReactSketchCanvas>, (nextFreehandEditRef: React.RefObject<import('react-sketch-canvas').ReactSketchCanvas>) => void]} */
  const [freehandEditRef, setFreehandEditRef] = useState(null);
  const [strokeColor, setStrokeColor] = useState(DEFALT_STROKE_COLOR);
  const [strokeWidth, setStrokeWidth] = useState(DEFAULT_STROKE_WIDTH);
  const [pageHeight, setPageHeight] = useState(DEFAULT_PAGE_HEIGHT);
  const [isErasing, setIsErasing] = useState(false);
  const [font, setFont] = useState(DEFAULT_FONT);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [currentLayerIndex, setCurrentLayerIndex] = useState(DEFAULT_LAYER_INDEX);
  // const [shouldForceOverrideInterrupt, setShouldForceOverrideInterrupt] = useState(false);
  const [wasEdited, setWasEdited] = useState(false);
  // setup dependant states
  const [currentSongs, setCurrentSongsState] = useState(songs);
  /** @type {[import('../../reducers/functions').Notification, import('react').Dispatch<import('../../reducers/functions').Notification>]} */
  const [notification, setNotification] = useState(null);

  
  /** @type {import('../forms/song/SongForm').KarachordsContext} */
  const { state: karachordsState } = useContext(Context);
  const { history } = karachordsState;
  const handleInterruptableClosePlayMode = handleInterruptableStateChange(
    onClose,
    DIALOG_MESSAGES.EXIT,
    setDialogMessage,
    wasEdited
  );
  useEffect(() => {
    const unblock = history.block(({action, location}) => {
      if (action === Action.Pop) {
        if (location.state) {
          handleInterruptableClosePlayMode(false)();
          return;
        }
      }
    })
    return () => {
      unblock();
    }
  }, [handleInterruptableClosePlayMode, history])

  const [currentSong, setCurrentSong] = [currentSongs[index], handleSetCurrentSong(currentSongs, setCurrentSongsState, wasEdited, setWasEdited)];
  const startingFile = getStartingFile(currentSong);
  const [currentFile, setCurrentFile] = [startingFile, handleSetCurrentFile(currentSong, setCurrentSong)];
  const [layers, setLayers] = [
    getLayersForFilePage(currentFile, page),
    handleSetLayers(currentFile, setCurrentFile, page)
  ]
  const currentLayer = getCurrentLayer(layers, currentLayerIndex);
  const [{ notes }, setNotes] = [currentLayer, handleSetNotes(currentLayer, layers, setLayers)];

  const nextSong = currentSongs[index + 1];
  const prevSong = currentSongs[index - 1];
  const noPrevSong = prevSong ? false : true;
  const noNextSong = nextSong ? false : true;


  const noNextPage = page >= pages;
  const noPrevPage = page < 2;
  const nextPage = handleGoToNextPage(noNextPage, wasEdited, page, currentFile, layers, setCurrentFile, setPage, setActiveNote, setWasEdited, freehandEditRef)
  const prevPage = handleGoToPrevPage(noPrevPage, wasEdited, page, currentFile, layers, setCurrentFile, setPage, setActiveNote, setWasEdited, freehandEditRef)
  const resetLocalState = () => {
    setPage(1);
    setPages(null);
    setDialogMessage(null);
    setCurrentLayerIndex(DEFAULT_LAYER_INDEX);
    setActiveNote(null);
    setWasEdited(false);
  }
  const playNext = () => {
    const nextIndex = index + 1;
    const nextSong = currentSongs[nextIndex];
    if (!nextSong) {
      // TODO: Message the user that they have reached the end?
      return;
    }
    setLayers(getLayersForFilePage(currentFile, page));
    setCurrentSong(nextSong);
    setIndex(nextIndex);
    resetLocalState();
  }
  const playPrev = () => {
    const prevIndex = index - 1;
    const prevSong = currentSongs[prevIndex];
    if (!prevSong) {
      // TODO: Message the user that they have reached the beginning?
      return;
    }
    setLayers(getLayersForFilePage(currentFile, page));
    setCurrentSong(prevSong);
    setIndex(prevIndex);
    resetLocalState();
  }
  const toggleEditMode = () => {
    if (editMode) {
      setEditMode(null);
      return;
    }
    setEditMode(editModes.selecting);
  };
  const goToNextSong = handleInterruptableStateChange(
    playNext,
    DIALOG_MESSAGES.NEXT_SONG,
    setDialogMessage,
    wasEdited
  )(false);

  const goToPrevSong = handleInterruptableStateChange(
    playPrev,
    DIALOG_MESSAGES.PREV_SONG,
    setDialogMessage,
    wasEdited
  )(false);

  // const goToNextPage = handleInterruptableStateChange(
  //   nextPage,
  //   DIALOG_MESSAGES.NEXT_PAGE,
  //   setDialogMessage,
  //   wasEdited
  // )(false);
  
  // const goToPrevPage = handleInterruptableStateChange(
  //   prevPage,
  //   DIALOG_MESSAGES.NEXT_PAGE,
  //   setDialogMessage,
  //   wasEdited
  // )(false);
  /** @type {import('./PlayControls').PlayControlsProps} */
  const controlsProps = {
    handleInterruptableClose: handleInterruptableClosePlayMode,
    nextSong: goToNextSong,
    prevSong: goToPrevSong,
    noNextSong,
    noPrevSong,
    nextPage: getChangePageMethod(nextPage, goToNextSong, noNextPage),
    prevPage: getChangePageMethod(prevPage, goToPrevSong, noPrevPage),
    noNextPage,
    noPrevPage,
    toggleEditMode,
    editMode,
  };
  /** @type {import('./PlayContext').PlayViewState} */
  const state = {
    currentSong,
    currentFile,
    page,
    pages,
    editMode,
    activeNote,
    notes,
    pageScale,
    freehandEditRef,
    EditPanelComponent,
    strokeColor,
    strokeWidth,
    pageHeight,
    isErasing,
    font,
    currentLayerIndex,
    layers,
    isLoading,
    dialogMessage,
    wasEdited,
    notification,
  }
  


  const updateActiveNote = handleUpdateActiveNote(setActiveNote, activeNote, notes, setNotes, setFont)
  /** @type {import('./PlayContext').PlayMethods} */
  const methods = {
    setIndex,
    setCurrentFile: setCurrentFile,
    setPages,
    setPage,
    setEditMode: handleUpdateEditMode(setActiveNote, setEditMode),
    setActiveNote: updateActiveNote,
    setNotes,
    setFreehandEditRef,
    setPageScale,
    setStrokeColor,
    setStrokeWidth,
    setPageHeight,
    setIsErasing,
    setFont: handleUpdateFont(font, setFont, activeNote, updateActiveNote),
    setCurrentLayerIndex: handleToLayer(activeNote, moveNoteToLayer, layers, currentLayer, setLayers),
    setLayers,
    setIsLoading,
    setDialogMessage,
    setWasEdited,
    setNotification,
    nextPage: controlsProps.nextPage,
    prevPage: controlsProps.prevPage
  };

  const context = { state, methods }
  const { playRoot } = usePlayStyles();
  const dialogButtonText = getDialogButtonText(dialogMessage);

  const afterResolveExitFreehand = handleAfterResolveExitFreehand(
    setDialogMessage,
    setWasEdited,
    setEditMode,
    freehandEditRef
  );
  /**
 * 
 * @param {import('./PlayContext').DialogMessage} dialogMessage
 * @return {VoidFunction}
 */
  const getAfterResolve = (dialogMessage) => {
    const dialogMessageAfterResolved = {
      [DIALOG_MESSAGES.EXIT]: handleInterruptableClosePlayMode(true),
      [DIALOG_MESSAGES.NEXT_SONG]: handleAfterResolveNextSong(playNext),
      [DIALOG_MESSAGES.PREV_SONG]: handleAfterResolvePrevSong(playPrev),
      [DIALOG_MESSAGES.EXIT_FREEHAND]: afterResolveExitFreehand,
    }
    return dialogMessageAfterResolved[dialogMessage];
  }
  return (
    <Grid item container className={playRoot} style={{ minHeight: getPlayRootHeight(pageHeight, editMode) }}>
      <PlayContext.Provider value={context}>

        <PlayViewDialog
          dialogMessage={dialogMessage}
          setDialogMessage={setDialogMessage}
          dialogButtonText={dialogButtonText}
          getAfterResolve={getAfterResolve}
        />

        <SongComponent />

        <PlayNotification notification={notification} setNotification={setNotification} />
        <EditPanel />
        <PlayControls {...controlsProps} />
      </PlayContext.Provider>
    </Grid>
  )
}

PlayView.displayName = "PlayView"







