import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

export const FullScreenSpinner = () => {
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}