import { Button, Grid, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { AddCircle, Casino, ContactMail, PlaylistAdd } from '@material-ui/icons';
import { mdiArrowExpandUp } from '@mdi/js';
import { decode } from 'html-entities';
import React, { useContext } from 'react';
import * as actions from '../../actions';
import * as constants from '../../constants';
import { Context } from '../../Context';
import { handleOpenUpgradeAccountDialog } from '../../utils';
import { dispatchFetchErrorNotification } from '../../utils/notfications';
import { getOne } from '../../utils/persistence';
import * as persistanceConstants from '../../utils/persistence/constants';
import { getBreakpoints } from '../../utils/theme';
import { ContactDeveloperAd, DonateAd } from '../ads';

const copy = {
  signedOut: "Sign up / login",
  signedIn: "Sign out",
  welcome: "Welcome, "
}
/**
 * 
 * @param {import('firebase/app').default.User} currentUser 
 */
const getWelcomeCopy = (currentUser) => {
  let name = 'anonymous musician';
  if (currentUser && currentUser.displayName) {
    name = decode(currentUser.displayName)
  }

  return copy.welcome + name;
}




// const DonateButton = (props) => {
//   const { analytics } = props;
//   useEffect(() => {
//     logAdImpression(constants.adIDs.homeButtonDonate, analytics);
//   }, [analytics]);
//   return (
//     <Button size={"large"}
//       startIcon={<MonetizationOn />}
//       color={"primary"}
//       fullWidth
//       variant={"contained"}
//       href={constants.GUMROAD_DONATION_LINK}
//       target={"_blank"}
//       className={props.homePanelButton}
//       onClick={logAdClick(constants.adIDs.homeButtonDonate, analytics)}
//       id={constants.adIDs.homeButtonDonate}
//     >
//       {"Donate to Karachords"}
//     </Button>
//   )
// }



export const HomePanel = () => {
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const currentUser = context.firebase.auth().currentUser;
  const useHomeStyles = makeStyles((theme) => {
    const { mobilePortrait } = getBreakpoints(theme);
    return {
      homePanelButton: {
        height: '6em',
        width: '62.5vw',
        [mobilePortrait]: {
          width: '100vw',
        }
      }
    }
  })
  const { homePanelButton } = useHomeStyles();
  /**
   * 
   * @param {import('../../Context').KarachordsContext} context 
   * @param {string} viewName
   * @return {() => void}
   */
  const handleNewItem = (context, viewName) => () => {
    const { dispatch } = context;
    /** @type {import('../../reducers/functions').SelectViewPayload} */
    const payload = { name: viewName, item: constants.EMPTY_ITEM };
    dispatch({ type: actions.SELECT_VIEW, payload })
  }
  /**
   * 
   * @param {import('../../Context').KarachordsContext} context
   * @returns {() => Promise<void>}
   */
  const playRandomSong = (context) => async () => {
    const { dispatch, state } = context;
    if (state.songs.list.length < 1) {
      return;
    }
    dispatch({ type: actions.SET_LOADING, payload: { value: true } })
    const maxIndex = state.songs.list.length;
    const selectIndex = Math.floor(Math.random() * maxIndex);
    const user = context.firebase.auth().currentUser;
    const userToken = await user.getIdToken();
    const id = state.songs.list[selectIndex].id;
    const resp = await getOne(persistanceConstants.SONGS_URL, userToken)(id);
    const { status } = resp;
    if (status !== 200) {
      dispatchFetchErrorNotification(resp, dispatch);
      dispatch({ type: actions.SET_LOADING, payload: { value: false } })
      return
    }
    /** @type {import('../../reducers/functions').PlaySongPayload} */
    const payload = { song: resp.data };
    dispatch({ type: actions.PLAY_SONG, payload })
  }

  return (
    <Grid item container alignItems={"center"} direction={"column"} justifyContent={"space-between"}>
      <DonateAd id={constants.adIDs.homeAdDonate} />
      <Grid item container spacing={2} alignItems={"center"} direction={"column"} justifyContent={"center"}>
        <Grid item>
          <Typography variant='h6'>
            {getWelcomeCopy(currentUser)}
          </Typography>
        </Grid>
        <Grid item>
          {/* <DonateButton analytics={analytics} homePanelButton={homePanelButton}></DonateButton> */}
          <Button size={"large"}
            startIcon={(
              <SvgIcon>
                <path d={mdiArrowExpandUp} />
              </SvgIcon>
            )}
            color={"primary"}
            fullWidth
            variant={"contained"}
            className={homePanelButton}
            onClick={handleOpenUpgradeAccountDialog(context, 'HomeUpgradeButton')}
          >
            {"Upgrade Account"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size={"large"}
            startIcon={<AddCircle />}
            fullWidth
            variant={"contained"}
            color={"primary"}
            onClick={handleNewItem(context, constants.VIEW_NAMES.LIBRARY)}
            className={homePanelButton}
          >
            {"Add to library"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size={"large"}
            startIcon={<PlaylistAdd />}
            color={"primary"}
            fullWidth
            variant={"contained"}
            onClick={handleNewItem(context, constants.VIEW_NAMES.SETLISTS)}
            className={homePanelButton}
          >
            {"Add setlist"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size={"large"}
            startIcon={<Casino />}
            color={"primary"}
            fullWidth
            variant={"contained"}
            onClick={playRandomSong(context)}
            className={homePanelButton}
          >
            {"Play random library item"}
          </Button>
        </Grid>

        <Grid item>
          <Button
            size={"large"}
            startIcon={<ContactMail />}
            color={"primary"}
            fullWidth
            variant={"contained"}
            href={constants.CONTACT_LINK}
            target={"_blank"}
            className={homePanelButton}
          >
            {"Contact the developer"}
          </Button>
        </Grid>
      </Grid>
      <Grid container item direction={"column"} justifyContent={"flex-end"} alignItems={"center"}>
        <ContactDeveloperAd id={'home-ad-contact-developer'} />
        <Typography variant={"subtitle2"}>
          {constants.copyrightText}
        </Typography>
      </Grid>
    </Grid>
  )
}
