import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { handleOpenShareDetailsDialog } from '../lists/utils';

/**
 * @typedef {Object} ViewShareDetailsMenuItemProps
 * @property {import('../../constants').Item} item
 * 
 * 
 * @param {ViewShareDetailsMenuItemProps} props
 */
export const ViewShareDetailsMenuItem = props => {
  const { item } = props;
  const { dispatch } = useContext(Context);
  const { shares, share } = item;
  const hasShares = (shares && shares.length > 0) || share;
  if (!hasShares) {
    return null;
  }
  return (
      <MenuItem onClick={handleOpenShareDetailsDialog(item, dispatch)}>
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText>
          {"View share details"}
        </ListItemText>
      </MenuItem>
  );
};
