import { Icon } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import get from 'lodash.get';
import pickBy from 'lodash.pickby';
import { Resizable } from 're-resizable';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { debounce } from '../../utils';
import { DEFAULT_CONTAINER_SIZE } from './playConstants';
import { PlayContext } from './PlayContext';
import { usePlayStyles } from "./PlayStyles";
import { RemoveNoteButton } from './RemoveNoteButton';
import { getDraggableContainerStyle, getDraggableItem, getRemovePredicate, isComponentDraggable, isComponentSelectable, isNoteActive, scaleToPage, unscaleFromPage, updateNote } from './utils';

/** @type {import('re-resizable').Enable} */
const resizableIsEnabled = {
  top: true,
  right: true,
  bottom: true,
  left: true,
  topRight: true,
  bottomRight: true,
  bottomLeft: true,
  topLeft: true,
}

/** @type {import('re-resizable').Enable} */
const resizeableIsDisabled = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}

export const ResizeArrow = (props) => {
  const { isActive } = props;
  const { resizeArrow } = usePlayStyles();
  if (!isActive) {
    return null;
  }
  return (
    <Icon className={resizeArrow}>
      <ArrowRight />
    </Icon>
  )
}
/**
 * @typedef {Object} DraggableContainerProps
 * @property {import('./utils').Note} note
 * 
 * @typedef {Object} DraggableItemExtraProps
 * @property {Number} pageScale
 * @property {boolean} isActive
 * 
 * @typedef { DraggableContainerProps & DraggableItemExtraProps } DraggableItemProps
 * 
 * @param { DraggableContainerProps } props 
 */
export const DraggableResizableContainer = (props) => {
  const { note } = props;
  const { id, type } = note;
  const size = get(note, 'size', DEFAULT_CONTAINER_SIZE)
  const { state, methods } = useContext(PlayContext);
  const { pageScale, notes } = state;
  const [isResizing, setIsResizing] = useState(false);
  
  const [resizableContainerSize, setResizableContainerSize] = useState(size);
  const { editMode, activeNote } = state;
  const { setActiveNote, setNotes } = methods;
  const { draggableItemContainer, draggableTextResizableContainer, activeNote: activeNoteStyle } = usePlayStyles();
  const isActive = isNoteActive(note, activeNote);

  const [{ isDragging }, dragRef] = useDrag(handleGetDragSpec(type, isActive, setActiveNote, note, id), [note]);
  const defaultContainerStyle = {
    opacity: isDragging ? 0.5 : 1,
  };
  const scaledSize = {
    width: scaleToPage(resizableContainerSize.width, pageScale),
    height: scaleToPage(resizableContainerSize.height, pageScale),
  }
  /** @type {React.Ref<import('re-resizable').Resizable>} */
  const resizeableRef = useRef();
  useEffect(() => {
    if (resizeableRef && resizeableRef.current) {
      resizeableRef.current.updateSize(scaledSize);
    }
  }, [pageScale])

  if (!pageScale) {
    return null;
  }

  const setContainerSize = handleSetContainerSize(pageScale, setResizableContainerSize, note, notes, setNotes, setActiveNote)
  const debouncedSetContainerSize = debounce(setContainerSize, 250)
  const containerStyle = getDraggableContainerStyle(note, pageScale, isActive);
  const finalContainerStyle = Object.assign({}, defaultContainerStyle, containerStyle);
  const DraggableItem = getDraggableItem(note);

  const isDraggable = isComponentDraggable(editMode, isResizing, isActive);
  const isSelectable = isComponentSelectable(editMode);
  return (
    <span
      className={clsx(draggableItemContainer, {
        [activeNoteStyle]: isActive
      })}
      ref={isDraggable ? dragRef : null}
      style={finalContainerStyle}
      onClick={(e) => {
        e.stopPropagation()
        if (!isActive && isSelectable) {
          setActiveNote(note);
        }
      }}>
      <RemoveNoteButton note={note} isActive={isActive} />
      <Resizable
        as={'span'}
        defaultSize={scaledSize}
        ref={resizeableRef}
        className={draggableTextResizableContainer}
        enable={isActive ? resizableIsEnabled : resizeableIsDisabled}
        onResizeStart={() => {
          setActiveNote(Object.assign(note, { isResizing: true }));
          setIsResizing(true);
        }}
        onResizeStop={(event, direction, elementRef, numberSizeDeltas) => {
          event.stopPropagation();
          setIsResizing(false);
          const nextWidth = scaledSize.width + numberSizeDeltas.width;
          const nextHeight = scaledSize.height + numberSizeDeltas.height;
          const nextInputSize = {
            width: nextWidth,
            height: nextHeight
          }
          debouncedSetContainerSize(nextInputSize);
        }}
      >
        <DraggableItem note={note} pageScale={pageScale} isActive={isActive} />
      </Resizable>
    </span>

  );
};


function handleSetContainerSize(pageScale, setResizableContainerSize, note, notes, setNotes, setActiveNote) {
  return (size) => {
    const { width, height } = size;
    const unscaledSize = {
      width: unscaleFromPage(width, pageScale),
      height: unscaleFromPage(height, pageScale)
    };
    setResizableContainerSize(unscaledSize);
    const nextNote = pickBy(Object.assign({}, note, { size: unscaledSize }), getRemovePredicate(["isResizing"]));
    updateNote(nextNote, notes, setNotes);

    setActiveNote(nextNote);
  };
}

function handleGetDragSpec(type, isActive, setActiveNote, note, id) {
  return () => {
    return {
      type,
      item: () => {
        if (!isActive) {
          setActiveNote(note);
        }
        return { id };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      }),
    };
  };
}

