import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { MenuDrawerOption } from './MenuDrawerOption';
import { useDrawerStyles } from "./styles";
import { Context } from '../../Context';

export const MenuDrawerOptionsList = () => {
  const classes = useDrawerStyles();
  /** @type {import('../../Context').KarachordsContext} */
  const context = useContext(Context);
  const options = context.views.list;
  return (
    <Grid container className={classes.menu}>
      {options.map((option) => {
        const { name } = option;
        return (
          <MenuDrawerOption key={name} {...option} />
        );
      })}
    </Grid>
  );
};
