import { Menu } from '@material-ui/core';
import React, { useContext } from 'react';
import { Context } from '../../../Context';
import { ViewShareDetailsMenuItem } from '../../menuItems/ViewShareDetailsMenuItem';
import { RemoveItemMenuItem } from '../../menuItems/RemoveItemMenuItem';
import { DeleteSongMenuItem } from './DeleteItemMenuItem';
import { ClearAnnotationsMenuItem } from './ClearAnnotationsMenuItem';
import { DELETE_ROLES, EDIT_ROLES, SHARE_TYPES } from '../../../constants';
import { canUserPeformAction } from '../../../utils';
import { RevokeAllSharesMenuItem } from '../../menuItems/RevokeAllSharesMenuItem';
import get from 'lodash.get';


export const copy = {
  clearAll: "This will clear all notes for this library item. This action is irreversible.",
  delete: "This will delete this library item and all of its files. This action is irreversible.",
}


/**
 * @typedef {Object} SongFormHeaderMenuProps
 * @property {(element: Element) => void} setMenuAnchorEl
 * @property {Element} menuAnchorEl
 * @property {Object} initialValues actually a Song
 * @property {(isLoading: Boolean) => void} setIsLoading
 * 
 * @param {SongFormHeaderMenuProps} props 
 */

export const SongFormHeaderMenu = (props) => {
  const { setMenuAnchorEl, menuAnchorEl, initialValues, setIsLoading } = props;
  const { id: songId, fileID: fileId } = initialValues;
  const context = useContext(Context);
  const closeMenu = () => {
    setMenuAnchorEl(null);
  }
  const { share } = initialValues;
  const canClearAnnotations = canUserPeformAction(share, EDIT_ROLES);
  const canDelete = canUserPeformAction(share, DELETE_ROLES);
  const type = get(share, 'type', null);
  const canRemove = !canDelete && type === SHARE_TYPES.song
  return (
    <Menu
      open={Boolean(menuAnchorEl)}
      onClose={closeMenu}
      anchorEl={menuAnchorEl}
    >
      <div>
        <ViewShareDetailsMenuItem item={initialValues} />
      </div>
      <div>
        <ClearAnnotationsMenuItem
          hidden={!canClearAnnotations}
          songId={songId}
          fileId={fileId}
          context={context}
        />
      </div>
      <div>
        <RevokeAllSharesMenuItem item={initialValues} context={context}/>
      </div>
      <div>
        <DeleteSongMenuItem
          initialValues={initialValues}
          setIsLoading={setIsLoading}
          context={context}
          hidden={!canDelete}
        />
      </div>
      <div>
        <RemoveItemMenuItem
          item={initialValues}
          setIsLoading={setIsLoading}
          context={context}
          hidden={!canRemove}
        />
      </div>
    </Menu>
  );
};

