import { Divider, Grid, IconButton, ListItem, ListItemText } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { Context } from '../../Context';
import { useSongFormStyles } from '../forms/song';
import { HeadedItemsListItemSecondaryActionButton } from './HeadedItemsListItemSecondaryActionButton';
import { getPrimaryActionProps, getSecondaryText, handleOpenShareDetailsDialog } from './utils';


/**
 * @property {React.FunctionComponent} Icon
 * @typedef {Object} HeadedItemsListItemProps
 * @property {import('../../constants').Item} item
 * @property {String} [secondaryTextProp]
 * @property {Boolean} [isSubDrawer]
 * @property {import('./HeadedItemsList').HeadedItemListItemSecondaryAction} [secondaryAction]
 * @property {import('./HeadedItemsList').HeadedItemListItemPrimaryAction} [primaryAction]
 * @property {Boolean} [disabled]
 */
/**
 * 
 * @param {HeadedItemsListItemProps} props 
 */
export const HeadedItemsListItem = (props) => {
  const { item, secondaryTextProp, secondaryAction, primaryAction, disabled } = props;
  const { dispatch } = useContext(Context)
  const { hide, spacingLeft8 } = useSongFormStyles();
  const { name, share, shares } = item;
  const secondaryText = getSecondaryText(secondaryTextProp, item);
  const primaryActionProps = getPrimaryActionProps(primaryAction, item);
  return (
    <React.Fragment>
      <Grid container alignItems={"center"} wrap={"nowrap"} justifyContent={"space-between"}>
        <Grid item style={{ width: "100%" }}>
          <ListItem {...primaryActionProps} role={"button"} style={{ padding: '0 0 0 16px' }}>
            <ListItemText primary={name} secondary={secondaryText} />
          </ListItem>
        </Grid>
        <Grid container xs={8} item alignItems={'center'} justifyContent={"flex-end"} direction={"row"}>
          <Grid item>
            <IconButton
              className={clsx({ [hide]: (!share && (!shares || shares.length < 1)) })}
              onClick={handleOpenShareDetailsDialog(item, dispatch)}
            >
              <Group />
            </IconButton>
          </Grid>
          <Grid item className={spacingLeft8}>
            <HeadedItemsListItemSecondaryActionButton action={secondaryAction} item={item} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </React.Fragment>
  );
};


